import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  workshop: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
});

const Freenightcontent = () => {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const Formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      workshop: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;
      axios
        .post(
          " https://git.digiwbs.com/Admin/public/api/apifreeCareerSend",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          Formik.resetForm();
        })
        .catch((err) => {
          console.error("Form not submited:", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });
  function onChange(value) {
    setRecaptchaToken(value);
  }
  return (
    <>
      <section className="freenightcontent">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <p>
                Attend our Free Career Night to learn more about the exciting
                industry of Information Technology. Our Career Nights are
                designed to provide information about the I.T. Industry so our
                applicants can make educated decisions on the direction they
                would like to head in. If you are not sure which Certification
                Track you would like to follow, your best bet is to attend our
                Free Career Night.
              </p>
              <h4>What you will learn:</h4>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>What are the latest trends in the field of IT?</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p> The state of current job market?</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p> Which skills are in demand?</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Importance of Certification</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Which training program is right for you?</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>What are different financing options?</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p> Get advice from a career advisor.</p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>See if you qualify for FREE TRAINING!!!</p>
              </div>

              <h4 className="mt-3">
                If you would like to attend a Free Career Night reserve your
                spot today and call:{" "}
                <a href="tel:8664644846" style={{ textDecoration: "none" }}>
                  <span style={{ color: "#f5aa30", textDecoration: "none" }}>
                    1-866-464-4846
                  </span>
                </a>{" "}
                or fill out the form and we will contact you with a
                confirmation.
              </h4>
            </div>
            <div className="col-12 col-lg-6">
              <form onSubmit={Formik.handleSubmit}>
                <div className="form-clip">
                  <h3 className="text">Get in Touch !</h3>
                </div>
                <div className="formdiv">
                  <div className="under-div">
                    <div className="name gx-5 d-flex">
                      <div className="d-grid w-100">
                        <CommonComp
                          type={"text"}
                          placeholder={"First Name"}
                          name={"first_name"}
                          id="first_name"
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          value={Formik.values.first_name}
                        />
                        {Formik.touched.first_name &&
                          Formik.errors.first_name && (
                            <p className="err">{Formik.errors.first_name}</p>
                          )}
                      </div>
                      <div className="d-grid w-100">
                        <CommonComp
                          type={"text"}
                          placeholder={"Last Name"}
                          name={"last_name"}
                          id="last_name"
                          onChange={Formik.handleChange}
                          onBlur={Formik.handleBlur}
                          value={Formik.values.last_name}
                        />
                        {Formik.touched.first_name &&
                          Formik.errors.last_name && (
                            <p className="err">{Formik.errors.last_name}</p>
                          )}
                      </div>
                    </div>
                    <CommonComp
                      type={"email"}
                      placeholder={"Email"}
                      name={"email"}
                      id="email"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.email}
                    />
                    {Formik.touched.email && Formik.errors.email && (
                      <p className="err">{Formik.errors.email}</p>
                    )}
                    <CommonComp
                      type={"phone"}
                      placeholder={"Phone Number"}
                      name={"phone"}
                      id="phone"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.phone}
                    />
                    {Formik.touched.phone && Formik.errors.phone && (
                      <p className="err">{Formik.errors.phone}</p>
                    )}
                    <CommonComp
                      type={"text"}
                      placeholder={"Address"}
                      name={"address"}
                      id="address"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.address}
                    />
                    {Formik.touched.address && Formik.errors.address && (
                      <p className="err">{Formik.errors.address}</p>
                    )}
                    <CommonComp
                      type={"text"}
                      placeholder={"Workshop Name"}
                      name={"workshop"}
                      id="workshop"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.workshop}
                    />
                    {Formik.touched.workshop && Formik.errors.workshop && (
                      <p className="err">{Formik.errors.workshop}</p>
                    )}
                    <ReCAPTCHA
                      className="g-recaptcha mt-3"
                      sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                      onChange={onChange}
                    />
                    <button className="btn button1 w-100 mt-3" type="submit">
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Freenightcontent;
