import React, { Suspense } from "react";
import Admissionbanner from "./Admission/Admissionbanner";
import Admissionform from "./Admission/Admissionform";

function AsmissionRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Admissionbanner />
        <Admissionform />        
      </Suspense>
    </>
  );
}

export default AsmissionRoute;
