import React, { useEffect, useState, Suspense } from "react";
import Categorybanner from "./Category/Categorybanner";
import Javaposter from "./Productpages/Javase8/Javaposter";
import Catecontent from "./Category/Catecontent";
import Coursesection from "./Category/Coursesection";
import { useParams } from "react-router-dom";
import axios from "axios";

function Categorypage() {
  const { id } = useParams();
  const [catInfo, setCatInfo] = useState({});
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://git.digiwbs.com/Admin/public/api/apiCoursesByCategory?id=${id}`
      )
      .then((result) => {
        setCourses(result.data.CoursesByCategory.data);
        setCatInfo(result.data.categoryData);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, [id]);


  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Categorybanner info={catInfo} />
        <Javaposter />
        <Catecontent info={catInfo} />
        <Coursesection data={courses} />       
      </Suspense>
    </>
  );
}

export default Categorypage;
