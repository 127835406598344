import React from "react";
import goal from "../../image/goal_10698551 1.png";

function Javaposter() {
  return (
    <>
      <section className="java-poster ">
        <div className="container">
          <div className="d-flex img-txt">
            <img src={goal} alt="goal" loading="lazy" />
            <h2>
              Unlock the Door to <strong>Success:</strong> Join Global It Where{" "}
              <strong>50000+ Success Stories</strong> Prove the Path to a
              Brighter Future!
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default Javaposter;
