import React from "react";
import { Rating } from "@mui/material";

function Booktourbanner() {
  return (
    <>
      <section className="booktourbanner">
        <div className="container h-100">
          <div className="book-content">
            <h1>Global Information Technology – Virtual and On-Campus</h1>
            <Rating readOnly defaultValue={5} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Booktourbanner;
