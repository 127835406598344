import React, { Suspense } from "react";
import Deliverybanner from "./Delivery_Method/Deliverybanner";
import Deliverycontent from "./Delivery_Method/Deliverycontent";
import Deliverycontact from "./Delivery_Method/Deliverycontact";

function DeleverymethodRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Deliverybanner />
        <Deliverycontent />
        <Deliverycontact />        
      </Suspense>
    </>
  );
}

export default DeleverymethodRoute;
