import React, { Suspense } from "react";
import Classbanner from "./Classschedules/Classbanner";
import Classtabel from "./Classschedules/Classtabel";

function ClassSchedulesRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Classbanner />
        <Classtabel />
      </Suspense>
    </>
  );
}

export default ClassSchedulesRoute;
