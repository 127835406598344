import React, { useEffect, useState, Suspense } from "react";
import Javasebanner from "./Productpages/Javase8/Javasebanner";
import Serviceabout from "./Productpages/Javase8/serviceabout";
import Rating from "./Productpages/Javase8/Rating";
import Javaposter from "./Productpages/Javase8/Javaposter";
import Menucontent from "./Productpages/Javase8/Menucontent";
import Admission from "./Productpages/Javase8/Admission";
import axios from "axios";
import { useParams } from "react-router-dom";

function Javase8route() {
  const [coursepage, setCoursepage] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://git.digiwbs.com/Admin/public/api/apiCoursePage?id=${id}`)
      .then((result) => {
        setCoursepage(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Javasebanner
          image={coursepage?.img}
          title={coursepage?.title}
          preReq={coursepage?.preRequisites}
          background={coursepage?.background}
        />
        <Serviceabout
          courseNo={coursepage?.courseNumber}
          duration={coursepage?.duration}
        />
        <Rating />
        <Javaposter />
        <Menucontent
          about={coursepage?.body}
          benifit={coursepage?.benifit}
          courseContent={coursepage?.courseContent}
          objective={coursepage?.courseObjectives}
          outline={coursepage?.courseOutline}
          whatYouWillLearn={coursepage?.whatYouWillLearn}
          whoShouldAttend={coursepage?.whoShouldAttend}
        />
        <Admission />
      </Suspense>
    </>
  );
}

export default Javase8route;
