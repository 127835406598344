import React from "react";
import { Rating } from "@mui/material";

function FreeCareerbanner() {
  return (
    <>
      <section className="free-palnning">
        <div className="container h-100">
          <div className="planning-content">
            <h1>Free Career Planning</h1>
            <p>We can help you plan your career!</p>
            <Rating name="read-only" readOnly defaultValue={5} />
          </div>
        </div>
      </section>
    </>
  );
}

export default FreeCareerbanner;
