import React from "react";
import { Rating } from "@mui/material";

function Contactbanner() {
  return (
    <>
      <section className="conatct-banner">
        <div className="container h-100">
          <div className="contactbanner-content">
            <h1>Contact Us</h1>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactbanner;
