import React from "react";

function Catecontent({ info }) {
  return (
    <>
      <section className="catecontent">
        <div className="container">
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: info?.body }} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Catecontent;
