import React from "react";
import { Rating } from "@mui/material";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Categorybanner({ info }) {
  return (
    <>
      <section className="catebanner">
        <div className="container h-100">
          <div className="banner-text">
            <img
              className="cate-img"
              src={`${imagePath}${info?.img}`}
              loading="lazy"
              alt="bg_image"
            />
            <h1>{info?.title}</h1>
            <p className="cate-para">
              Learn the language of virtually every type of networked
              application.
            </p>
            <Rating
              name="read-only"
              defaultValue={5}
              readOnly
              className="ps-2"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Categorybanner;
