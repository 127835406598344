import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Certificatecourses() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiPopularCourses")
      .then((result) => {
        setCourses(result.data.PopularCourses.data);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);

  const cardColors = [
    "var(--c-1, #f25022)",
    "var(--c-2, #4b3290)",
    "var(--c-3, #93000a)",
    "var(--c-6, #5fa9a8)",
    "var(--c-5, #00d8c0)",
    "var(--c-7, #ed80ca)",
  ];

  return (
    <>
      <div className="certificationn">
        <div className="container">
          <h5>TOP COURSES</h5>
          <h2>Browse Our Popular Courses</h2>

          <div className="slider-container">
            <Slider {...sliderSettings}>
              {courses &&
                courses.map((val, index) => (
                  <div
                    key={index}
                    className="card"
                    style={{
                      borderTop: `6px solid ${
                        cardColors[index % cardColors.length]
                      }`,
                    }}
                  >
                    <Link
                      to={`/Course/${val.title}/ ${val.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="card-body text-center d-grid  align-items-center">
                        <img
                          className="card-img"
                          src={`${imagePath}${val.img}`}
                          alt="Card_image"
                          loading="lazy"
                        />
                        <div className="pop-title">
                          <Link to={`/Course/${val.title} / ${val.id}`}>
                            {val.title}
                          </Link>
                        </div>
                      </div>
                    </Link>

                    <button
                      className="right-link"
                      style={{
                        backgroundColor: cardColors[index % cardColors.length],
                      }}
                    >
                      <Link
                        to={`/Course/${val.title} / ${val.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <ArrowForwardIosIcon
                          sx={{ fontSize: 18 }}
                          style={{ color: "#fff" }}
                        />
                      </Link>
                    </button>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Certificatecourses;
