import React from "react";
import image1 from "../image/git_experiance.jpg";
import image2 from "../image/what_we_offer.jpg";
import image3 from "../image/company_benifit.jpg";

function Overviewcontent() {
  return (
    <>
      <section className="overviewcontent ">
        <div className="container">
          <div className="row overview_row">
            <div className="col-12 col-lg-6">
              {" "}
              <img src={image1} alt="GITimg" className="mb-3 w-100" />
            </div>
            <div className="col-12 col-lg-6">
              <h2> GIT Experience</h2>
              <p>
                Whether you are an I.T. professional, looking to break into the
                I.T. industry, or want to enhance your skills, Global I.T. has
                the training that is right for you. Knowing this is the hottest
                industry to be in for the long run, it is important to pick the
                right school.
                <br />
                We maintain our affiliation with the MMBDC, Automation Alley,
                CompTIA, and Microsoft.
              </p>
            </div>
          </div>

          <div className="row overview_row">
            <div className="col-12 col-lg-6 order">
              <h2>What we offer?</h2>
              <p>
                Global I.T. offers career-oriented certification courses
                designed to fit one’s specific needs in the areas of networking,
                database administration, e-commerce, web development, clerical,
                and programming. All of our courses are highly structured,
                taking into consideration industry requirements,
                cost-effectiveness, and emerging technologies.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              {" "}
              <img src={image2} alt="offer" className="mb-3 w-100" />
            </div>
          </div>

          <div className="row overview_row">
            <div className="col-12 col-lg-6">
              {" "}
              <img src={image3} alt="Benefits" className="mb-3 w-100" />
            </div>
            <div className="col-12 col-lg-6">
              <h2>Benefits</h2>
              <p>
                Global I.T. has many benefits to offer, making it the best
                school for training! Global I.T. will focus on your
                goals, interests and work to bring those together to help you
                cost-effectively meet your career objectives. Global I.T.
                assures your certification and will put its name on the line to
                back it up. In addition to training, Global I.T. offers services
                in staffing, web development, and IT consulting solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Overviewcontent;
