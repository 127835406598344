import React from "react";
import { Rating } from "@mui/material";

function Studentbanner() {
  return (
    <>
      <section className="studentbanner">
        <div className="container h-100">
          <div className="student-content text-center">
            <h1>Student Services</h1>
            <p>Welcome to the Global Information Technology Tool Box</p>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Studentbanner;
