import React from "react";
import { Accordion } from "react-bootstrap";

function Networkaccordian() {
  return (
    <>
      <section className="networkaccordian">
        <div className="container">
          <div className="row">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Spyware Removal</Accordion.Header>
                <Accordion.Body>
                  <h2>Spyware</h2>
                  <p>
                    In the realm of computers, spyware refers to a wide category
                    of software designed to intercept or take partial control of
                    the computer system without the knowledge or consent of the
                    computer operator. While the term implies that the installed
                    spyware monitors the user, in fact, the the term has come to
                    refer to software that subverts the operation of the system
                    for the benefit of a third party.
                  </p>
                  <h2>Some forms of Spyware on infected systems include:</h2>
                  <p>
                    The delivery of unwanted and unsolicited pop-up
                    advertisements (adware).
                    <br />
                    Theft of personal information such as credit card numbers,
                    banking account numbers, etc.
                    <br />
                    The monitoring of Web-browsing activity for marketing
                    purposes.
                    <br />
                    Spyware has become a major security threat and will attack
                    almost any unprotected system, regardless of the user's
                    surfing habits.
                  </p>
                  <h2>Malware</h2>
                  <p>
                    Malware is malicious software that infects unprotected
                    systems for malicious reasons such as the destruction of
                    data, network degradation, or the rendering of systems
                    inoperable.
                    <br />
                    The best-known types of malware are viruses and worms, which
                    are known for how they spread rather than any other
                    particular behavior.
                    <br />
                    Originally, the term computer virus was used to describe a a
                    program that infected other executable software, while worm
                    transmitted itself over a network to infect computers.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Virus Removal</Accordion.Header>
                <Accordion.Body>
                  <h2>Virus/Worms</h2>
                  <p>
                    A computer A virus is a self-replicating computer program
                    that spreads by inserting copies of itself into other
                    executable code and becoming part of another executable
                    program or document. A computer virus requires user
                    intervention to spread, such as a user opening an email that
                    contains a virus. Viruses generally do not affect network
                    performance, as their malicious activities are mostly
                    confined within the target computer itself.
                  </p>
                  <p>
                    Computer Worm is a self-replicating computer program,
                    similar to a computer virus. Unlike a computer virus, a worm
                    is self-contained and does not need to be part of another
                    program to propagate itself. They are often designed to
                    exploit the file transmission capabilities found on many
                    computers. In addition to replication, a worm may be
                    designed to do any number of things, such as delete files on
                    a host system, encrypt files, or send documents via e-mail.
                    In general, worms harm the network and consume bandwidth.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Backup and recovery</Accordion.Header>
                <Accordion.Body>
                  <h2>Computer OS Restore</h2>
                  <p>
                    There are many varied reasons for re-installing the
                    operating system on your system. Some of these are listed
                    below:
                  </p>
                  <ul>
                    <li>Your hard drive has become inoperable or unstable.</li>
                    <li>
                      Your system is so loaded with viruses and spyware that you
                      wish to make a clean start.
                    </li>
                    <li>
                      You are selling or giving away your system, and you want
                      to make sure that all your personal files and data are
                      cleared off the machine.
                    </li>
                    <li>
                      You may wish to change back to your original Operating
                      System.
                    </li>
                  </ul>
                  <h2>Disaster Recovery</h2>
                  <p>
                    Disaster recovery and business continuity refer to an
                    organization’s ability to recover from a
                  </p>
                  <p>
                    Disaster recovery and business continuity refer to an
                    organization’s ability to recover from a disaster and/or
                    unexpected event and resume and continue operations.
                    Organizations should have a plan in place (usually referred
                    to as a “Disaster Recovery Plan” or “Business Continuity
                    Plan”) that outlines how this will be accomplished. This
                    plan should outline backup and restore policies and
                    procedures.
                  </p>
                  <h2>Types of backup</h2>
                  <p>
                    There are primarily three different types of backups: full,
                    incremental, and differential.
                    <br />A full backup is simply a backup of all the files on
                    the system. An incremental backup only backs up the files
                    that have changed since the most recent backup (either full
                    or differential). An incremental backup is also referred to
                    as a cumulative incremental backup. The advantage of this is
                    quicker backup times; as only files changed since the last
                    backup (full or differential) need to be saved. The
                    disadvantage is longer recovery times, as the latest full
                    backup and any days up to the date of system loss need to be
                    restored (for example, Full Backup of Monday, Incremental
                    Tuesday – Wednesday – Thursday).
                  </p>
                  <p>
                    A differential backup is a cumulative backup of all changes
                    made since the last full backup. The advantage of this is
                    the quicker recovery time, requiring only a full backup and
                    the latest differential backup to restore the system. The
                    disadvantage is that each day is further from the last full
                    backup (for example Friday), more data needs to be backed up
                    each day; especially if a majority of the data has been
                    edited or created.
                  </p>
                  <h2>Data Recovery</h2>
                  <p>
                    Data recovery is the process of salvaging data from damaged,
                    failed, wrecked, or inaccessible primary storage media (hard
                    drives) when it cannot be accessed normally. Often, the data
                    is being salvaged from storage media formats such as hard
                    disk drives, storage tapes, CDs, DVDs, RAIDs, and other
                    electronics. This can be due to physical damage to the
                    storage device or logical damage to the file system that
                    prevents it from being mounted by the host operating system.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Installations</Accordion.Header>
                <Accordion.Body>
                  <h2>Installations</h2>
                  <p>
                    Installations are divided into three types: system
                    installations, hardware installations, and software
                    installations.
                  </p>
                  <h2>System Installation</h2>
                  <p>
                    A new installation of a Windows operating system normally
                    partitions the hard disk into one or more partitions (C:
                    drive, D: drive, etc.). The operating system is loaded onto
                    one of these partitions. The partition that the operating
                    system is actually loaded from is marked as the active
                    partition. Once the operating system is loaded, updates and
                    an antivirus program should be installed immediately to
                    prevent the corruption of the system.
                  </p>
                  <h2>Software Installation</h2>
                  <p>
                    Software has historically been considered an intermediary
                    between electronic hardware and data. The output of a a
                    particular piece of executed software may be the input for
                    another executed piece of software. Therefore, the software
                    may be considered an interface between hardware, data,
                    and/or other software.
                    <br />
                    Once the operating system is loaded on the system, the
                    software needs to be loaded to allow the user to interface
                    with the system and perform useful tasks (writing a letter,
                    sending an email, viewing photographs, etc.).
                  </p>
                  <h2>Hardware Installation</h2>
                  <p>
                    Computer hardware is the physical part of a computer,
                    including the digital circuitry. The hardware of a computer
                    is infrequently changed in comparison with software and
                    data, which are “soft” in the sense that they are readily
                    created, modified, or erased on the computer. Computer
                    hardware consists of various boards, such as modems and
                    sound cards. Hardware can be inside or outside the computer
                    case. Some examples of hardware that is outside the computer
                    case are printers, external modems, scanners, and USB
                    drives.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Networking and Wireless</Accordion.Header>
                <Accordion.Body>
                  <h2>Networking</h2>
                  <p>
                    In a broad sense, Networking or Computer Networking is the
                    scientific and engineering disciplines concerned with
                    communication between computer systems. These networks
                    involve at least two computers separated by a few inches or
                    thousands of miles. A network can be as simple as a computer
                    sharing and updating information via a Bluetooth connection
                    or as complex as a corporate system spanning many states or
                    countries and consisting of thousands of systems and
                    servers.
                  </p>
                  <p>Three types of Networks are listed below:</p>
                  <ol>
                    <li>
                      A local area network (LAN) is a computer network covering
                      a small local area, like a home, office, or small group of
                      buildings such as a home, office, or college. Currently,
                      LANs are most likely to be based on switched Ethernet or
                      WiFi (wireless) technology running at 10, 100, or 1,000
                      Mbit/s.
                    </li>
                    <li>
                      A wide area network (WAN) is a computer network covering a
                      wide geographical area and involving a vast array of
                      computers.
                    </li>
                    <li>
                      A Metropolitan Area Network (MAN) is a large computer
                      network usually spanning a campus or a city. They
                      typically use wireless infrastructure or optical fiber
                      connections to link their sites.
                    </li>
                  </ol>
                  <h2>Networking and Cabling</h2>
                  <p>
                    In the context of a wired network, many individual
                    components may be involved in the physical makeup of the
                    network. Some of these components are as follows:
                  </p>
                  <ol>
                    <li>
                      The physical cables that connect the network components
                      together. With today’s standards, these cables are usually
                      Cat5 (category 5), Cat7 (the newer category 7), or, in
                      some cases, fiber optic (although this can be very
                      expensive while being very fast).
                    </li>
                    <li>
                      Also, there will usually be some type of connection to the
                      Internet (although this is not required).
                    </li>
                    <li>
                      Routers, switches, and hubs are some of the components
                      that connect the wiring and manage where the information
                      transmitted over the wire.
                    </li>
                    <li>
                      The physical connection on the wall is where the computers
                      connect to the LAN (Drops).
                    </li>
                    <li>
                      The actual connection on the computer, printer, etc. where
                      the wire connects (Network Interface Card (NIC)).
                    </li>
                    <li>
                      In addition to the hardware, a protocol that all devices
                      on the LAN can understand (usually TCP/IP on modern
                      systems)
                    </li>
                  </ol>
                  <h2>Wireless Installation</h2>
                  <p>
                    In computer terms, wireless is a method of communication
                    that uses low-powered radio waves to transmit data between
                    devices. The term refers to communication without cables or
                    cords, chiefly using radio frequency and infrared waves. A
                    common use is the wireless networking of computers. A
                    network may be a combination of wired and wireless
                    technology.
                  </p>
                  <h2>Access Point (AP)</h2>
                  <p>
                    An access point (AP) is a two-way transceiver that
                    broadcasts data into the surrounding environment.
                  </p>
                  <h2>Wireless Cards</h2>
                  <p>
                    Wireless devices designed for personal computers. Their
                    purpose is to allow the computer to connect to a network
                    through radio waves. These peripherals can be found as PCI
                    cards or portable USB devices. When activated, they scan
                    their environment for signals from a wireless access point.
                    If one is found, it allows the user to connect to the access
                    point. The wireless capture card can determine if the access
                    point is secured with an encryption method (usually Wired
                    Equivalent Privacy (WEP) or Wi-Fi Protected Access (WPA))
                    and can still allow the user to connect to it, assuming the
                    key or security passcode is available.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Computer Cleaning</Accordion.Header>
                <Accordion.Body>
                  As your system operates, the built-in fan(s) pull in outside
                  air to keep the interior components of the system cool. This
                  cooling extends the life of the system and allows it to
                  operate at maximum performance. Unfortunately, the fans also
                  pull in outside pollutants such as dust, pet hair, etc. These
                  pollutants will cover the CPU and other components in the
                  system, causing the machine to run at an increased
                  temperature. These pollutants in the interior of the machine
                  need to be removed to ensure continued operation of the
                  system. Pollutants also accumulate on the exterior of systems,
                  getting into the fan and keyboard and coating the screen.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Computer upgrades</Accordion.Header>
                <Accordion.Body>
                  <h2>Disk Optimization</h2>
                  <p>
                    The purpose of disk optimization is to optimize the time it
                    takes to read and write files to and from the hard drive.
                    This is accomplished using a combination of several tasks,
                    as briefly listed below.
                  </p>
                  <h2>Defragmentation</h2>
                  <p>
                    Fragmentation occurs when the system cannot or will not
                    allocate enough contiguous space to store a complete file as
                    a unit but instead puts parts of it in gaps between other
                    files (usually those gaps exist because they formerly held a
                    file that the operating system has subsequently deleted or
                    because the operating system allocated excess space for the
                    file in the first place). Advances in technology are
                    bringing us larger and larger disk drives. The performance
                    loss due to fragmentation squares with each doubling of the
                    size of the drive. Larger files and greater numbers of files
                    also contribute to fragmentation and consequent performance
                    loss. Defragmentation restores a drive to its original
                    speed. It also moves infrequently used files further from
                    the directory area.
                    <br />
                    Move all the index or directory information to one spot.
                    Move this spot into the center of the data, e.g., one-third
                    of the way in, so that head travel to the data is halved
                    compared to having directory information at the front.
                  </p>
                  <ol>
                    <li>Cluster files around the directory area</li>
                    <li>
                      Move infrequently used files further from the directory
                      area.
                    </li>
                    <li>
                      Obey a user-provided table of file descriptions to
                      emphasize or ignore.
                    </li>
                    <li>
                      Make files contiguous so that they can be read without
                      unnecessary seeking.
                    </li>
                  </ol>
                  <h2>Windows Startup Programs</h2>
                  <p>
                    Every time any computer boots up, it launches many programs
                    that “run in the background.”. This means that the various
                    pieces of software are loaded into memory and are ready to
                    run, but they do not occupy a button on the taskbar;
                    sometimes they will have a position in the System Tray
                    (Notification Area). Some of these programs are valid and
                    should be left alone, while others eat up system resources,
                    thus slowing your system to a crawl as well as possibly
                    posing dangers to your system, and should be removed.
                  </p>
                  <h2>Drivers</h2>
                  <p>
                    A device driver, or software driver, is a type of computer
                    software that is typically developed to allow interaction
                    with hardware devices. Typically, this is an interface for
                    communicating with the hardware device. These drivers are
                    normally written by the hardware vendor. These drivers can
                    become obsolete for various reasons and should be checked
                    for new versions occasionally.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Networkaccordian;
