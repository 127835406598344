import React, { useState } from "react";
import Requestform from "../RequestAccessform/Requestform";
import { Link } from "react-router-dom";

function Studentcontent() {
  const [showreqModal, setShowreqModal] = useState(false);

  const openreqModal = () => setShowreqModal(true);
  const closereqModal = () => setShowreqModal(false);
  return (
    <>
      <section className="studentcontent">
        <div className="container">
          <div className="d-flex w-100 flex-wrap content-div">
            <div className="w-50 content-column">
              <h5>Resume Tips</h5>
              <p>To make your resume stand out among others.</p>
            </div>
            <div className="w-50 content-column">
              <h5>Earn Money</h5>
              <p>Make money using our quick and simple referral system.</p>
            </div>
            <div className="w-50 content-column">
              <h5>Interview Preparation</h5>
              <p>
                Get sample answers to the most frequently asked interview
                questions.
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Study Tips</h5>
              <p>
                Take advantage of exam tips from students who have already
                passed the exams.
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Lecture Slides</h5>
              <p>
                Did you miss a class? Want to review the material covered?
                Download the PowerPoint slides!
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Salary Guide</h5>
              <p>A guide to negotiating the best salary for your position.</p>
            </div>
            <div className="w-50 content-column">
              <h5>Practice Exams</h5>
              <p>
                Preparing to take a certification test? Practice online to make
                sure you’re ready.
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>SharePoint Portal</h5>
              <p>
                A portal where students can freely collaborate and share ideas,
                as well as read current I/T articles and have access to practice
                exams.
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Student Chat</h5>
              <p>
                Post class-related questions to instructors/students and
                participate in online discussions
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Training Technical News</h5>
              <p>
                A single news aggregator for Global I.T. students who seek the
                latest in training and technology development news.
              </p>
            </div>
            <div className="w-50 content-column">
              <h5>Job Listings</h5>
              <p>
                Easily locate job openings in your area with this great tool.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center w-100 mt-5 content-btngroup">
              <button className="btn request" onClick={openreqModal}>
                Request Access
              </button>
              <span>OR</span>
              <button className="btn button1 student">
                <Link to="https://moodle.global-itech.com/login/index.php" target="_blank">
                  Student Login
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
      <Requestform show={showreqModal} handleClose={closereqModal} />
    </>
  );
}

export default Studentcontent;
