import React from "react";
import Card from "react-bootstrap/Card";
import brnd from "../image/Group 1775.png";
import ecom from "../image/Group 1777.png";
import uiux from "../image/Group 1776.png";
import dev from "../image/Group 1778.png";
import digi from "../image/Group 1773.png";

function Webservice() {
  return (
    <>
      <section className="webservice ">
        <div className="container">
          <div className="row">
            <div className="d-flex flex-wrap card-div">
              <Card>
                <Card.Img variant="top" src={brnd}  />
                <Card.Body>
                  <Card.Title>Branding</Card.Title>
                  <Card.Text>
                    Identity forged, perceptions shaped, brands defined.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={ecom}  />
                <Card.Body>
                  <Card.Title>E-Commerce</Card.Title>
                  <Card.Text>
                    Innovation driving growth, e-commerce solutions delivered.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={uiux}  />
                <Card.Body>
                  <Card.Title>UI / UX </Card.Title>
                  <Card.Text>
                    Designing experiences, shaping digital interactions
                    beautifully.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={dev} />
                <Card.Body>
                  <Card.Title>Development</Card.Title>
                  <Card.Text>
                    Innovation coded, online presence amplified, websites.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img variant="top" src={digi}  />
                <Card.Body>
                  <Card.Title>Digital Marketing</Card.Title>
                  <Card.Text>
                    Data-driven strategies, brands thrive online.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Webservice;
