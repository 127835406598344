import React,{Suspense} from "react";
import Plancarerbanner from "./Planyourcareer/Plancarerbanner";
import Plancareercontent from "./Planyourcareer/Plancareercontent";

function PlancareerRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Plancarerbanner />
        <Plancareercontent />
      </Suspense>
    </>
  );
}

export default PlancareerRoute;
