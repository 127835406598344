import { Rating } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/career/";

function CareerCourseContent() {
  const [Trackcourse, setTrackcousre] = useState();

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apicareerAll")
      .then((result) => {
        // console.log("track", result.data.data);
        setTrackcousre(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setTrackcousre]);

  return (
    <>
      <section className="course-content">
        <div className="container">
          <div className="row">
            <div className="card-grid">
              {Trackcourse &&
                Trackcourse.map((val, index) => (
                  <div className="card" key={index}>
                    <img
                      className="card-img-top"
                      src={`${imagePath}${val.image}`}
                      loading="lazy"
                      alt={val.title}
                    />
                    <div className="card-body">
                      <h5>Training</h5>
                      <h5 className="card-title">{val.title}</h5>
                      <p className="card-text">{val.s_des}</p>
                      <div className="rating-btn d-flex align-items-center mt-2">
                        <div className="d-flex align-items-center">
                          <p>4.3</p>
                          <Rating
                            name="read-only"
                            defaultValue={5}
                            readOnly
                            className="ps-2"
                          />
                        </div>
                        <Link
                          to={`/career-track/${val.title}/${val.id}`}
                          className="btn"
                        >
                          Learn More
                          <ArrowForwardIosIcon
                            sx={{ fontSize: 15 }}
                            style={{ color: "#1da7f5" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CareerCourseContent;
