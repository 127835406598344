import React from "react";
import career from "../image/career_content.jpg";
import { Link } from "react-router-dom";

function Careercontent() {
  return (
    <>
      <section className="careercontent " id="career">
        <div className="container">
          <h2>Career</h2>
          <div className="content-career">
            <div className="row">
              <div className="col-12 col-lg-6">
                <p>
                  We are also constantly recruiting qualified personnel,
                  especially in the areas of networking and programming. We have
                  full-time and part-time opportunities for certified IT
                  professionals and trainers to teach MCSE, CCNA, CCNP, CCIE,
                  Oracle DBA, Java, and .net courses. The candidate would be
                  responsible for providing training services in the above
                  technologies both in-house and at client sites. Candidates
                  must have excellent communication skills.
                </p>
                <h2>We are an equal-opportunity employer.</h2>
                <Link to="https://globalinformation.applicantpro.com/jobs/" target="_blank">
                  Click here for Open Jobs
                </Link>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={career}
                  alt="career"
                  className="w-100"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careercontent;
