import React,{Suspense} from "react";
import Faqbanner from "./FAQ/Faqbanner";
import Faqcontent from "./FAQ/Faqcontent";

function FaqRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Faqbanner />
        <Faqcontent />
      </Suspense>
    </>
  );
}

export default FaqRoute;
