import React from "react";
import youtube from "../image/youtube-video.png";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Accordion from "react-bootstrap/Accordion";

function BenifitDetail() {
  return (
    <>
      <section className="benifitdetail">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <p>
                “GI Bill® is a registered trademark of the U.S. Department of
                Veterans Affairs (VA). More information about education benefits
                offered by the VA is available at the official U.S. government
                website at{" "}
                <a href="http://www.benefits.va.gov/gibill">
                  http://www.benefits.va.gov/gibill”.
                </a>
              </p>
              <h2>Veteran Services</h2>
              <p>
                Now, it’s our turn and our pleasure to serve you and help you
                reach your educational goals at Global Information Technology!
                Allow us to take this opportunity to thank you for your service
                to our nation. Your sacrifice and contributions to the Armed
                Forces around the world are greatly appreciated. Global
                Information Technology takes great care in serving Active Duty
                Military National Guard Reserves and Veterans over the many
                years with career pathways in a variety of advanced and
                accelerated IT professions including Technical Help Desk, Web
                Design and Development, Data Administration, Network
                Engineering, Computer Programming, Desktop Support, and Clerical
                Administration. We offer real-world hands-on education from
                dedicated instructors to prepare you for success in today’s most
                demanding IT job market.
              </p>
              <p>
                In addition to more traditional IT paths, global IT has created
                a unique opportunity for veterans to join the growing need for
                information security professionals. The Veterans Information
                Security Initiative, a Veterans Affairs Agency-certified Fast
                Track Program is one of the most innovative IT training and job
                placement services in the State of Michigan, teaching veterans
                information security skills such as CompTIA Security+ and CYSA+
                and progressing to Certified Ethical Hacker and Certified
                Information Systems Security Professional.
              </p>
              <p>
                Our mission at Global Information Technology is to support
                veterans and their dependents to pursue academic achievement and
                personal excellence. We provide resources and assistance to help
                you reach your professional and educational goals. Global
                Information Technology welcomes all veterans, their dependents
                and spouses, National Guard members, and reservists to use their
                benefits at our center.
              </p>
              <p>
                Veterans, service members, and military-connected students
                directly access academic advising through the Veteran Resource
                Representative and access disability services and career
                counseling.
              </p>
              <h2>Veteran Services</h2>
              <p>
                Veterans are welcome to visit our Veterans Resource Center, at
                “VA Loungeroom," a place where they are invited to relax, enjoy
                their coffee, and meet fellow veterans.
              </p>
              <p>
                Veterans are welcome to visit our Veterans Resource Center, at
                “VA Loungeroom," a place where they are invited to relax, enjoy
                their coffee, and meet fellow veterans. For more information,
                Veterans can reach the Michigan Affairs Agency ( MVAA) at
                1-800-MICH-VET ( 800-642-4838) or visit{" "}
                <a href="https://www.michigan.gov/mvaa">
                  https://www.michigan.gov/mvaa
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                className="w-100"
                src={youtube}
                alt="youtube-video"
                loading="lazy"
              />
              <h2 className="benifitfaq">FAQ</h2>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <ContactSupportIcon
                      sx={{ color: "#f5aa30" }}
                      className="me-3"
                    />
                    How do I use my Post 9/11 G.I Bill?
                  </Accordion.Header>
                  <Accordion.Body>
                    Global Information Technology is approved by the State of
                    Michigan, Proprietary School Education, and the US
                    Department of Veterans Affairs to accept veteran education
                    benefits. These benefits may cover up to 100% of tuition and
                    fees and can provide a percentage stipend for your
                    courseware, lodging, testing, and other training-related
                    costs as well.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <ContactSupportIcon
                      sx={{ color: "#f5aa30" }}
                      className="me-3"
                    />
                    How to use G.I. Bill with Global Information Technology?
                  </Accordion.Header>
                  <Accordion.Body>
                    We will review your background and assist you through the
                    application process so you can start your training as
                    quickly as possible. You will need to provide a copy of your
                    VA GI Bill COE (Certificate of Eligibility) or screenshot of
                    your COE from the VA e-Benefits website to begin the
                    process.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <ContactSupportIcon
                      sx={{ color: "#f5aa30" }}
                      className="me-3"
                    />
                    Does Global Information Technology (Global IT) accept the
                    VRRAP benefits?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, Global Information Technology (Global IT) is approved
                    by the Department of Veteran Affairs to receive benefits
                    under the VRRAP, or Veteran Rapid Retraining Assistance
                    Program. You can choose up to IT Professional 6. Total: 288
                    hours. Total of 16 weeks. Service members interested in
                    enrolling in our VRRAP program should begin by filling out
                    the contact form below. Afterward, one of our career
                    advisors or certifying officials will contact you to
                    schedule a tour and/or answer any questions you may have.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <ContactSupportIcon
                      sx={{ color: "#f5aa30" }}
                      className="me-3"
                    />
                    What is the VET TEC Program?
                  </Accordion.Header>
                  <Accordion.Body>
                    VET TEC is a new tuition and housing assistance program to
                    fund veteran job training in the technology field. It’s an
                    alternative to the Post-9/11 GI Bill, with a focus on
                    getting veterans into high-tech skills that are in demand in
                    today’s workplace.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BenifitDetail;
