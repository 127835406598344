import React, { useEffect, useState, Suspense } from "react";
import Detailbanner from "./Trackdetailpage/Detailbanner";
import Detailcontent from "./Trackdetailpage/Detailcontent";
import Relatedcard from "./Trackdetailpage/Relatedcard";
import axios from "axios";
import { useParams } from "react-router-dom";

function Detailpage() {
  const [Coursedetail, setCoursedetail] = useState([]);
  const [Related, setRelated] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://git.digiwbs.com/Admin/public/api/apicareerPage?id=${id}`)
      .then((result) => {
        // console.log("track", result.data);
        setCoursedetail(result.data.career_course);
        setRelated(result.data.related_courses);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Detailbanner title={Coursedetail.title} />
        <Detailcontent
          title={Coursedetail.title}
          image={Coursedetail.image}
          des={Coursedetail.f_des}
        />
        <Relatedcard related={Related} />
      </Suspense>
    </>
  );
}

export default Detailpage;
