import React from "react";
import Tophead from "./home/Tophead";
import Navbar from "./home/Navbar2";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "./home/Footer";
import Learnersys from "./home/Learnersys";
import Ourpartrner from "./home/Ourpartrner";

function layout() {
  return (
    <>
      <Tophead />
      <Navbar />
      <Outlet />
      <Learnersys />
      <Ourpartrner />
      <Footer />
      <ScrollRestoration/>
    </>
  );
}

export default layout;
