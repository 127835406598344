import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function All_coursesmodel({ show, handleClose }) {
  const [Alldata, setAlldata] = useState();

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiCoursesAll")
      .then((result) => {
        setAlldata(result.data.data);
      })
      .catch((err) => {
        // console.error("Error fetching courses:", err);
      });
  }, [setAlldata]);
  return (
    <>
      <Modal show={show} onHide={handleClose} className="all-model">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <div className="all-course">
          <h2>All Courses</h2>
          <div className="all-ul">
            <ul>
              {Alldata &&
                Alldata.map((val, index) => (
                  <li key={index}>
                    <Link to={`/Course/${val.title}/${val.id}`} key={index}>
                      <img
                        src={`${imagePath}${val?.img}`}
                        loading="lazy"
                        alt="_img"
                      />
                      {val?.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default All_coursesmodel;
