import React from "react";
import career from "../image/careertrack.png";
import { Link } from "react-router-dom";

function Careertrack() {
  return (
    <>
      <section className="careertrack">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img src={career} alt="career track" className="w-100 h-100" />
            </div>
            <div className="col-12 col-lg-6">
              <div className="right-career">
                <h5>Career Tracks</h5>
                <h2>IT Career Tracks</h2>
                <p>
                  Embark on your journey in the world of IT with your
                  comprehensive IT career Tracks. Gain experience and industry
                  recognition through specialized training and certifications
                  from leading authorities like Cisco, CompTIA, PMI, IIMA,
                  EC-Council and Microsoft. Start your fulfilling IT career
                  today by exploring our offerings.
                </p>
                <Link to="/career-track">
                  <button className="btn">Learn More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careertrack;
