import { Rating } from "@mui/material";
import React from "react";

const Examregisbanner = () => {
  return (
    <>
      <section className="examregisbanner">
        <div className="container h-100">
          <div className="examregis-content">
            <h1>Exam Registration</h1>
            <p>Exam Registration for all courses!</p>
            <Rating readOnly defaultValue={5} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Examregisbanner;
