import { Rating } from "@mui/material";
import React from "react";

const Freenightbanner = () => {
  return (
    <>
          <section className="freenigthbanner">
        <div className="container h-100">
          <div className="freenight-content">
            <h1>Free Career Nights</h1>
            <p>Night that could change your career!</p>
            <Rating readOnly defaultValue={5} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Freenightbanner;
