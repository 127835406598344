import React,{Suspense} from "react";
import Staffbanner from "./StaffService/Staffbanner";
import Deliverycontact from "./Delivery_Method/Deliverycontact";
import Staffcontent from "./StaffService/Staffcontent";
import Staffaccorimg from "./StaffService/Staffaccorimg";

function StaffRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Staffbanner />
        <Staffcontent />
        <Staffaccorimg />
        <Deliverycontact />
      </Suspense>
    </>
  );
}

export default StaffRoute;
