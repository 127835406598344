import React, { useState } from "react";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

function Deliverycontact() {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;

      if (!formik.isValidating) {
        const apiUrl =
          "https://git.digiwbs.com/Admin/public/api/apicallbackSend";
        axios
          .post(apiUrl, values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((result) => {
            Swal.fire({
              title: "success",
              text: "Form Submitted Successfully!",
              icon: "success",
            });

            formik.resetForm();
          })
          .catch((err) => {
            // console.error("Error submitting form:", err);
            Swal.fire({
              title: "error",
              text: "Error submitting form",
              icon: "error",
            });
          });
      }
    },
  });

  function onChange(value) {
    setRecaptchaToken(value);
  }

  return (
    <>
      <section className="delivery-contact">
        <div className="container">
          <h2>
            Have any questions? Call:{" "}
            <a
              href="tel:2485572480"
              style={{ color: "#f5aa30", textDecoration: "none" }}
            >
              248-557-2480
            </a>
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-clip">
              <h3>Get a Call back</h3>
            </div>
            <div className="formdiv">
              <div className="under-div">
                <div className="d-flex name ">
                  <div className="d-grid w-100">
                    <CommonComp
                      type={"text"}
                      placeholder={"First Name*"}
                      name={"first_name"}
                      id="first_name"
                      className={"first_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <p className="err">{formik.errors.first_name}</p>
                    )}
                  </div>
                  <div className="d-grid w-100">
                    <CommonComp
                      type={"text"}
                      placeholder={"Last Name*"}
                      name={"last_name"}
                      id="last_name"
                      className={"last_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <p className="err">{formik.errors.last_name}</p>
                    )}
                  </div>
                </div>
                <div className="d-flex name">
                  <div className="d-grid w-100">
                    <CommonComp
                      type={"email"}
                      placeholder={"Email*"}
                      name={"email"}
                      id="email"
                      className={"email"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="err">{formik.errors.email}</p>
                    )}
                  </div>
                  <div className="d-grid w-100">
                    <CommonComp
                      type={"number"}
                      placeholder={"Phone No.*"}
                      name={"phone"}
                      id="phone"
                      className={"phone"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <p className="err">{formik.errors.phone}</p>
                    )}
                  </div>
                </div>
                <div className="d-grid">
                  <CommonComp
                    type={"text"}
                    placeholder={"Address*"}
                    name={"address"}
                    id="address"
                    className={"address"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <p className="err">{formik.errors.address}</p>
                  )}
                </div>
                <div className="d-grid">
                  <textarea
                    cols={6}
                    rows={6}
                    placeholder="Comments*"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <p className="err">{formik.errors.message}</p>
                  )}
                </div>
                <ReCAPTCHA
                  className="g-recaptcha mt-3"
                  sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                  onChange={onChange}
                />
                <button
                  className="btn button1 w-100 mt-5 delivery-button"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Deliverycontact;
