import axios from "axios";
import React, { useEffect, useState } from "react";
const imagePath2 =
  process.env.IMAGE_PATH2 ||
  "https://git.digiwbs.com/Admin/public/build/images/testimonials/";

function TestimonialContent() {
  const [records, setRecords] = useState();
  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiTestimonials")
      .then((result) => {
        setRecords(result.data.Testimonials.data);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);
  return (
    <>
      <section className="testimonialcontent">
        <div className="container">
          <h2>Our wall of love</h2>
          <p>
            Global Information Technology strives to deliver a consistent
            top-of-class-training experience. Learn how our innovative learning
            methods impact <br />
            our students:
          </p>
          <div className="row">
            {records &&
              records.map((val) => (
                <div className="learner-card d-flex" id={val.id}>
                  <div className="">
                    <img
                      className="img-top"
                      src={`${imagePath2}${val?.img}`}
                      alt="clint_img"
                      loading="lazy"
                    />
                    <h5 className="clint-title">{val?.title}</h5>
                  </div>
                  <div
                    className="learner-text"
                    dangerouslySetInnerHTML={{
                      __html: val?.content,
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialContent;
