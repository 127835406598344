import React, { useState } from "react";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  job_title: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  background: Yup.string().required("Required"),
  reffered_by: Yup.string(),
  achieve_goals: Yup.string(),
  img: Yup.mixed()
    .required("Essay file is required")
    .test(
      "fileSize",
      "File size must be less than 2MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    ),
});

function SchoolerContent() {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company: "",
      job_title: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      background: "",
      reffered_by: "",
      achieve_goals: "",
      img: null,
      it_category: [],
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("img", values.img);

      // Append other form fields to FormData
      Object.keys(values).forEach((key) => {
        if (key !== "img") {
          formData.append(key, values[key]);
        }
      });

      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;

      axios
        .post(
          " https://git.digiwbs.com/Admin/public/api/apischolarshipSend",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            },
          }
        )
        .then((result) => {
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          formik.resetForm();
        })
        .catch((err) => {
          console.error("Error submitting form:", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });

  const handleCheckboxChange = (value) => {
    const isCheckboxChecked = formik.values.it_category.includes(value);

    if (isCheckboxChecked) {
      // If checkbox is already checked, remove it from the array
      formik.setValues({
        ...formik.values,
        it_category: formik.values.it_category.filter((item) => item !== value),
      });
    } else {
      // If checkbox is not checked, add it to the array
      formik.setValues({
        ...formik.values,
        it_category: [...formik.values.it_category, value],
      });
    }
  };

  function onChange(value) {
    setRecaptchaToken(value);
  }

  return (
    <>
      <section className="schoolership-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="schooler-contact">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-clip">
                    <h3>Get in Touch !</h3>
                  </div>
                  <div className="formdiv">
                    <div className="under-div">
                      <h3>The Global it Scholarship Application Form</h3>
                      <div className="d-flex name">
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"First Name*"}
                            name={"first_name"}
                            id="first_name"
                            className={"first_name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <p className="err">{formik.errors.first_name}</p>
                            )}
                        </div>
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"Last Name*"}
                            name={"last_name"}
                            id="last_name"
                            className={"last_name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                          />
                          {formik.touched.last_name &&
                            formik.errors.last_name && (
                              <p className="err">{formik.errors.last_name}</p>
                            )}
                        </div>
                      </div>
                      <div className="name d-flex">
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"email"}
                            placeholder={"Email Address*"}
                            name={"email"}
                            id="email"
                            className={"email"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <p className="err">{formik.errors.email}</p>
                          )}
                        </div>
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"number"}
                            placeholder={"Phone Number*"}
                            name={"phone"}
                            id="phone"
                            className={"phone"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <p className="err">{formik.errors.phone}</p>
                          )}
                        </div>
                      </div>
                      <div className="name d-flex">
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"Company Name*"}
                            name={"company"}
                            id="company"
                            className={"company"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company}
                          />
                          {formik.touched.company && formik.errors.company && (
                            <p className="err">{formik.errors.company}</p>
                          )}
                        </div>
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"Job Title*"}
                            name={"job_title"}
                            id="job_title"
                            className={"job_title"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.job_title}
                          />
                          {formik.touched.job_title &&
                            formik.errors.job_title && (
                              <p className="err">{formik.errors.job_title}</p>
                            )}
                        </div>
                      </div>
                      <div className="d-grid">
                        <CommonComp
                          type={"text"}
                          placeholder={"Address*"}
                          name={"address"}
                          id="address"
                          className={"address"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address && (
                          <p className="err">{formik.errors.address}</p>
                        )}
                      </div>
                      <div className="name d-flex">
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"City*"}
                            name={"city"}
                            id="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city && (
                            <p className="err">{formik.errors.city}</p>
                          )}
                        </div>
                        <div className="d-grid w-100">
                          <CommonComp
                            type={"text"}
                            placeholder={"State*"}
                            name={"state"}
                            id="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                          />
                          {formik.touched.state && formik.errors.state && (
                            <p className="err">{formik.errors.state}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-grid">
                        <CommonComp
                          type={"text"}
                          placeholder={"Zip*"}
                          name={"zip"}
                          id="zip"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.zip}
                        />
                        {formik.touched.zip && formik.errors.zip && (
                          <p className="err">{formik.errors.zip}</p>
                        )}
                      </div>
                      <textarea
                        className="w-100"
                        cols="4"
                        rows="3"
                        placeholder="How did you hear about this scholarship opportunity ?*"
                        name="reffered_by"
                        form="usrform"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reffered_by}
                      />
                      <textarea
                        className="w-100 "
                        cols="4"
                        rows="3"
                        placeholder="Tell little about your background.*"
                        name="background"
                        form="usrform"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.background}
                      />
                      <textarea
                        className="w-100 "
                        cols="4"
                        rows="3"
                        placeholder="How this scholarship help you achieve your goals?*"
                        name="achieve_goals"
                        form="usrform"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.achieve_goals}
                      />
                      <p>
                        Please upload a 750-word essay describing why you should
                        be awarded this scholarship, and what you plan to use it
                        for. The essay file format should be Microsoft Word.
                        <span style={{ color: "red" }}>*</span>
                      </p>

                      <label htmlFor="img" className="upload-essay">
                        <CloudUploadIcon className="me-2" /> Upload Essay
                        <input
                          type="file"
                          id="img"
                          name="img"
                          accept=".doc, .docx, .txt"
                          hidden
                          onChange={(event) =>
                            formik.setFieldValue(
                              "img",
                              event.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </label>
                      {formik.touched.img && formik.errors.img && (
                        <p className="err">{formik.errors.img}</p>
                      )}
                    </div>
                    <div className="under-div mt-3">
                      <div className="check mt-4">
                        <h4>
                          Which IT categories are you interested in?
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <div className="d-flex flex-wrap">
                          <div className="d-flex align-items-center w-50 it-category">
                            <input
                              type="checkbox"
                              id="microsoft_technical"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "microsoft_technical"
                              )}
                              onChange={() =>
                                handleCheckboxChange("microsoft_technical")
                              }
                            />
                            <p>Microsoft Technical</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="itil"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "itil"
                              )}
                              onChange={() => handleCheckboxChange("itil")}
                            />
                            <p>ITIL</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="compTIA"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "compTIA"
                              )}
                              onChange={() => handleCheckboxChange("compTIA")}
                            />
                            <p>CompTIA</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="business_analyst"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "business_analyst"
                              )}
                              onChange={() =>
                                handleCheckboxChange("business_analyst")
                              }
                            />
                            <p>Business Analyst</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="cisco"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "cisco"
                              )}
                              onChange={() => handleCheckboxChange("cisco")}
                            />
                            <p>Cisco (CCNA/CCNP)</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="microsoft_office"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "microsoft_office"
                              )}
                              onChange={() =>
                                handleCheckboxChange("microsoft_office")
                              }
                            />
                            <p>Microsoft Office</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="web_design"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "web_design"
                              )}
                              onChange={() =>
                                handleCheckboxChange("web_design")
                              }
                            />
                            <p>Web Design</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="java_programming"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "java_programming"
                              )}
                              onChange={() =>
                                handleCheckboxChange("java_programming")
                              }
                            />
                            <p>Java Programming</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="oracle"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "oracle"
                              )}
                              onChange={() => handleCheckboxChange("oracle")}
                            />
                            <p>Oracle</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="cyber_security"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "cyber_security"
                              )}
                              onChange={() =>
                                handleCheckboxChange("cyber_security")
                              }
                            />
                            <p>Cyber Security</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="qa_testing"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "qa_testing"
                              )}
                              onChange={() =>
                                handleCheckboxChange("qa_testing")
                              }
                            />
                            <p>QA Testing</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="microsoft_visio"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "microsoft_visio"
                              )}
                              onChange={() =>
                                handleCheckboxChange("microsoft_visio")
                              }
                            />
                            <p>Microsoft Visio</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="project_management"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "project_management"
                              )}
                              onChange={() =>
                                handleCheckboxChange("project_management")
                              }
                            />
                            <p>Project Management</p>
                          </div>
                          <div className="d-flex align-items-center w-25 it-category">
                            <input
                              type="checkbox"
                              id="ethical_hacker"
                              name="it_category"
                              checked={formik.values.it_category.includes(
                                "ethical_hacker"
                              )}
                              onChange={() =>
                                handleCheckboxChange("ethical_hacker")
                              }
                            />
                            <p>Certified Ethical Hacker</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-4">
                        <input
                          type="checkbox"
                          name="agreeToContact"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.agreeToContact}
                        />
                        <p className="m-0 ps-3" style={{ color: "#124C66" }}>
                          I agree to the rules and regulations
                          <span style={{ color: "red" }}>*</span>
                        </p>
                      </div>
                      {formik.touched.agreeToContact &&
                        formik.errors.agreeToContact && (
                          <p className="err">{formik.errors.agreeToContact}</p>
                        )}

                      <ReCAPTCHA
                        className="g-recaptcha mt-3"
                        sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                        onChange={onChange}
                      />
                      <button className="btn button1 w-100 mt-4" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h2 className="schooler-head">GLOBAL IT SCHOLARSHIP</h2>
              <p>
                Global Information Technology is proud to announce the Global IT
                Scholarship Program. Every month, the Global IT Scholarship will
                be awarded to one highly driven individual wanting to begin or
                advance their IT career. Exclusively, the scholarship will
                provide a grant of $2,500.00 towards any certification course or
                remote live training at Global Information Technology.
              </p>
              <p>
                The Global Information Technology Foundation created this IT
                Scholarship to give back to the community and spread our desire
                to help those in need. “We believe that learning is essential,
                and we will continue to help and develop individuals to help
                them succeed in their personal development goals.”
              </p>
              <p>
                Take advantage of this unique opportunity; apply for the Global
                IT Scholarship today!
              </p>
              <h3 style={{ color: "#F5AA30" }} className="mt-4 mb-4 number">
                Have any questions? Call:{" "}
                <a
                  href="tel:2485572480"
                  style={{ color: "#F5AA30", textDecoration: "none" }}
                >
                  248-557-2480
                </a>
              </h3>
              <h2>How To Apply</h2>
              <p>
                There are no strings attached; simply complete the following
                online application, and you will be entered to win.
              </p>
              <p className="best-query">
                If you have any questions about the Global IT Scholarship,
                please feel free to send us an Email: info@global-itech.com or call a
                Global Information Technology Staff member today at
                1-866-GO-GIT-GIT (464-4846). One scholarship will be awarded
                each month. Applications must be received by the 15th of the
                month to be considered for that month’s drawing, and winners
                will be announced at the end of the month, beginning in October
                2019. With an award-winning training program and top-quality
                instruction, there has never been a better time to advance your
                career with Global Information Technology–“The Premier Provider
                of IT Training and Consulting Services Since 1999.”
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SchoolerContent;
