import React,{Suspense} from "react";
import Schoolershipbanner from "./Schoolership/Schoolershipbanner";
import SchoolerContent from "./Schoolership/SchoolerContent";

function SchholershipRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Schoolershipbanner />
        <SchoolerContent />
      </Suspense>
    </>
  );
}

export default SchholershipRoute;
