import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Accordion } from "react-bootstrap";
import img from '../image/Group 1516.png'

function Staffaccorimg() {
  return (
    <>
      <section className="staffaccorimg">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-6">
              <img src={img} alt="sideimg" className="w-100" />
            </div>
            <div className="col-12 col-lg-6">
              <h2>Solution Driven</h2>
              <p>
                Our well-experienced and energetic team of employees first work
                to understand customer needs and they are fully capable in
                providing the right solution to achieve customer goals in the
                following cutting-edge areas of Information Technology:
              </p>
              <div className="w-100 d-flex flex-wrap">
                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>
                    Helpdesk/Technical Support Network Architecture &
                    Administration
                  </p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>
                    Business Analysts, Project Managers, and Software Testers
                  </p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>
                    Web Development/Programming (HTML, ASP, JavaScript, Perl,
                    CGI)
                  </p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>C/C++, Java & Visual Basic Programming</p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>
                    Database Development & Administration(Oracle, SQL,
                    MS-Access)
                  </p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: "#053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>System Integration</p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>Software & Hardware Technicians</p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>Telecommunication Engineering/ Analysis</p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: " #053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>E-Commerce</p>
                </div>

                <div className="d-flex w-50">
                  <ArrowForwardIcon
                    style={{
                      color: "#053347",
                      fontSize: "20px",
                      marginTop: "4px",
                    }}
                  />
                  <p>Data Entry</p>
                </div>
              </div>
            </div>            
          </div>
          
          <div className=" staff-accor">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Temporary or Contract</Accordion.Header>
                <Accordion.Body>
                  Our clients benefit from the flexibility of a contingent
                  workforce to supplement short-term and long-term staffing
                  needs for vacation leave, family medical leave, holidays,
                  seasonal requirements, contract-to-hire, filling open
                  positions during hiring freezes, production peaks and valleys,
                  and special projects. Our clients are relieved of the
                  responsibility of worker’s compensation claims, statutory
                  taxes, unemployment claims, and the management of the daily HR
                  issues as they pertain to coaching, counseling, hiring
                  terminations, and other related issues.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Direct Hire Placement</Accordion.Header>
                <Accordion.Body>
                  Our direct hire placement services focus on providing our
                  clients with outstanding, pre-screened, pre-qualified
                  candidates. Our diverse recruitment network enables us to
                  identify top talent and place them into our extensive
                  candidate database. Here, all candidates’ skill levels and
                  assessment scores are available for a custom match to your
                  specific job requirements.
                  <br />
                  Our direct hire placement services are performed on a
                  contingency fee basis only, which means you pay us a fee only
                  after hiring one of our candidates.
                  <br />
                  Allow us the opportunity to demonstrate the personal,
                  dedicated service that has made us so successful since 1999.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Contract to Hire</Accordion.Header>
                <Accordion.Body>
                  Are you still having your staff invest countless hours
                  attempting to recruit, evaluate, screen, and interview
                  candidates for your open positions? By utilizing our
                  contract-to-hire services, you can shift those administrative
                  burdens onto our experienced shoulders. We’ve been supporting
                  this function for our clients since 1999.
                  <br />
                  While we retain the contract to hire an employee on our
                  payroll and benefits package, you have the opportunity to
                  observe their skill sets and determine their compatibility
                  with your company culture. Once the agreed-upon time frame has
                  been completed, you may choose to hire your well-tested
                  employee without incurring a placement fee.
                  <br />
                  While contract-to-hire is not ideal for every situation, in
                  our current market candidates are often open to showing you
                  what they can do in hopes of securing a job with a great
                  company.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Staffaccorimg;
