import React from "react";

const CommonComp = ({
  type,
  placeholder,
  name,
  className,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <input
      type={type}
      placeholder={!placeholder ? "please enter" : placeholder}
      name={name}
      className={`commoncss ${className}`}
      onChange={onChange} // Ensure that onChange is passed down to the input
      onBlur={onBlur} // Ensure onBlur is passed down to the input
      value={value} // Ensure value is passed down to the input
    />
  );
};

export default CommonComp;
