import React, { Suspense } from "react";
import Firstbanner from "./Craeerfirst/Firstbanner";
import Firstcontent from "./Craeerfirst/Firstcontent";

function Careerroute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Firstbanner />
        <Firstcontent/>
      </Suspense>
    </>
  );
}

export default Careerroute;
