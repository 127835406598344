import React, { useState } from "react";
import Registermodal from "../../home/Registermodal";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Javasebanner({ image, title, preReq, background }) {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="javase">
        <img
          src={`${imagePath}${background}`}
          loading="lazy"
          className="bg-image"
          alt="bg_image"
        />
        <div className="container h-100">
          <div className="java-banner d-grid">
            <>
              <img
                src={`${imagePath}${image}`}
                alt="logo"
                className="javalogo"
                loading="lazy"
              />
              <h1>{title}</h1>
              <div style={{ borderLeft: "4px solid #F5AA30" }}>
                <h2>
                  Take Your Career to the Next Level with
                  <br />
                  {title} Certification
                </h2>
              </div>
              <p>{preReq}</p>
              <button
                className="btn button1 btn-sm mt-4"
                onClick={openregisModal}
              >
                Apply Now
              </button>
              <img
                src={`${imagePath}${image}`}
                className="upimage"
                alt="bg-logo"
                loading="lazy"
              />
            </>
          </div>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Javasebanner;
