import React from "react";
import ContactForm from "../ContactForm";

function Admissionform() {
  return (
    <>
      <section className="admissionform ">
        <div className="container">
          <h2>
            Start Building the Pathway to Your Future IT Career with Global
            Information Technology
          </h2>
          <p className="form-para">
            Ready to get started? You can fill out the form below to start your
            application with the Global Information Technology admissions team.
            They will review your submission and reach out to you to answer any
            questions you may have.
          </p>
          <ContactForm />
        </div>
      </section>
    </>
  );
}

export default Admissionform;
