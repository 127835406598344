import React from "react";
import microsoft from "../image/micro (1).png";
import itil from "../image/ITIL-Logo 1.png";
import microffice from "../image/clint11.png";
import cisco from "../image/clint4.png";
import comptia from "../image/clint5.png";
import cyber from "../image/clint7.png";
import sunorcle from "../image/clint8.png";
import iiba from "../image/iiba.png";
import ecouncil from "../image/clint3.png";
import projectmanage from "../image/clint2.jpg";
import Slider from "react-slick";

function Ourpartrner() {
  const organizations = [
    { name: "Microsoft", imageSrc: microsoft },
    { name: "Microffice", imageSrc: microffice },
    { name: "Cisco", imageSrc: cisco },
    { name: "ITIL", imageSrc: itil },
    { name: "CompTIA", imageSrc: comptia },
    { name: "Cyber", imageSrc: cyber },
    { name: "Sunorcle", imageSrc: sunorcle },
    { name: "IIBA", imageSrc: iiba },
    { name: "ECouncil", imageSrc: ecouncil },
    { name: "ProjectManage", imageSrc: projectmanage },
  ];

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  return (
    <>
      <section className="partner">
        <h2>trusted by the world's Largest organizations</h2>
        <Slider {...settings}>
          {organizations.map((org, index) => (
            <div key={index} className="slide">
              <img src={org.imageSrc} alt={org.name} loading="lazy" />
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
}

export default Ourpartrner;
