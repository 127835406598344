import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import logo from "../image/logo-git-1.png";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CourseDropdown from "./Dropdown";
import Searchmodal from "./Searchmodal";
import Registermodal from "./Registermodal";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("school");
  const [showModal, setShowModal] = useState(false);
  const [showregisModal, setShowregisModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  const handleItemClick = (item, e) => {
    e.preventDefault(); // Prevent default behavior (e.g., navigating to a new page)
    e.stopPropagation();
    if (selectedItem !== item) {
      setSelectedItem(item);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="menubg">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img className="logo" src={logo} alt="Logo" loading="lazy" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse align-items-center justify-content-evenly"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav all align-items-center">
                <li className="nav-item pe-3 pb-2">
                  <NavLink to="/">
                    <HomeIcon style={{ fontSize: "22" }} />
                  </NavLink>
                </li>
                <CourseDropdown
                  handleCategoryClick={handleCategoryClick}
                  selectedCat={selectedCategory}
                />
                <li className="nav-item dropdown pe-2 service-li">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    href=" "
                  >
                    Services
                  </a>
                  <div
                    className="dropdown-menu service-drop mt-3"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <div className="row d-flex h-100 ">
                      <div className="col-lg-4 left-side">
                        <button
                          className={`dropdown mt-3 ${
                            selectedItem === "school" ? "activee" : ""
                          }`}
                          onClick={(e) => handleItemClick("school", e)}
                        >
                          Student Services
                        </button>
                        <button
                          className={`dropdown ${
                            selectedItem === "class" ? "activee" : ""
                          }`}
                          onClick={(e) => handleItemClick("class", e)}
                        >
                          Consulting
                        </button>
                      </div>
                      <div className="right-side col-lg-8">
                        {selectedItem === "school" && (
                          <ul className="mt-3">
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/ClassSchedules"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Class Schedule
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/Schoolership">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Scholarship
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/FreeCareerPlanning"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Free Career Planning
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/JobPlacement">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Job Placement
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/financialAid">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Financial Aid
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/freeWorkShop">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Free Workshops
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/training_solution"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Training Solutions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/delivery_method"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Delivery Method
                              </NavLink>
                            </li>
                            <li></li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/student_service"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Student Login
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/faq">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                FAQ
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/plan_your_career"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Plan Your Career
                              </NavLink>
                            </li>
                          </ul>
                        )}
                        {selectedItem === "class" && (
                          <ul className="mt-3">
                            <li>
                              <NavLink className="dropdown" to="/web_design">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Web Design
                              </NavLink>
                            </li>
                            <li>
                              <NavLink className="dropdown" to="/staff_service">
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Staffing
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className="dropdown"
                                to="/network_service"
                              >
                                <ArrowForwardIosIcon
                                  className="me-1"
                                  sx={{ fontSize: 14 }}
                                  style={{ color: "#28a9e0" }}
                                />
                                Networking
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item pe-2">
                  <NavLink
                    to="/admission"
                    className="nav-link "
                    activeclassname="active"
                  >
                    Admissions
                  </NavLink>
                </li>
                <li className="nav-item pe-2">
                  <NavLink to="/ClassSchedules" className="nav-link ">
                    Class Schedules
                  </NavLink>
                </li>
                <li className="nav-item pe-2">
                  <NavLink to="/vetrans" className="nav-link ">
                    Veterans
                  </NavLink>
                </li>
                <li className="nav-item dropdown pe-2">
                  <NavLink
                    to="https://globalinformation.applicantpro.com/jobs/"
                    target="_blank"
                    className="nav-link "
                    activeclassname="active"
                  >
                    Job Board
                  </NavLink>
                </li>                
                <li className="nav-item dropdown pe-2">
                  <a
                    className="nav-link dropdown-toggle abouttt"
                    activeclassname="active"
                    id="navbarDropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    href=" "
                  >
                    About Us
                  </a>
                  <div
                    className="dropdown-menu about-li mt-3"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <NavLink className="dropdown-item" to="/about_us">
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Overview
                    </NavLink>

                    <HashLink
                      to="/about_us/#corporate_value"
                      className="dropdown-item"
                    >
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Corporate Values
                    </HashLink>

                    <HashLink
                      to="/about_us/#corporate_strategy"
                      className="dropdown-item"
                    >
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Corporate Strategy
                    </HashLink>

                    <HashLink to="/about_us/#partner" className="dropdown-item">
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Our Partner
                    </HashLink>

                    <HashLink to="/about_us/#career" className="dropdown-item">
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Careers
                    </HashLink>

                    <HashLink
                      to="/about_us/#rent_lab"
                      className="dropdown-item"
                    >
                      <ArrowForwardIosIcon
                        className="me-1"
                        sx={{ fontSize: 14 }}
                        style={{ color: "#28a9e0" }}
                      />
                      Lab Rental
                    </HashLink>
                  </div>
                </li>
                <li className="nav-item dropdown pe-2">
                  <NavLink
                    to="/contactUs"
                    className="nav-link "
                    activeclassname="active"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center search-regi">
              <div className=" pe-2">
                <span className="search-btn" onClick={openModal}>
                  <SearchIcon sx={{ fontSize: 30 }} />
                </span>
              </div>
              <li
                className="btn button1 btn-sm mx-2 my-2"
                onClick={openregisModal}
              >
                Register
              </li>
            </div>
          </div>
        </nav>
      </div>
      <Searchmodal show={showModal} handleClose={closeModal} />
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Navbar;
