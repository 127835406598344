import React,{Suspense} from "react";
import Workshopbanner from "./FreeWorkshop/Workshopbanner";
import Workshopcontent from "./FreeWorkshop/Workshopcontent";

function WorkshopRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Workshopbanner />
        <Workshopcontent />
      </Suspense>
    </>
  );
}

export default WorkshopRoute;
