import { Rating } from "@mui/material";
import React from "react";

function Jobplacementbanner() {
  return (
    <>
      <section className="jobplacement">
        <div className="container h-100">
          <div className="placement-content">
            <h1>Job Placement Services</h1>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Jobplacementbanner;
