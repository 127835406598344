import React from "react";
import image1 from "../image/trainingcorporate.jpg";
import image2 from "../image/trainingindividual.jpg";
import image3 from "../image/traininggoverment.jpg";
import image4 from "../image/trainingdirective.jpg";

function Trainingcontent() {
  return (
    <>
      <section className="trainingcontent ">
        <div className="container">
          <div className="row training-row">
            <div className="col-12 col-lg-6">
              <img src={image1} alt="corporate" />
            </div>
            <div className="col-12 col-lg-6">
              <h2> CORPORATE SOLUTIONS</h2>
              <p>
                Let Global Information Technology upgrade your employees with
                the most current certifications and skills proven to keep your
                entire company on the cutting edge of technology while
                maintaining productivity. We’ve trained employees from a very
                diverse group of companies with excellent performance-enhancing
                results. Training with us increases employee efficiency,
                provides measurable improvement for your training dollars, and
                develops learning plans known to increase employee
                effectiveness. Corporate IT learning resources include an
                account management team available to analyze, develop, and
                implement learning programs that fit your company’s needs. We
                provide professional subject matter experts to develop custom
                learning and technology solutions to help you achieve long-term
                growth in technology. We provide a client support professional
                to assist with scheduling, registration, and billing, plus
                reporting to track the effectiveness of your IT learning
                investment.
              </p>
            </div>
          </div>

          <div className="row training-row">
            <div className="col-12 col-lg-6 order">
              <h2> INDIVIDUAL SOLUTIONS</h2>
              <p>
                Global Information Technology will help you gain the technology
                skills that companies are demanding. Whether you are looking to
                change careers, update IT skills, or advance in your current
                career, we have the right IT training for you. We have helped
                fuel ambitious, goal-driven people by providing the technology
                training most demanded by companies looking to hire and retain
                top technology talent. Change can be intimidating, but our
                certified courses make your learning experience exciting. Our
                performance-based training strategy ensures your success and
                prepares you for a fulfilling IT career at your pace and on your
                schedule.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img src={image2} alt="individual" />
            </div>
          </div>

          <div className="row training-row">
            <div className="col-12 col-lg-6">
              <img src={image3} alt="goverment" />
            </div>
            <div className="col-12 col-lg-6">
              <h2>GOVERNMENT SOLUTIONS</h2>
              <p>
                Global Information Technology can help you reach and establish
                your technology goals very cost-effectively. We understand the
                unique issues and skill requirements of our military and
                government agencies. For 14 years, we have provided certified
                training courses that coincide with military and government
                agencies that improve operation efficiency, optimize cyber
                security efforts, and meet requirements for specific Department
                of Defense job directives. Global Information Technology has the
                cyber-security solution training expertise to thoroughly train
                Department of Defense (DoD) personnel and contractors to comply
                with the very specific certification requirements of Directive
                8570.
              </p>
              <p>
                Ready to bring IT Training to your workplace? Contact a Global
                Information Technology Account Executive today!
              </p>
            </div>
          </div>

          <div className="row training-row">
            <div className="col-12 col-lg-6 order">
              <h2>OUR SUITE OF DIRECTIVE SOLUTIONS</h2>
              <ul>
                <li>
                  Baseline Certification training for personnel performing
                </li>
                <li>
                  Continuous Education/Sustainment training to maintain
                  certification status and further develop skills
                </li>
                <li>
                  Certification training for a specific operating system
                  environments
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              <img src={image4} alt="directive" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Trainingcontent;
