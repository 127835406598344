import React from "react";
import why from "../image/Group 1491.png";

function Webwhyglobalit() {
  return (
    <>
      <section className="webwhy ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img src={why} alt="why-us" className="w-100" loading="lazy" />
            </div>
            <div className="col-12 col-lg-6 pt-5">
              <h2>Why Global IT?</h2>
              <p>
                To have a dominant presence online, designing and implementing a
                functional website is the most fundamental and crucial step.
                However, it does not necessarily follow that simply having a
                website online is going to meet your goals. Companies with the
                expertise as well as a great deal of web industry exposure can
                bring forth such attractive, powerful, and user-friendly
                websites.
              </p>
              <p>
                At GIT, we pride ourselves on taking your vision and placing it
                online. We emphasize the importance of fully understanding your
                needs to meet your requirements within the frame of time
                allotted. Your feedback is the catalyst for each phase of the
                development process.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Webwhyglobalit;
