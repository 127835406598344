import React,{Suspense} from 'react'
import Referralbanner from './ReferralProgram/Referralbanner'
import Refrralcontent from './ReferralProgram/Refrralcontent'

const RefrralProgramRoute = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Referralbanner />
        <Refrralcontent />
      </Suspense>
    </>
  );
}

export default RefrralProgramRoute