import React,{Suspense} from "react";
import Studentbanner from "./Student-login/Studentbanner";
import Studentcontent from "./Student-login/Studentcontent";

function StudentloginRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Studentbanner />
        <Studentcontent />
      </Suspense>
    </>
  );
}

export default StudentloginRoute;
