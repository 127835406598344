import React from "react";
import ContactForm from "../ContactForm";
import { Link } from "react-router-dom/dist";
import gifvid from "../Video/Gifplay.gif";

function Contactform() {
  return (
    <>
      <section className="get-ans">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-6 con-col">
              <h2 className="contact-head">Get Every Single Answer here.</h2>
              <p className="get-para">
                Got questions? We've got answers! Explore our concise FAQ.
                section for quick solutions to common queries. Your learning
                journey starts with clarity–dive in and discover more with
                Global IT Solutions.
              </p>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      How does GIT set itself apart from the competition?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Customer satisfaction is at the top of our list of
                      priorities, so we go to great lengths to provide every
                      possible resource we can for our students to succeed in
                      their goals. We understand that our success is 100%
                      contingent upon the success of our students, so we are
                      willing to go to great lengths to help you succeed with
                      high-quality training at an affordable price.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Why should I choose your school?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Choosing a training provider can be a tough choice. We all
                      claim to offer the best value in terms of price, passing
                      rates, and guarantee. GIT provides the lowest price, one
                      of the best training materials, and talented instructors.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Does GIT offer job placement assistance?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, Global I.T. will help you prepare a professional
                      resume and schedule mock interviews with managers and
                      technical experts within the industry.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      GIT financial AID assistance?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Global Information Technology students have some financial
                      aid options, including government funding, Veterans
                      Administration programs, and private bank loans.
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mt-3">
                <button className="btn read-more">
                  <Link to="/faq">Read More..</Link>
                </button>
              </div>
              <div className="gif">
                <img src={gifvid} alt="Global It" className="mt-5 w-100" />
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactform;
