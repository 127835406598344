import React from "react";
import { Accordion } from "react-bootstrap";
import sideimg from "../image/financial_content.png";
import { Link } from "react-router-dom";

function FinancialContent() {
  return (
    <>
      <section className="finanicial-accor">
        <div className="container">
          <div className="d-flex financial-para">
            <img src={sideimg} alt="information" />
            <div className="">
              <h2> Global Information Technology</h2>
              <p>
                offers a variety of different payment assistance programs to
                choose from to help you achieve your personal and professional
                IT education objectives. As noted below, some of these sources
                you can qualify for, while others require an application. Your
                career consultant at Global I.T. can help you identify the
                funding sources available to you and help you get started in
                your program of choice.
              </p>
              <strong className="mt-4">
                “GI Bill® is a registered trademark of the U.S. Department of
                Veterans Affairs (VA). More information about education benefits
                offered by the VA is available at the official U.S. government
                website at{" "}
                <Link
                  to="https://www.benefits.va.gov/gibill/"
                  target="_blank"
                  style={{ color: "#f5aa30", textDecoration: "none" }}
                >
                  http://www.benefits.va.gov/gibill“
                </Link>
                .
              </strong>
            </div>
          </div>

          <div className=" mt-5">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  01. &nbsp; Veteran’s (VA) Benefits
                </Accordion.Header>
                <Accordion.Body>
                  Global Information Technology is approved to accept students
                  who are eligible to receive Veteran’s Administration
                  Educational Benefits (G.I. Bill). Need help to pick out the
                  right educational benefits? This website will help you
                  understand which chapter or active benefit best fits you:{" "}
                  <Link
                    to="https://www.va.gov/education/gi-bill-comparison-tool/"
                    target="_blank"
                    style={{ color: "#f5aa30", textDecoration: "none" }}
                  >
                    Best Educational Benefits for You.
                  </Link>{" "}
                  Please contact Global Information Technology’s career
                  consultant at 248-557-2480 for more information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className=" mt-3">
                <Accordion.Header>
                  02. &nbsp; Partnership, Accountability, Training, Hope (PATH)
                </Accordion.Header>
                <Accordion.Body>
                  For low-income families, the PATH program provides:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Work support and education and training opportunities are
                      needed to help them get good-paying jobs that move their
                      families out of poverty.
                    </li>
                    <li>
                      A broader and more intensive range of services, including
                      job placement assistance, job readiness services, remedial
                      education, training, high school completion,
                      post-secondary education, and ongoing post-employment
                      supports and services.
                    </li>
                  </ul>
                  <br />
                  You must be approved by the local workforce development agency
                  to receive this type of funding. For more information about
                  the program, please contact a Global Information Technology
                  career consultant at 248-557-2480.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className=" mt-3">
                <Accordion.Header>
                  03. &nbsp; Post 9/11 GI Bill®
                </Accordion.Header>
                <Accordion.Body>
                  (A New Bill for a New Century) Global Information Technology
                  is honored to serve our nation’s veterans as a Post-9/11 GI
                  Bill®-approved education provider and authorized training
                  provider with the Department of Veterans Affairs. The Post
                  9/11 GI Bill® provides education benefits for service members
                  who have served on active duty for 90 or more days after
                  September 11, 2001. The most robust military education grant
                  ever enacted, the new Post 9/11 GI Bill® covers more than just
                  tuition fees. Depending on how many months you served, the
                  Post 9/11 GI Bill® can cover numerous training-related costs,
                  including books, supplies, lodging, certification exams,
                  relocation, and even free training for veterans’ spouses and
                  children. Contact Global Information Technology at
                  1-866-G0-GIT-GO to help you figure out the exact amount of
                  education benefits you’ve earned. Most Post-9/11 GI Bill®
                  students at Global Information Technology enjoy 100% tuition
                  coverage.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className=" mt-3">
                <Accordion.Header>
                  04. &nbsp; Dislocated Worker Training (DWT) National Emergency
                  Grant (NEG)
                </Accordion.Header>
                <Accordion.Body>
                  The purpose of the National Dislocated Worker Grant program is
                  to temporarily expand service capacity at the state and local
                  levels by providing time-limited funding assistance in
                  response to significant dislocation events. Significant events
                  are those that create a sudden need for assistance that cannot
                  reasonably be expected to be accommodated within the ongoing
                  operations of the formula-funded Dislocated Worker program,
                  including the discretionary resources reserved at the state
                  level.
                  <br />
                  <br />
                  For more information and assistance about the program, please
                  contact the Global Information Technology career consultant at
                  248-557-2480.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className=" mt-3">
                <Accordion.Header>
                  05. &nbsp; MyCAA Program Funding
                </Accordion.Header>
                <Accordion.Body>
                  The Department of Defense (DoD) Military Spouse Career
                  Advancement Accounts (MyCAA) program provides up to $4,000 of
                  financial assistance for military spouses who are pursuing
                  degree programs, licenses, or credentials leading to
                  employment in portable career fields. For more information,
                  contact a Global Information Technology VA SCO Consultant at
                  248-557-2480.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className=" mt-3">
                <Accordion.Header>06. &nbsp; JET</Accordion.Header>
                <Accordion.Body>
                  The JET Program helps those receiving public assistance find
                  employment and achieve economic self-sufficiency. The program
                  offers comprehensive assessment, job search assistance,
                  training, support services, and follow-up services. Eligible
                  individuals are referred by the Department . of Human
                  Services. Those individuals receiving Temporary Assistance for
                  Needy Families (TANF), food stamps, Medicaid benefits, or
                  child care benefits may be eligible. Additionally, eligible
                  individuals are referred by local Friends of the Court
                  offices. Participation in the program may be mandatory or
                  voluntary, depending on the federal and state policies
                  governing the program. If you are not currently receiving
                  assistance from DHS and think you may qualify for assistance,
                  apply in person at your local Michigan Department of Human
                  Services.
                  <br />
                  <br /> For more information, contact Global Information
                  Technology at 248-557-2480 for directions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className=" mt-3">
                <Accordion.Header>
                  07. &nbsp; Vocational Rehabilitation (VR & E) - Chapter 31
                </Accordion.Header>
                <Accordion.Body>
                  VR&E’s primary benefit program is vocational rehabilitation
                  services for veterans who have a service-connected disability
                  of 10% or higher. To receive services, a veteran must be found
                  both eligible and entitled. The desired outcome of these
                  services is to lead disabled veterans to suitable employment
                  that is consistent with their aptitudes and interests or to
                  achieve independence in their daily lives. <br />
                  <br />
                  Follow the link for more information on the{" "}
                  <Link
                    to="https://www.benefits.va.gov/vocrehab/index.asp"
                    target="_blank"
                    style={{ color: "#f5aa30", textDecoration: "none" }}
                  >
                    Vocational Rehabilitation and Employment (VR&E) VetSuccess
                    Program.
                  </Link>{" "}
                  Please contact Global Information Technology’s career
                  consultant at 248-557-2480 for any further assistance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className=" mt-3">
                <Accordion.Header>
                  08. &nbsp; Michigan Rehabilitation Services (MRS)
                </Accordion.Header>
                <Accordion.Body>
                  MRS is the federally mandated general public rehabilitation
                  agency in Michigan, which provides an array of services for
                  persons with disabilities to prepare for, attain, and maintain
                  employment, self-sufficiency, and an independent lifestyle.
                  MRS is housed within the Department of Labor and Economic
                  Growth (DLEG) and is federally funded with a required state
                  match. The MRS full-time civil service staff is nearly 550,
                  with approximately 260 certified master's-level rehabilitation
                  counselors who work with customers in job exploration, job
                  training, and placement. MRS has 36 offices located
                  geographically across the state, with counselors located in
                  each Michigan state! One-Stop Service Centers. For more, call
                  one of our career consultants at 248-557-2480.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8" className=" mt-3">
                <Accordion.Header>
                  09. &nbsp; Michigan Works Grants/ Workforce Innovation And
                  Opportunity Act (WIOA)
                </Accordion.Header>
                <Accordion.Body>
                  You may be eligible for funding to assist with the cost of
                  training if you are unemployed and/or a “dislocated” worker
                  (such as those who lose their jobs because of permanent
                  layoffs or plant closings), receiving unemployment payments,
                  having unemployment benefits expired, having a bridge card,
                  working but having a low income, or being a youth(18–24) who
                  is unemployed. Global Information Technology is an approved,
                  eligible training provider (authorized to accept WIOA funding)
                  in Michigan. For further information on the WIOA program,
                  contact the local One-Stop Career Center through this link:{" "}
                  <Link
                    to="https://www.michiganworks.org/about-michigan-works/one-stop-service-centers"
                    target="_blank"
                    style={{ color: "#f5aa30", textDecoration: "none" }}
                  >
                    http://www.michiganworks.org/about-michigan-works/one-stop-service-centers
                  </Link>{" "}
                  Once approved, the Global Information Technology career
                  consultant completes all of the necessary paperwork to secure
                  funding before enrollment. Please contact the Global
                  Information Technology Career Consultant at 248-557-2480 for
                  more information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9" className=" mt-3">
                <Accordion.Header>
                  10. &nbsp; Tuition Assistance from your employer or through
                  UAW
                </Accordion.Header>
                <Accordion.Body>
                  Global Information Technology is approved to participate in
                  tuition assistance and scholarship programs provided by
                  UAW-Chrysler, UAW-Ford, and UAW-GM for employees, retirees,
                  and their eligible dependent children. Contact your local
                  union to determine your eligibility. We also accept tuition
                  reimbursement from your employer. Call 248-557-2480 for
                  further information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10" className=" mt-3">
                <Accordion.Header>
                  11. &nbsp; Trade Adjustment Assistance (TAA) Funding
                </Accordion.Header>
                <Accordion.Body>
                  The Trade Adjustment Assistance (TAA) program is a federal
                  entitlement program established under the Trade Act of 1974,
                  as amended. The TAA program provides aid to workers who lose
                  their jobs or whose hours of work and wages are reduced as a
                  result of increased imports. TAA offers a variety of benefits
                  and re-employment services to assist unemployed workers in
                  preparing for and obtaining suitable employment. Workers may
                  be eligible for training, job search and relocation
                  allowances, income support, and other re-employment services.
                  Individuals must be approved by the local workforce
                  development agency to receive this type of funding. For more
                  information about TAA funding visit{" "}
                  <Link
                    to="https://www.dol.gov/agencies/eta"
                    target="_blank"
                    style={{ color: "#f5aa30", textDecoration: "none" }}
                  >
                    http://www.doleta.gov/tradeact
                  </Link>{" "}
                  or mention this program to a Global career consultant. Call
                  248-557-2480 for further information.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11" className=" mt-3">
                <Accordion.Header>12. &nbsp; Student Loans</Accordion.Header>
                <Accordion.Body>
                  We’re happy to help you advance your career, and we believe
                  money should not be an impediment to your success. Sometimes
                  making an investment in your future requires financial
                  assistance and resources that students aren’t aware are
                  available to them. Global Information Technology is committed
                  to helping you find the best solution. We have various student
                  loans available through our financial partners.
                  <br /> <br />
                  Some common features of student loans include:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Inclusion of all educational expenses such as training
                      costs, fees, books, exams, computer hardware, and
                      software.
                    </li>
                    <li>Low interest rates.</li>
                    <li>Deferment options.</li>
                    <li>No prepayment penalties.</li>
                    <li>Repayment options.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default FinancialContent;
