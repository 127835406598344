import { ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Relatedcard({ related }) {
  return (
    <>
      <section className="related">
        <div className="container">
          <div className="row">
            {related &&
              related.map((val, index) => (
                <div
                  className="card"
                  key={index}
                >
                  <div className="card-title">
                    <img src={`${imagePath}${val.img}`} alt={val.title} />
                    <h4>{val.title}</h4>
                  </div>                   
                    <Link to={`/Course/${val.title}/${val.id}`} key={val.id}>
                      <button>
                        Learn More
                        <ArrowForwardIos
                          className="ms-1"
                          sx={{ fontSize: 16 }}
                        />
                      </button>
                    </Link>
                </div>
              ))}           
          </div>
        </div>
      </section>
    </>
  );
}

export default Relatedcard;
