import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Rating } from "@mui/material";
import union from "../image/Union.svg";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
const imagePath2 =
  process.env.IMAGE_PATH2 ||
  "https://git.digiwbs.com/Admin/public/build/images/testimonials/";

function Learnersys() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [records, setRecords] = useState();
  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiTestimonials")
      .then((result) => {
        setRecords(result.data.Testimonials.data);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);

  return (
    <>
      <section className=" learner">
        <div className=" container">
          <h2 className="toptext">What Our Learners Say</h2>
          <p className="toppera">
            We place a huge value on strong relationships. Customer feedback is
            <br />
            vital in helping us to get it right.
          </p>
          <Slider {...sliderSettings}>
            {records &&
              records.map((val) => (
                <React.Fragment key={val.id}>
                  <div className="card" key={val.id}>
                    <div className="clint-body d-flex">
                      <div className="clintimg">
                        <img
                          className="union"
                          src={union}
                          loading="lazy"
                          alt="bg-clint"
                        />
                        <img
                          className="img-top"
                          src={`${imagePath2}${val?.img}`}
                          alt="clint_img"
                          loading="lazy"
                        />
                      </div>
                      <div className="clintid">
                        <h5 className="clint-title">{val?.title}</h5>
                      </div>
                      <Rating
                        name="read-only"
                        defaultValue={5}
                        readOnly
                        className="mt-4 ms-4"
                      />
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: val?.content.slice(0, 550) + " ...",
                      }}
                    />
                    <HashLink
                      to={`/testimonial/#${val.id}`}
                      className="read_more"
                    >
                      Read More
                    </HashLink>
                  </div>
                </React.Fragment>
              ))}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Learnersys;
