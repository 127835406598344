import React from "react";
import { Rating } from "@mui/material";

function Plancarerbanner() {
  return (
    <>
      <section className="planbanner">
        <div className="container h-100">
          <div className="plan-content text-center">
            <h1>Your path starts here</h1>
            <p>
              Discover a course or certificate program that’s right for you.
            </p>
            <Rating name="rad-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Plancarerbanner;
