import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import lab from "../image/Rentlabimage.png";

function Rentlabcontent() {
  return (
    <>
      <section className="rentcontent " id="rent_lab">
        <div className="container">
          <h2>lab Rental</h2>
          <div className="labcontent">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Computer labs in the dedicated training environment</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>
                    Ability to host live online courses (mics, video, and
                    speakers)
                  </p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Classroom sizes from 4-16 people</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>High-end PCs</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Ceiling Projectors, Whiteboards</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Setup included</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Wired and Wireless Internet</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Client welcome service included</p>
                </div>
                <div className="d-flex">
                  <CheckCircleIcon style={{ color: "#00A5DD" }} />
                  <p>Free parking</p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={lab}
                  alt="rent-lab"
                  className="w-100 h-100"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Rentlabcontent;
