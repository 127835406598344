import React from "react";
import { Link } from "react-router-dom";

function Usefulllink() {
  return (
    <>
      <div className="usefullink">
        <ul className="courselist">
          <li>
            <Link to="/delivery_method">Delivery methods </Link>
          </li>

          <li>
            <Link to="/training_solution">Training Solutions</Link>
          </li>
          <li>
            <Link to="/FreeCareerPlanning">Free Career Planning</Link>
          </li>
          <li>
            <Link to="/freeWorkShop">Free Workshops</Link>
          </li>
          <li>
            <Link to="/career-nights">Free Career Nights</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/exam-registration">Exam Registration</Link>
          </li>
          <li>
            <Link to="/refrral_program">Referral Program</Link>
          </li>
          <li>
            <Link to="/FreeCareerPlanning">Plan Your Career</Link>
          </li>
          <li>
            <Link to="/testimonial">Testimonials</Link>
          </li>
          <li>
            <Link to="/about_us">Why us?</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Usefulllink;
