import React from "react";
import { Rating } from "@mui/material";
function Admissionbanner() {
  return (
    <>
      <section className="admissionbanner">
        <div className="container h-100">
          <div className="admission-content">
            <h1>Admissions</h1>
            <p>
              Start Building the Pathway to Your Future IT Career with Global
              Information Technology
            </p>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Admissionbanner;
