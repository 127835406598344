import React, { useState } from "react";
import CommonComp from "../CommonComp";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import LanguageIcon from "@mui/icons-material/Language";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

function Contactforminfo() {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;

      axios
        .post(
          " https://git.digiwbs.com/Admin/public/api/apirequestinfoSend",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          // console.log(result.data.success);
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          formik.resetForm();
        })
        .catch((err) => {
          // console.error("Error fetching courses:", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });

  function onChange(value) {
    setRecaptchaToken(value);
  }

  return (
    <>
      <section className="contact-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-clip">
                  <h3>Request Information!</h3>
                </div>
                <div className="formdiv">
                  <div className="under-div">
                    <CommonComp
                      type={"text"}
                      placeholder={"First Name*"}
                      name={"first_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <p className="err">{formik.errors.first_name}</p>
                    )}
                    <CommonComp
                      type={"text"}
                      placeholder={"last Name*"}
                      name={"last_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <p className="err">{formik.errors.last_name}</p>
                    )}
                    <CommonComp
                      type={"email"}
                      placeholder={"Email Address*"}
                      name={"email"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="err">{formik.errors.email}</p>
                    )}
                    <CommonComp
                      type={"number"}
                      placeholder={"Phone Number*"}
                      name={"phone"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <p className="err">{formik.errors.phone}</p>
                    )}
                    <CommonComp
                      type={"text"}
                      placeholder={"Address*"}
                      name={"address"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <p className="err">{formik.errors.address}</p>
                    )}
                    <textarea
                      rows="4"
                      cols="6"
                      name="message"
                      form="usrform"
                      placeholder="Message*"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    ></textarea>
                    {formik.touched.message && formik.errors.message && (
                      <p className="err">{formik.errors.message}</p>
                    )}
                    <ReCAPTCHA
                      className="g-recaptcha mt-3"
                      sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                      onChange={onChange}
                    />
                    <button className="btn button1 w-100 mt-5" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 col-lg-6">
              <div className="contact-detail p-5">
                <h2>Contact Information</h2>
                <p>Global Information Technology</p>
                <div className="d-flex align-items-center contact-call mt-5">
                  <AddIcCallIcon
                    style={{
                      color: "#fff",
                      fontSize: "30px",
                    }}
                    className="me-3"
                  />
                  <div>
                    <div className="number">
                      <span style={{ fontWeight: "700" }}>Phone: </span>
                      <a href="tel:2485572480">(248) 557-2480</a>
                    </div>
                    <div className="number">
                      <span style={{ fontWeight: "700" }}>Toll Free: </span>
                      <a href="tel:8664644846">(866)-GO-GIT-GO</a>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center contact-call mt-4">
                  <WhereToVoteIcon
                    style={{
                      color: "#fff",
                      fontSize: "30px",
                    }}
                    className="me-3"
                  />
                  <p>
                    <span style={{ fontWeight: "700" }}>Address: </span>26700
                    Lahser Rd, Suite # 350,
                    <br />
                    Southfield, MI 48076
                  </p>
                </div>
                <div className="d-flex align-items-center contact-call mt-4">
                  <MarkEmailUnreadIcon
                    style={{
                      color: "#fff",
                      fontSize: "30px",
                    }}
                    className="me-3"
                  />
                  <div className="mail">
                    <span style={{ fontWeight: "700" }}>Gmail: </span>
                    <a href="mailto:globalit1234@gmail.com">
                      globalit1234@gmail.com
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center contact-call mt-4">
                  <LanguageIcon
                    style={{
                      color: "#fff",
                      fontSize: "30px",
                    }}
                    className="me-3"
                  />
                  <div className="mail">
                    <span style={{ fontWeight: "700" }}>Website:</span>{" "}
                    <Link to="https://www.global-itech.com/">
                      www.global-itech.com
                    </Link>
                  </div>
                </div>
                <div className="social-icon mt-5">
                  <div className="d-flex align-items-center mt-4">
                    <i
                      className="fa-brands fa-facebook"
                      style={{
                        color: "#fff",
                        fontSize: "25px",
                      }}
                    />
                    <Link to="https://www.facebook.com/gogitgo">Facebook</Link>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <i
                      className="fa-brands fa-youtube"
                      style={{
                        color: "#e41010",
                        fontSize: "25px",
                      }}
                    />
                    <Link to="https://www.youtube.com/user/GoGITGo/videos">
                      YouTube
                    </Link>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <i
                      className="fa-brands fa-x-twitter"
                      style={{
                        color: "black",
                        fontSize: "25px",
                      }}
                    />
                    <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fgogitgo">
                      Twitter
                    </Link>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <i
                      className="fa-brands fa-linkedin"
                      style={{
                        color: "#0049bc",
                        fontSize: "25px",
                      }}
                    />
                    <Link to="https://www.linkedin.com/company/global-itech/">
                      LinkedIn
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.3599144693953!2d-83.26245552349225!3d42.48390042726791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c871a7cdd281%3A0xff291bda279d0c2a!2sGlobal%20Information%20Technology!5e0!3m2!1sen!2sin!4v1716871611807!5m2!1sen!2sin"
          width="100%"
          height="500"
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="mt-5"
          title="map"
        ></iframe>
      </section>
    </>
  );
}

export default Contactforminfo;
