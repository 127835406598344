import React, { Suspense } from "react";
import Booktour from "./Book-Tour/Booktour";
import Booktourbanner from "./Book-Tour/Booktourbanner";

function BooktourRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Booktourbanner />
        <Booktour />
      </Suspense>
    </>
  );
}

export default BooktourRoute;
