import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import seminar from "../image/seminar.jpg";
import Registrationform from "./Registrationform";

function Workshopcontent() {
  const columns = ["Name", "Date", "Time", "By Category", "By Organizer"];
  const [tabledata, setTabledata] = useState([]);
  const [showregisModal, setShowregisModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiEvents")
      .then((result) => {
        console.log("event evnt event event event event", result);
        const data = result.data.data.data.map((event) => ({
          Name: event.title,
          Date: new Date(event.start_date).toLocaleDateString("en-GB"),
          Time: formatEventTime(event.start_date, event.end_date),
          Category: event.category,
          Organizer: event.organizers,
          // Add more properties if needed
        }));
        setTabledata(data);
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  }, []);

  //Change format start_date and end_date week and time
  const formatEventTime = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    const dayOfWeek = startDate.toLocaleDateString("en-US", {
      weekday: "long",
    });
    const startTime = startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    const endTime = endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    return `${dayOfWeek}, ${startTime} to ${endTime}`;
  };

  const options = {
    filterType: "checkbox",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    search: true,
    print: false,
    download: false,
    viewColumns: true,
    pagination: true,
    serverSide: false,
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      setSelectedEvent(rowData); // Set the selected event when a row is clicked
      openregisModal(); // Open the registration modal
    },
  };

  return (
    <>
      <section className="workshopcontent ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Learn for Free Each Day</h2>
              <p>
                Global Information Technology is giving back to the community by
                offering free classes (live-streamed instructor-led workshops)
                during the COVID-19 crisis we are all facing. Learn from
                Introduction to coding, Java, web design, LinkedIn Basic,
                Project Management, Business Analyst, Agile to Microsoft Office.
                Global Information Technology conducts free workshops for the
                benefit of individuals to help them learn new technology and the
                latest skills. The workshops are free, and there is absolutely
                no obligation to register for any course.
              </p>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  Each free workshop will be live-streamed through our virtual
                  classroom environment (free for the attendees).
                </p>
              </div>
              <div className="d-flex">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  Before the workshop, we will send you a link for the meeting.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img src={seminar} alt="seminar" className="seminar" />
            </div>
          </div>
          <div className="text-center seminar-table">
            <h2>Spaces are limited. Register Today!!!</h2>
            <p>
              <strong>Register: </strong> Learn more and register for the
              topic(s) of your choice below <strong>3 days in advance</strong>.
            </p>
            <div className="muitable mt-5 mb-3">
              <MUIDataTable
                title={"SEMINARS AND EVENTS"}
                data={tabledata}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>
      </section>
      <Registrationform
        show={showregisModal}
        handleClose={closeregisModal}
        eventName={selectedEvent ? selectedEvent[0] : ""}
      />
    </>
  );
}

export default Workshopcontent;
