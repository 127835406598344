import React,{Suspense} from "react";
import Financialbanner from "./FinancialAid/Financialbanner";
import FinancialContent from "./FinancialAid/FinancialContent";

function FinancialRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Financialbanner />
        <FinancialContent />
      </Suspense>
    </>
  );
}

export default FinancialRoute;
