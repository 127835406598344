import React from "react";
import { Accordion } from "react-bootstrap";

function Faqcontent() {
  return (
    <>
      <section className="faqcontent ">
        <div className="container">
          <h2>
            Have questions? Our FAQ will help you with initial and frequently
            asked questions <br />
            or you can always call us at:
            <a
              href="tel:8664644846"
              style={{ color: "#f5aa30", textDecoration: "none" }}
            >
              {" "}
              866-464-4846
            </a>
          </h2>
          <div className="row">
            <Accordion className="mt-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  01. &nbsp; How does GIT set itself apart from the competition?
                </Accordion.Header>
                <Accordion.Body>
                  Customer satisfaction is at the top of our list of priorities,
                  so we go to great lengths to provide every possible resource
                  we can for our students to succeed in their goals. We
                  understand that our success is 100% contingent upon the
                  success of our students, so we are willing to go to great
                  lengths to help you succeed with quality training at an
                  affordable price.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className=" mt-3">
                <Accordion.Header>
                  02. &nbsp; What does ILT stand for?
                </Accordion.Header>
                <Accordion.Body>
                  Instructor-Led-Training. Global I.T. appreciates other methods
                  of training, such as online and CBT; however, there is nothing
                  like getting hands-on ILT experience. We are people who have
                  to have our questions answered as they arise, so we are better
                  prepared to move on further into the subject we are studying.
                  If our questions cannot be addressed in a manner that
                  satisfies our thirst for knowledge, then we are likely to lose
                  interest or be subject to unnecessary frustration. Global I.T.
                  instructors are all certified as well as industry-experienced
                  in the technology they teach and are prepared to address the
                  questions of all of our students. Our students are encouraged
                  to ask questions to inspire group discussions and enhance the
                  educational process.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className=" mt-3">
                <Accordion.Header>
                  03. &nbsp; Is there a flexible training schedule available?
                </Accordion.Header>
                <Accordion.Body>
                  Yes! We offer daytime, weeknight, and weekend classes. We can
                  also tailor a special course schedule to your needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className=" mt-3">
                <Accordion.Header>
                  04. &nbsp; Do I get college credits for attending this course?
                </Accordion.Header>
                <Accordion.Body>
                  No, currently Global Information Technology is looking into
                  options that will allow our students to get college credits
                  for the classes they attend. However, we are not able to offer
                  college credits at this point in time.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4" className=" mt-3">
                <Accordion.Header>
                  05. &nbsp;Is it important to visit a school before enrolling?
                </Accordion.Header>
                <Accordion.Body>
                  Your future and success depend on it! This is an important
                  step. We encourage you to weigh your decision carefully and
                  invest the time to make a well-informed choice. A visit
                  enables you to discuss a school’s training with a school
                  representative and see the classrooms and equipment you will
                  use in your training.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className=" mt-3">
                <Accordion.Header>
                  06. &nbsp; What does it mean to get certified?
                </Accordion.Header>
                <Accordion.Body>
                  More and more employers are insisting that their candidates
                  are more than capable of the technology that they are working
                  with. One of the ways of determining whether or not a person
                  is qualified in a particular area by having vendors come up
                  with an internationally recognized certification process.
                  According to a survey done by the International Data
                  Corporation, Certification is a “Standard benchmark for
                  assessing skills, lowering employer training costs, and
                  reports that 78% of companies experienced more productivity
                  from their certified employees and 92% realized financial
                  benefits by having certificate holders on staff.” Therefore,
                  certification is a ticket to a better job and more financial
                  security.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className=" mt-3">
                <Accordion.Header>
                  07. &nbsp; How much time do I need to devote to training?
                </Accordion.Header>
                <Accordion.Body>
                  Global Information Technology expects that once a student
                  begins a training course, they will commit to a minimum of six
                  hours per week. Most of our students study anywhere between
                  six and 12 hours per week. This will depend on a student’s
                  individual circumstances and how quickly they wish to complete
                  the course.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className=" mt-3">
                <Accordion.Header>
                  08. &nbsp; What's The Future Of Computer Certification?
                </Accordion.Header>
                <Accordion.Body>
                  Certification is quickly becoming the standard for all IT
                  professionals. The value of computer professional
                  certifications appears to be on the rise. Judging by the rapid
                  emergence of new programs, big businesses, and professional
                  organizations appear eager and able to fuel the market.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8" className=" mt-3">
                <Accordion.Header>
                  09. &nbsp; What is the job outlook in the industry?
                </Accordion.Header>
                <Accordion.Body>
                  The US Department of Labor states that 8 out of 10 of the
                  fastest-growing occupations are computer careers. It is a fact
                  that companies across America are desperate for well-trained
                  IT professionals. There is opportunity at all levels in the
                  industry.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9" className=" mt-3">
                <Accordion.Header>
                  10. &nbsp; Why should I enter the IT field?
                </Accordion.Header>
                <Accordion.Body>
                  Information Technology is essential to the day-to-day
                  operations and long-term growth of all businesses. Because of
                  this, there is rising demand for a range of IT
                  professionals—including Network Engineers, IT Help Desk,
                  Computer Systems Analysts, Computer Support Specialists,
                  Database Administrators, Software Engineers, Business
                  Analysts, Project Managers, Web Designers, and Software
                  Testers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10" className=" mt-3">
                <Accordion.Header>
                  11. &nbsp; Where can I find funding help for my IT training
                  program?
                </Accordion.Header>
                <Accordion.Body>
                  Global Information Technology students have some funding
                  options, including government funding, Veterans Administration
                  programs, and private bank loans.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11" className=" mt-3">
                <Accordion.Header>
                  12. &nbsp; What are the time and resource commitments for an
                  IT education?
                </Accordion.Header>
                <Accordion.Body>
                  Global Information Technology provides highly focused and
                  relevant in-demand IT training and certification preparation
                  for a variety of IT career paths—typically with a shorter time
                  and smaller financial commitment than a two- or four-year
                  degree.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  13. &nbsp; Why should I choose your school?
                </Accordion.Header>
                <Accordion.Body>
                  Choosing a training provider can be a tough choice. We all
                  claim to offer the best value in terms of price, passing
                  rates, and guarantee. GIT provides the lowest price, one of
                  the best training materials, and talented instructors. This is
                  an excellent opportunity for you to learn valuable
                  industry-ready skills and succeed in exams. Our long history
                  in this area of technical training and our past student’s
                  success guarantee our claims. As a Better Business
                  Bureau-accredited business, we can honestly say what we offer
                  is the truth, and you can verify it.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13" className=" mt-3">
                <Accordion.Header>
                  14. &nbsp; Will Certification help me earn more?
                </Accordion.Header>
                <Accordion.Body>
                  Re-energizing your resume and continuing the learning cycle
                  can help you turn those certifications into money. According
                  to Alison Indrisano, “54% of certified IT professionals who
                  participated in a study received a reward at work, which is a
                  20% increase from the previous year.” This includes salary
                  increases at 18%, new responsibilities at 20%, and promotions
                  at 9%.”Certification is an excellent framework and supporting
                  structure in the lives of IT professionals and provides the
                  road map for career direction and the future of your
                  professional journey. (June 2003, Alison Indrisano, “Cashing
                  In: Make Your Certification Pay Off).
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14" className=" mt-3">
                <Accordion.Header>
                  15. &nbsp; How long should it take to achieve my
                  certification?
                </Accordion.Header>
                <Accordion.Body>
                  The question “How long will it take me?” gets asked a lot. The
                  answer to this question is, “It is entirely up to you.”
                  <br /> Our goal is to help you prepare for the certification
                  exam. We will give you the information that you need to
                  survive the exam, as well as the tools necessary to apply what
                  you learn in a “real-world” environment. Once you finish a
                  course, it will be up to you when you go and take the
                  certification exam. You can wait a few months while you study
                  the material after each course, or you could wait until you
                  finish the classes and go take all of the exams in 6 months
                  (for example). Some students prefer to take their time, and
                  other students want to get it over with as quickly as
                  possible. The time it takes you to finish your certification
                  process depends on how much time you want to devote to
                  studying the exam objectives for each certification.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15" className=" mt-3">
                <Accordion.Header>
                  16. &nbsp; What Certifications Are There?
                </Accordion.Header>
                <Accordion.Body>
                  There are currently more than 700 technical certifications you
                  can earn. Contact a Global IT career advisor at
                  1-866-GO-GIT-GO to discuss the areas you might be interested
                  in and the relevant certifications. Global IT offers free
                  career planning.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16" className=" mt-3">
                <Accordion.Header>
                  17. &nbsp; Which certifications should I obtain?
                </Accordion.Header>
                <Accordion.Body>
                  This depends on the type of job you would like to have and
                  your experience. Every area of IT has relevant certifications.
                  Please contact a Global IT career advisor at 1-866-GO-GIT-GO
                  to go over different career paths and decide on an area that
                  best suits you.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17" className=" mt-3">
                <Accordion.Header>
                  18. &nbsp; What are my chances of actually getting a job in
                  the computer industry?
                </Accordion.Header>
                <Accordion.Body>
                  Excellent, and getting better every year. The US Department of
                  Labor states that 8 out of 10 of the fastest-growing
                  occupations are computer-related careers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18" className=" mt-3">
                <Accordion.Header>
                  19. &nbsp; Do I Need A College Degree?
                </Accordion.Header>
                <Accordion.Body>
                  Absolutely not. In fact, there are generally no educational
                  prerequisites. Even a lack of a high school diploma won’t bar
                  your entrance to a computer certification program. That’s
                  always been one of the great things about computer
                  work—there’s room for everyone capable, and often even for
                  those with marginal skills but a burning passion for the
                  field. Good educational credentials, however, will often
                  smooth the way into the upper ranks of computer professionals
                  and are listed as a requirement in many job postings. Larger
                  companies, especially, seem to automatically expect candidates
                  to hold a bachelor of computer science degree. Startups are
                  often more flexible. Computer certifications can sometimes be
                  used in place of a degree.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19" className=" mt-3">
                <Accordion.Header>
                  20. &nbsp; What quality guarantee do you offer on your
                  training?
                </Accordion.Header>
                <Accordion.Body>
                  Most of our students clear the certifications on the first
                  attempt. If required, we suggest a free retake of the module
                  after analyzing the needs of the student.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="20" className=" mt-3">
                <Accordion.Header>
                  21. &nbsp; Does GIT offer job placement assistance?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, Global I.T. will help you prepare a professional resume
                  and schedule mock interviews with managers and technical
                  experts within the industry. Please note that Global I.T. does
                  not guarantee job placement and will look to help those
                  candidates who get certified first.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="21" className=" mt-3">
                <Accordion.Header>
                  22. &nbsp; What is your enrollment process?
                </Accordion.Header>
                <Accordion.Body>
                  The enrollment process at Global Information Technology is
                  very easy. First, meet with a representative at the first
                  meeting. We will discuss your career goals and aspirations,
                  and you will learn more about our programs and take a tour of
                  the facility. Next, finalize the financing and registration
                  processes. We will assist you in determining your most
                  suitable financing options and make sure that all your
                  registration information is current and complete. To start
                  your application, please fill out the enrollment form, and one
                  of our career counselors will contact you shortly thereafter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="22" className=" mt-3">
                <Accordion.Header>
                  23. &nbsp; How long are the training classes?
                </Accordion.Header>
                <Accordion.Body>
                  The courses vary in length depending on the career path you
                  elect to take and the schedule you choose to pursue. In
                  general, a student can expect to go through 1 to 9 months of
                  training to attempt all the respective certification exams and
                  get certified.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="23" className=" mt-3">
                <Accordion.Header>
                  24. &nbsp; Why are course prices not listed on your website?
                </Accordion.Header>
                <Accordion.Body>
                  We understand that price is a very important factor in making
                  your training decision. Rest assured that we work with all of
                  our students to keep our costs down as much as possible and
                  help our students identify financing options. Also, students
                  should take the time to investigate exactly what is included
                  in the price to make a valid comparison. Our prices may very
                  well be the lowest, but you will get the highest return on
                  your investment with Global Information Technology. However,
                  we feel that our prices are very competitive, and finding a
                  better value will be next to impossible. Visit us and meet one
                  of our career advisors, and you will definitely be satisfied
                  with what we can help you accomplish within your budget.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqcontent;
