import React, { Suspense } from "react";
import Jobplacementbanner from "./JobPlacement/Jobplacementbanner";
import Jobplacementcontent from "./JobPlacement/Jobplacementcontent";

function JobplacementRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Jobplacementbanner />
        <Jobplacementcontent />
      </Suspense>
    </>
  );
}

export default JobplacementRoute;
