import React from "react";

function Firstbanner() {
  return (
    <>
      <section className="career-first">
        <div className="container h-100">
          <div className="career-content">
            <h1>
              Unleash Your Potential in IT <br /> With Global IT’S
            </h1>
            <p>Accelerated Career Track Programs</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Firstbanner;
