import React from "react";

function Plancareercontent() {
  return (
    <>
      <section className="plancontent">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Plan your Career</h2>
              <p>
                Professional development is one of the most important aspects of
                your business. Making this happen can be challenging. Global
                information technology helps make this task simple. We provide
                consulting and training services to professionals around the
                globe and have been doing so since 1999. We have developed the
                know-how and the programs to give your team or yourself the
                right training.  
              </p>
              <p>
                We will make the where and when you’ll train an easy transition.
                We offer some great flexible choices for schedules and
                convenient options to pay. This will help keep you and your team
                armed with the skills that are essential to helping you or your
                business grow.
              </p>
              <h2>Meet with us</h2>
              <p>
                Meet with us to discuss where you want to go and how we can help
                you get there. Please call{" "}
                <a
                  href="tel:8664644846"
                  style={{ color: "#f5aa30", textDecoration: "none" }}
                >
                  1-866-GO-GIT-GO ( 464-4846)
                </a>{" "}
                to set up a meeting or simply fill up a request form.
              </p>
              <h2>Convenient Training Location</h2>
              <p>
                We are centrally located in the heart of Lathrup Village. Close
                to major freeways, the city of Detroit, and the suburbs.
              </p>
              <h2>Align with the job</h2>
              <p>
                Our programs are built from the ground up to align with the
                career goals.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <h2>Access your skills</h2>
              <p>
                We will position you for success by matching your passion with
                your skill set. We have free IT assessment available.
              </p>
              <h2>Choose your Program</h2>
              <p>
                We offer a variety of programs to help you find the best choice
                for your career Let us help ease the process of making the
                career you want.
              </p>
              <h2>Funding Sources</h2>
              <p>
                We help you find the right funding source to kick-start your
                career!
              </p>
              <h2>Plan your schedule</h2>
              <p>
                We have flexible daytime, afternoon, weeknight, and weekend
                schedules available. Find the schedule that fits you.
              </p>
              <h2>Delivery methods</h2>
              <p>
                Choose the best delivery method suitable to your needs. We have
                an instructor-led classroom or remote-live or on-demand
                convenient delivery formats available for you to get the
                training you need where, when, and how you want it.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Plancareercontent;
