import { Rating } from "@mui/material";
import React from "react";

function Overviewbanner() {
  return (
    <>
      <section className="overviewbanner">
        <div className="container h-100">
          <div className="overview-content text-center">
            <h1>About Global IT</h1>
            <p>
              Global Information Technology offers I.T. Training, <br />
              Staffing and Consulting Services since 1999.
            </p>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Overviewbanner;
