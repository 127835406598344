import React from "react";
import about from "../image/aboutimage.png";
import schooler from "../image/schooler.svg";
import expert from "../image/approvedexpert.svg";
import envirnment from "../image/frindlyenvir.svg";
import boost from "../image/boostknow.svg";
import call from "../image/callsvg.png";
import { Link } from "react-router-dom/dist";

function About() {
  return (
    <>
      <section className="about ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 left-about">
              <img src={about} loading="lazy" alt="bg-about" />
            </div>
            <div className="col-12 col-lg-6 righttext my-5">
              <h5>About Us</h5>
              <h2>Discover Our Story</h2>
              <p>
                Through dedicated and motivated IT professionals, Global I.T.
                continually energizes result-oriented perspectives on technology
                and processes, each of which is a critical success factor.
                necessary to thrive in this new economy.{" "}
              </p>{" "}
              <p>
                We never take customer loyalty for granted. Our goal is always
                to exceed their perceived expectations. We strive to
                continuously add value by listening to our customers and staying
                ahead of business and technological shifts, thus enabling us to
                respond with speed and accuracy to the needs of our customers.{" "}
              </p>
              <div className="aboutservice d-flex w-100">
                <div className="d-flex align-items-center w-50 abouthover1">
                  <img src={schooler} loading="lazy" alt="training" />
                  <p>Flexible training programs</p>
                </div>
                <div className="d-flex align-items-center w-50 abouthover2">
                  <img src={expert} loading="lazy" alt="expert" />
                  <p>Learn from approved experts</p>
                </div>
              </div>
              <div className="aboutservice d-flex w-100 mt-4">
                <div className="d-flex align-items-center w-50 abouthover3">
                  <img src={envirnment} loading="lazy" alt="environment" />
                  <p>Friendly environment for you</p>
                </div>
                <div className="d-flex align-items-center w-50 abouthover4">
                  <img src={boost} loading="lazy" alt="knowledge" />
                  <p>Boost your knowledge</p>
                </div>
              </div>
              <div className="d-flex mt-5 know-btn">
                <Link to="/about_us">
                  <button className="submit btn">Know More</button>
                </Link>
                <div className="d-flex ms-5 callsvg align-items-center abouthover5">
                  <img src={call} loading="lazy" alt="call" />
                  <a href="tel:8664644846" className="ps-3 w-100">
                    866-464-4846
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
