import React, { useState } from "react";
import TrackFormmodal from "./TrackFormmodal";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/career/";

function Detailcontent({ title, image, des }) {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="detailcontent">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img src={`${imagePath}${image}`} alt={title} />
            </div>
            <div className="col-12 col-lg-6">
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: des }} />
              <button className="btn" onClick={openregisModal}>
                Get Program Details Now!
              </button>
            </div>
          </div>
        </div>
      </section>
      <TrackFormmodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Detailcontent;
