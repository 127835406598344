import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import ContactForm from "../ContactForm";
import Accordion from "react-bootstrap/Accordion";

function Vetransbenifit() {
  return (
    <>
      <section className="vetrans-benifit">
        <div className="container">
          <div className="benifit-head">
            <h2>
              VA Education Benefits Accepted at
              <span style={{ color: "#f5aa30" }}> Global IT</span>
            </h2>
          </div>
          <div className="row pt-5">
            <div className="col-12 col-lg-6  pt-5">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    CHAPTER 33 – Post 9/11 GI Bill®
                  </Accordion.Header>
                  <Accordion.Body>
                    At least 90 days of aggregate service on or after September
                    11, 2001, or be discharged with a service-connected
                    disability after 30 days. You must have received an
                    honorable discharge to be eligible for the Post-9/11 GI
                    Bill®. The Post-9/11 GI Bill® became effective for training
                    on or after August 1, 2009. A post-9/11 pamphlet is
                    available. The Post-9/11 GI Bill is an education benefit
                    program for individuals who served on active duty on or
                    after September 11, 2001 (va.gov).
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    CHAPTER 30 – Montgomery GI Bill® for Active Duty (MGIB-AD)
                  </Accordion.Header>
                  <Accordion.Body>
                    At least two years on active duty contributed $1,200 to your
                    GI Bill® fund and you were honorably discharged.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    CHAPTER 1606 – Montgomery GI Bill® Selected Reserve
                    (MGIB-SR)
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>Reservists/National Guard–Chapter 1606</strong>
                    You must have a six-year obligation to serve in the Reserves
                    or Guard and be currently in good standing with your unit.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    CHAPTER 31 – Vocational Rehabilitation and Employment (VR&E)
                  </Accordion.Header>
                  <Accordion.Body>
                    If you have a service-connected disability that limits your
                    ability to work or prevents you from working, Veteran
                    Readiness and Employment (formerly called Vocational
                    Rehabilitation and Employment) can help. This program—also
                    known as Chapter 31 or VR&E—helps you explore employment
                    options and address training needs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    CHAPTER 35 – Survivors’ and Dependents’ Educational
                    Assistance Program
                  </Accordion.Header>
                  <Accordion.Body>
                    Child (18–26 years) or spouse of a veteran determined to be
                    100% disabled or deceased due to military-related injuries.
                    If you are 26 years of age or under and are collecting
                    Chapter 35 benefits, you are eligible for the Children of
                    Veterans Tuition Grant for the State of Michigan.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    VET TEC – Veteran Employment Through Technology Education
                    Courses
                  </Accordion.Header>
                  <Accordion.Body>
                    In April 2019, the Department of Veterans Affairs announced
                    the pilot program VET TEC, which stands for Veteran
                    Employment Through Technology Education Courses. The VA
                    announcement opened the doors for applications to the
                    program for qualifying veterans who are eligible for VA
                    education benefits. The program is designed to be a
                    fast-track education experience designed to help veterans
                    get into high-tech careers.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <SchoolIcon sx={{ color: "#f5aa30" }} className="me-3" />
                    VRRAP – Veteran Rapid Retraining Assistance Program
                  </Accordion.Header>
                  <Accordion.Body>
                    The Veteran Rapid Retraining Assistance Program (VRRAP)
                    offers education and training for high-demand jobs to
                    Veterans who are unemployed because of the COVID-19
                    pandemic.
                    <strong> Federal/State Tuition Assistance</strong>
                    Students planning to use Federal or State Tuition Assistance
                    (TA) must first consult their respective branch/unit
                    Education Services Officer (ESO) or education counselor for
                    program approval before requesting TA through the respective
                    service portal. For more education benefit information,
                    please visit benefits.va.gov/gibill/
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-12 col-lg-6  pt-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Vetransbenifit;
