import React from "react";
import { Rating } from "@mui/material";
import gradute from "../../image/graduated_3135755 1.png";
import customer from "../../image/customer-review_1379505 1.png";
import like from "../../image/like_9708008 1.png";

function Ratingg() {
  return (
    <>
      <section className="ratting">
        <div className="container">
          <div className="row gy-5">
            <div className="col-12 col-lg-4">
              <div className="rating-card rating-card1 d-flex">
                <img src={gradute} loading="lazy" alt="geadute" />
                <div className="card-body">
                  <h3>Course Rating BY lEARNERS</h3>
                  <div className="d-flex align-items-center mt-3">
                    <p>4.5</p>
                    <Rating
                      name="read-only"
                      defaultValue={5}
                      readOnly
                      className="ps-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="rating-card rating-card2 d-flex">
                <img src={customer} loading="lazy" alt="customer" />
                <div className="card-body">
                  <h3>Overall Experience</h3>
                  <div className="d-flex align-items-center mt-3">
                    <p>4.9</p>
                    <Rating
                      name="read-only"
                      defaultValue={5}
                      readOnly
                      className="ps-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="rating-card rating-card3 d-flex">
                <img src={like} loading="lazy" alt="like" />
                <div className="card-body">
                  <h3>Course Quality</h3>
                  <div className="d-flex align-items-center mt-3">
                    <p>4.5</p>
                    <Rating
                      name="read-only"
                      defaultValue={5}
                      readOnly
                      className="ps-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ratingg;
