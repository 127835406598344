import React, { useEffect, useState } from "react";
import enroll from "../../image/enroll-now-registration-membership-concept 1.png";
import ContactForm from "../../ContactForm";
import Registermodal from "../../home/Registermodal"

function Menucontent({
  about,
  benifit,
  courseContent,
  objective,
  outline,
  whatYouWillLearn,
  whoShouldAttend,
}) {
  const [activeTab, setActiveTab] = useState("about");
  const [oembedUrl, setOembedUrl] = useState("");
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function getVideoId(url) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? match[1] : "";
  }

  useEffect(() => {
    const apiData = about;

    // Check if API data contains a video
    if (apiData && apiData.includes("<oembed")) {
      // Extracting the URL using a regular expression
      const pattern = /<oembed url=\"(.*?)\">/;
      const matches = apiData.match(pattern);
      if (matches && matches.length > 1) {
        const oembedUrl = matches[1];
        // Setting the oembed URL
        setOembedUrl(oembedUrl);
      }
    } else {
      setOembedUrl("");
    }
  }, [about]);

  return (
    <>
      <section className="maincontent">
        <div className="">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center javamenu">
              <ul className="nav nav-tabs">
                {!about == null || !about == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "about" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("about")}
                    >
                      About
                    </a>
                  </li>
                ) : (
                  ``
                )}

                {!courseContent == null || !courseContent == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "courseContent" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("courseContent")}
                    >
                      Curriculum
                    </a>
                  </li>
                ) : (
                  ``
                )}

                {!benifit == null || !benifit == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "benifit" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("benifit")}
                    >
                      Benefits
                    </a>
                  </li>
                ) : (
                  ``
                )}

                {!objective == null || !objective == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "objective" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("objective")}
                    >
                      Objective
                    </a>
                  </li>
                ) : (
                  ``
                )}
                {!outline == null || !outline == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "outline" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("outline")}
                    >
                      Outline
                    </a>
                  </li>
                ) : (
                  ``
                )}
                {!whatYouWillLearn == null || !whatYouWillLearn == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "whatYouWillLearn" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("whatYouWillLearn")}
                    >
                      What You Will Learn
                    </a>
                  </li>
                ) : (
                  ``
                )}
                {!whoShouldAttend == null || !whoShouldAttend == "" ? (
                  <li
                    className={`nav-item ${
                      activeTab === "whoShouldAttend" ? "activee" : ""
                    }`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleTabClick("whoShouldAttend")}
                    >
                      Who Should Attend
                    </a>
                  </li>
                ) : (
                  ``
                )}
                <li
                  className={`nav-item ${activeTab === "faq" ? "activee" : ""}`}
                >
                  <a className="nav-link" onClick={() => handleTabClick("faq")}>
                    FAQs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#javadminssion">
                    Admissions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pt-5 menucontent">
            <div className="col-12 col-lg-8 ">
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    activeTab === "about" ? "show active" : ""
                  }`}
                  id="about"
                >
                  <h3>About</h3>

                  <div dangerouslySetInnerHTML={{ __html: about }} />
                  {oembedUrl && (
                    <iframe
                      title="youtube-video"
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${getVideoId(
                        oembedUrl
                      )}`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
                <div
                  id="courseContent"
                  className={`tab-pane fade ${
                    activeTab === "courseContent" ? "show active" : ""
                  }`}
                >
                  <h3>Course Content</h3>
                  <div dangerouslySetInnerHTML={{ __html: courseContent }} />
                </div>
                <div
                  id="objective"
                  className={`tab-pane fade ${
                    activeTab === "objective" ? "show active" : ""
                  }`}
                >
                  <h3>Objective</h3>
                  <div dangerouslySetInnerHTML={{ __html: objective }} />
                </div>
                <div
                  id="outline"
                  className={`tab-pane fade ${
                    activeTab === "outline" ? "show active" : ""
                  }`}
                >
                  <h3>Outline</h3>
                  <div dangerouslySetInnerHTML={{ __html: outline }} />
                </div>
                <div
                  id="whatYouWillLearn"
                  className={`tab-pane fade ${
                    activeTab === "whatYouWillLearn" ? "show active" : ""
                  }`}
                >
                  <h3>What You Will Learn</h3>
                  <div dangerouslySetInnerHTML={{ __html: whatYouWillLearn }} />
                </div>
                <div
                  id="whoShouldAttend"
                  className={`tab-pane fade ${
                    activeTab === "whoShouldAttend" ? "show active" : ""
                  }`}
                >
                  <h3>Who Should Attend</h3>
                  <div dangerouslySetInnerHTML={{ __html: whoShouldAttend }} />
                </div>

                <div
                  id="benifit"
                  className={`tab-pane fade ${
                    activeTab === "benifit" ? "show active" : ""
                  }`}
                >
                  <h3> Benefit</h3>
                  <p className="mt-3 mb-5">
                    <div dangerouslySetInnerHTML={{ __html: benifit }} />
                  </p>
                  <img src={enroll} alt="enroll" />
                  <button className="btn button1 mt-5" onClick={openregisModal}>
                    Enroll Now
                  </button>
                </div>

                <div
                  id="faq"
                  className={`tab-pane fade faq ${
                    activeTab === "faq" ? "show active" : ""
                  }`}
                >
                  <h2 className="faqhead">Frequently Asked Questions</h2>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What is Global IT Solutions course about?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Global IT Solutions is a comprehensive program
                          designed to equip learners with essential skills and
                          knowledge in various IT domains, including
                          programming, networking, cybersecurity, and more.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Who is the target audience for this course?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Global IT Solutions is suitable for beginners as well
                          as professionals seeking to upgrade their IT skills
                          and advance their careers in the tech industry.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          How long does the Global IT Solutions course last?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          The duration of the course varies depending on the
                          specific track you choose, ranging from a few weeks to
                          several months.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Is the course self-paced or instructor-led?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Global IT Solutions offers both self-paced and
                          instructor-led options to cater to different learning
                          preferences and schedules. Head
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          Are there any prerequisites for enrolling in this
                          course?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          While some tracks have no prerequisites, certain
                          advanced modules may require basic programming or IT
                          knowledge. Details are provided on the course
                          description page.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          Can I access the course materials on any device?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, the course materials are accessible on computers,
                          laptops, tablets, and smartphones, allowing you to
                          learn on-the-go.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingSeaven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSeaven"
                          aria-expanded="false"
                          aria-controls="flush-collapseSeaven"
                        >
                          Is there a certification awarded upon completion?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseSeaven"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSeaven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          Yes, upon successfully completing Global IT Solutions
                          and passing the assessments, you will receive a
                          recognized certification to showcase your expertise.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="apllyform">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Menucontent;
