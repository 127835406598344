import React, { Suspense } from "react";
import Careercoursebanner from "./CareerTrackCourse/Careercoursebanner";
import CareerCourseContent from "./CareerTrackCourse/CareerCourseContent";

function CareerCourseRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Careercoursebanner />
        <CareerCourseContent />
      </Suspense>
    </>
  );
}

export default CareerCourseRoute;
