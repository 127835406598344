import React, { Suspense } from "react";
import Overviewbanner from "./About us/Overviewbanner";
import Overviewcontent from "./About us/Overviewcontent";
import Rentlabcontent from "./About us/Rentlabcontent";
import Corporatecontent from "./About us/Corporatecontent";
import Strategycontent from "./About us/Strategycontent";
import Partnercontent from "./About us/Partnercontent";
import Careercontent from "./About us/Careercontent";

function AboutusRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Overviewbanner />
        <Overviewcontent />
        <Corporatecontent />
        <Strategycontent />
        <Partnercontent />
        <Careercontent />
        <Rentlabcontent />
      </Suspense>
    </>
  );
}

export default AboutusRoute;
