import React from "react";
import classroom from "../../image/classroom.png";
import curriculum from "../../image/watch (1).png";
import trainer from "../../image/like styar.png";
import salary from "../../image/file batch.png";

function serviceabout({ courseNo, duration }) {
  return (
    <>
      <section className="btngroup">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 buttons">
              <div className="div1 d-flex mt-3 mb-3">
                <img src={classroom} loading="lazy" alt="course_no" />
                <p>
                  <strong>Course Number : </strong>
                  {courseNo}
                </p>
              </div>
              <div className="div2 d-flex mt-3 mb-3">
                <img src={curriculum} loading="lazy" alt="time" />
                <p>
                  <strong>Duration :</strong> {duration}
                </p>
              </div>
              <div className="div2 d-flex mt-3 mb-3">
                <img src={trainer} loading="lazy" alt="trainer" />
                <p>Experienced Trainers</p>
              </div>
              <div className="div2 d-flex mt-3 mb-3">
                <img src={salary} loading="lazy" alt="payment" />
                <p>Flexible Payment Plans</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default serviceabout;
