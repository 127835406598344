import { Rating } from "@mui/material";
import React from "react";

const Referralbanner = () => {
  return (
    <>
      <section className="refrrall-banner">
        <div className="container h-100">
          <div className="referral-content">
            <h1>Referral Program</h1>
            <p>Refer to us and make $100!</p>
            <Rating readOnly defaultValue={5} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Referralbanner;
