import React, { useEffect, useState } from "react";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  class: Yup.string().required("Required"),
});

function Requestform({ show, handleClose }) {
  const [cousre, setCourses] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      class: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;
      axios
        .post(
          " https://git.digiwbs.com/Admin/public/api/apirequestAccessSend",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          formik.resetForm();
        })
        .catch((err) => {
          console.error("Error fetching courses:", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiPopularCourses")
      .then((result) => {
        setCourses(result.data.PopularCourses.data);
      })
      .catch((err) => {
        console.log("Error fetching courses:", err);
      });
  }, [setCourses]);

  function onChange(value) {
    setRecaptchaToken(value);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-register">
        <div className="request-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="formdiv">
              <div className="under-div">
                <CommonComp
                  type={"text"}
                  placeholder={"FirstName*"}
                  name={"firstname"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <p className="err">{formik.errors.firstname}</p>
                )}
                <CommonComp
                  type={"text"}
                  placeholder={"LastName*"}
                  name={"lastname"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <p className="err">{formik.errors.lastname}</p>
                )}
                <CommonComp
                  type={"email"}
                  placeholder={"Email Address*"}
                  name={"email"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="err">{formik.errors.email}</p>
                )}
                <select
                  name="class"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="select course">Course Enrolled*</option>
                  {cousre &&
                    cousre.map((val, index) => (
                      <option value={val.title} key={index}>
                        {val.title}
                      </option>
                    ))}
                </select>
                {formik.touched.class && formik.errors.class && (
                  <p className="err">{formik.errors.class}</p>
                )}
                <ReCAPTCHA
                  className="g-recaptcha mt-3"
                  sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                  onChange={onChange}
                />
                <button className="btn" type="submit">
                  Request Access
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Requestform;
