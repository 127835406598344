import { Rating } from "@mui/material";
import React, { useState } from "react";
import Registermodal from "../home/Registermodal";

function Staffbanner() {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="staffbanner">
        <div className="container h-100">
          <div className="staff-content">
            <h1>Staffing Services</h1>
            <p>
              Global I.T. provides staffing solutions in a variety of
              Information <br />
              technology-related business arenas.
            </p>
            <Rating
              name="read-only"
              defaultValue={5}
              readOnly
              className="mb-4"
            />
            <br />
            <button className="start btn" onClick={openregisModal}>
              Start Consulting
            </button>
          </div>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Staffbanner;
