import React from "react";
import image1 from "../image/our_misssion.png";
import image2 from "../image/vision.png";
import image3 from "../image/our_people.png";
import image4 from "../image/values.png";
import image5 from "../image/life.png";

function Corporatecontent() {
  return (
    <>
      <section className="corporatecontent" id="corporate_value">
        <div className="container">
          <h2>Corporate Values</h2>
          <div className="row d-flex flex-wrap corporate-row ">
            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image1} alt="missionimg" loading="lazy" />
                <h3> Our Mission</h3>
              </div>

              <p>
                {" "}
                Our mission is to be the premier resource for technical
                training, staffing, and consulting services in southeastern
                Michigan. Global Information Technology will achieve this
                mission by merging advanced technologies with highly skilled
                employees, providing the most personable customer service in the
                industry.
              </p>
            </div>
            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image2} alt="vision" loading="lazy" />
                <h3>Our Vision</h3>
              </div>
              <p>
                To be the number one contact for information technology
                training, staffing, and consulting services in southeastern
                Michigan.
              </p>
            </div>
            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image3} alt="People" loading="lazy" />
                <h3> Our People</h3>
              </div>
              <p>
                The Global IT family is a team of qualified, skilled, highly
                trained, and highly motivated professionals. We believe in the
                adage that a company is best defined by its people-dedicated,
                enthusiastic, and innovative.
              </p>
            </div>
            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image4} alt="values" loading="lazy" />
                <h3>Our Values</h3>
              </div>
              <p>
                Our values are driven by customer focus, intelligence,
                resourcefulness, and our entrepreneurial spirit. We are an
                inspired team that values and respects the contributions of
                everyone. We place a great deal of emphasis on integrity and
                refuse to take action that will compromise it. We value and
                practice a never-ending improvement philosophy and are open to
                constructive criticism.
              </p>
            </div>

            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image5} alt="Philosophy" loading="lazy" />
                <h3> Our Philosophy</h3>
              </div>
              <p>
                Global IT is committed to addressing each and every customer
                opportunity with a partnering approach. Based on the customer’s
                requirements, we offer a solution that meets their goals and
                objectives. We ensure that our solution will provide growth and
                migration plans for the future. Global IT incorporates only the
                highest quality services into its solutions. This approach
                guarantees that all of our solutions meet the following
                criteria: overall quality, cost-effectiveness, reliability,
                availability, and maximum returns on investments.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Corporatecontent;
