import React, { useState } from "react";
import bannervid from "../Video/istockphoto-1461202973-640_adpp_is.mp4";
import global from "../image/global.png";
import { Link } from "react-router-dom";
import Allcoursesmodel from "./Allcoursesmodel";

function Banner() {
  const [allcourseModal, setallcoursesModal] = useState(false);

  const openallcourseModal = () => setallcoursesModal(true);
  const closeallcourseModal = () => setallcoursesModal(false);

  return (
    <>
      <section className="banner">
        <video autoPlay muted loop>
          <source src={bannervid}></source>
        </video>
        <div className="container h-100">
          <div className="banner-div ">
            <div className="global">
              <img
                src={global}
                alt="global-25th"
                className="global_img"
                loading="lazy"
              />
            </div>
            <div className="heading ">
              <h1>Search From 100+ IT Courses</h1>
              <p>
                <span style={{ color: "#E8383B" }}>$100M+</span> Salary
                delivered
                <br />
                Is IT Certification Training Right For You?{" "}
              </p>
            </div>
            <div className="d-flex  flex-wrap z-999 banner-button">
              <button className="btn viewcourse " onClick={openallcourseModal}>
                View Courses
              </button>
              <button className="btn quiz ms-5 ">
                <Link
                  to="https://global-it-cr.virtualadviser.com/?lead_src=w-header"
                  target="_blank"
                >
                  Take the Free Quiz!
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Allcoursesmodel
        show={allcourseModal}
        handleClose={closeallcourseModal}
      />
    </>
  );
}

export default Banner;
