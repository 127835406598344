import React from "react";
import { Link } from "react-router-dom";

function Tophead() {
  return (
    <>
      <section className="tophead text-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 text-end">
              <p>
                Global Information Technology is here for you!{" "}
                <Link to="/book_tour">Click here</Link> for Virtual or In-person
                Appointments.
              </p>
            </div>
            <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center top-call">
              <a href="tel:8664644846" className="call">
                866-GO-GIT-GO
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Tophead;
