import React,{Suspense} from 'react'
import Examregisbanner from './Exam Registration/Examregisbanner'
import Examregiscontent from './Exam Registration/Examregiscontent'

const ExamregistrationRoute = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Examregisbanner />
        <Examregiscontent />
      </Suspense>
    </>
  );
}

export default ExamregistrationRoute