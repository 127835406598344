import React from "react";
import Deliverycontact from "../Delivery_Method/Deliverycontact";

function FreeplanningContent() {
  return (
    <>
      <section className="free-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6  rightfree-content">
              <Deliverycontact />
            </div>
            <div className="col-12 col-lg-6">
              <h2>Free Career Planning</h2>
              <p>
                The goal of this one-on-one session is to learn about your goals
                for the future and to explore various career opportunities
                available to you as a graduate of our program.
              </p>
              <p>
                Come meet with us, and we’ll craft a career plan that fits your
                interests, schedule, budget, and financial goals. Rest assured,
                you can accomplish your educational goals without prior
                technical knowledge or experience. Global I.T. will help make
                sure that you are starting out at a level that is conducive to
                your skill level of expertise. GIT was built around the mission
                to provide quality education and consulting services in an
                environment where all students can achieve industry-recognized
                certifications and, more importantly, become “career qualified"
                in as little as 7 months. We offer day, evening, and weekend
                classes to fit your schedule.
              </p>
              <h2 className="session-call">
                If you would like to schedule your personal Career Planning
                Session call:{" "}
                <a
                  href="tel:8664644846"
                  style={{ color: "#F5AA30", textDecoration: "none" }}
                >
                  1-866-GO-GIT-GO
                </a>
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FreeplanningContent;
