import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Component/Home";
import "./Component/style/style.css";
import CourseRoute from "./Component/CourseRoute";
import Categorypage from "./Component/Categorypageroute";
import AdmissionRoute from "./Component/AdmissionRoute";
import VetransRoute from "./Component/VetransRoute";
import ContactRoute from "./Component/ContactRoute";
import SchholershipRoute from "./Component/SchoolershipRoute";
import ClassSchedulesRoute from "./Component/ClassSchedulesRoute";
import FreecareerRoute from "./Component/FreecareerRoute";
import WorkshopRoute from "./Component/WorkshopRoute";
import JobplacementRoute from "./Component/JobplacementRoute";
import FinancialRoute from "./Component/FinancialRoute";
import FaqRoute from "./Component/FaqRoute";
import DeleverymethodRoute from "./Component/DeleverymethodRoute";
import TrainingRoute from "./Component/TrainingRoute";
import StudentloginRoute from "./Component/StudentloginRoute";
import PlancareerRoute from "./Component/PlancareerRoute";
import WebdesignRoute from "./Component/WebdesignRoute";
import StaffRoute from "./Component/StaffRoute";
import NetworkRoute from "./Component/NetworkRoute";
import AboutusRoute from "./Component/AboutusRoute";
import TestimonialRoute from "./Component/TestimonialRoute";
import BooktourRoute from "./Component/BooktourRoute";
import RefrralProgramRoute from "./Component/RefrralProgramRoute";
import FreenightRoute from "./Component/FreenightRoute";
import ExamregistrationRoute from "./Component/ExamregistrationRoute";
import Layout from "./Component/layout";
import Careerroute from "./Component/Careerroute";
import CareerCourseRoute from "./Component/CareerCourseRoute";
import DetailpageRoute from "./Component/DetailpageRoute";
import SearchpageRoute from "./Component/SearchpageRoute";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Layout />
        </>
      ),
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "/Course/:title/:id",
          element: <CourseRoute />,
        },
        {
          path: "/category/:title/:id",
          element: <Categorypage />,
        },
        {
          path: "/admission",
          element: <AdmissionRoute />,
        },
        {
          path: "/vetrans",
          element: <VetransRoute />,
        },
        {
          path: "/contactUs",
          element: <ContactRoute />,
        },
        {
          path: "/Schoolership",
          element: <SchholershipRoute />,
        },
        {
          path: "/ClassSchedules",
          element: <ClassSchedulesRoute />,
        },
        {
          path: "/FreeCareerPlanning",
          element: <FreecareerRoute />,
        },
        {
          path: "/freeWorkShop",
          element: <WorkshopRoute />,
        },
        {
          path: "/JobPlacement",
          element: <JobplacementRoute />,
        },
        {
          path: "/financialAid",
          element: <FinancialRoute />,
        },
        {
          path: "/freeWorkShop",
          element: <WorkshopRoute />,
        },
        {
          path: "/faq",
          element: <FaqRoute />,
        },
        {
          path: "/delivery_method",
          element: <DeleverymethodRoute />,
        },
        {
          path: "/training_solution",
          element: <TrainingRoute />,
        },
        {
          path: "/student_service",
          element: <StudentloginRoute />,
        },
        {
          path: "/plan_your_career",
          element: <PlancareerRoute />,
        },
        {
          path: "/web_design",
          element: <WebdesignRoute />,
        },
        {
          path: "/staff_service",
          element: <StaffRoute />,
        },
        {
          path: "/network_service",
          element: <NetworkRoute />,
        },
        {
          path: "/about_us",
          element: <AboutusRoute />,
        },
        {
          path: "/testimonial",
          element: <TestimonialRoute />,
        },
        {
          path: "/book_tour",
          element: <BooktourRoute />,
        },
        {
          path: "/refrral_program",
          element: <RefrralProgramRoute />,
        },
        {
          path: "/career-nights",
          element: <FreenightRoute />,
        },
        {
          path: "/exam-registration",
          element: <ExamregistrationRoute />,
        },
        {
          path: "/career-track",
          element: <Careerroute />,
        },
        {
          path: "/career-track-course",
          element: <CareerCourseRoute />,
        },
        {
          path: "/career-track/:title/:id",
          element: <DetailpageRoute />,
        },
        {
          path: "/Search-query",
          element: <SearchpageRoute />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
