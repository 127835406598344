import React from "react";
import ContactForm from "../ContactForm";
import Modal from "react-bootstrap/Modal";

function Registermodal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} className="registermodal">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <div className="register-modal">
          <ContactForm />
        </div>
      </Modal>
    </>
  );
}

export default Registermodal;
