import React,{Suspense} from "react";
import Trainingbanner from "./Training-solution/Trainingbanner";
import Trainingcontent from "./Training-solution/Trainingcontent";
import Deliverycontact from "./Delivery_Method/Deliverycontact";

function TrainingRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Trainingbanner />
        <Trainingcontent />
        <Deliverycontact />
      </Suspense>
    </>
  );
}

export default TrainingRoute;
