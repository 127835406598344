import React, { useState } from "react";
import filecheck from "../../image/filecheck (1).png";
import filesearch from "../../image/filesearch.png";
import callmessage from "../../image/callmessage.png";
import Registermodal from "../../home/Registermodal";

function Admission() {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="jadmission" id="javadminssion">
        <div className="container">
          <div className="">
            <h2>Admissions</h2>
            <p className="admpara">
              Discover a seamless enrollment process designed to make your
              educational journey hassle-free. Whether you're a beginner or a
              seasoned professional looking to upskill, our team of dedicated
              advisors is here to guide you every step of the way. Join us today
              and embark on a transformative learning experience that will shape
              your future for the better. Enroll now and unlock your potential
              in just a few steps.
            </p>
          </div>
          <div className="row adm-card mt-5 gy-5">
            <div className="col-12 col-lg-4">
              <div className="adm1 text-center">
                <img src={filecheck} loading="lazy" alt="Application" />
                <h3>Submit the Application</h3>
                <p>Fill up the form and Start your journey and excel </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="adm2 text-center">
                <img src={filesearch} loading="lazy" alt="Application" />
                <h3>Application Review</h3>
                <p>
                  Submit your details for a comprehensive application review and
                  swift response.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="adm3 text-center">
                <img src={callmessage} loading="lazy" alt="Application" />
                <h3>Submit the Application</h3>
                <p>Stay tuned! We'll contact you after the review process.</p>
              </div>
            </div>
          </div>
          <button className="btn button1 mt-5" onClick={openregisModal}>
            Apply Now
          </button>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Admission;
