import React from "react";
import image1 from "../image/Deliverycontent1.png";
import image2 from "../image/Deliveryimage2.jpg";
import image3 from "../image/DFeliveryimage3.jpg";

function Deliverycontent() {
  return (
    <>
      <section className="deliverycontent ">
        <div className="container">
          <div className="row delivery_row">
            <div className="col-12 col-lg-6">
              <img
                src={image1}
                alt="classroomimg"
                className="mb-3 w-100"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-lg-6">
              <h2>Classroom Instructor-Led Training</h2>
              <p>
                <strong>Global Information Technology </strong>is a leader in a
                classroom-setting training session. Global Information
                Technology believes the classroom experience is the foundation
                of our performance training approach to making the learning
                experience enriching, dynamic, and valuable for students and
                corporations. We offer very diverse training courses, from
                technical and desktop applications to graphic and web design and
                business skills classes. Our instructors are certified,
                well-seasoned, experienced professionals. <br />
                Looking for a class? Search Global Information Technology’s
                course catalog today to find a class that matches your
                professional needs and skill level.
              </p>
            </div>
          </div>

          <div className="row delivery_row">
            <div className="col-12 col-lg-6 order">
              <h2>ON-SITE TRAINING</h2>
              <p>
                Businesses are realizing the value of bringing training on-site.
                Global Information Technology is a flexible, cost-effective an
                option that enables you to train as many or as few employees as
                you need, from a single team or department to everyone in your
                organization. You save on travel expenses, and courses are
                delivered when and where you want them at your office(s). This
                ensures productivity, which keeps projects moving.
              </p>
              <p>
                Ready to bring IT training to your workplace? Contact a global
                information technology account executive today!
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={image3}
                alt="ON-SITE"
                className="mb-3 w-100"
                loading="lazy"
              />
            </div>
          </div>

          <div className="row delivery_row">
            <div className="col-12 col-lg-6">
              <img
                src={image2}
                alt="Remote"
                className="mb-3 w-100"
                loading="lazy"
              />
            </div>
            <div className="col-12 col-lg-6 ">
              <h2>Remote live Instructor Led Training</h2>
              <p>
                With Remote Live Instructor Training (RLIT), students can attend
                these same live classes from anywhere using a high-speed
                Internet connection. We’ve built a top-notch learning
                environment to give remote students the most complete,
                efficient, and effective transfer of knowledge and skills
                possible. In fact, it’s the exact same experience that our
                in-class students have come to expect.
              </p>
              <p>
                Features and Benefits of Remote-Live Instructor Training
                Courses:
              </p>
              <ul>
                <li>
                  Hands-on, live instructor-led training from any
                  internet-accessible location
                </li>
                <li>
                  Real-time remote access to class software and virtual lab
                  environment
                </li>
                <li>
                  Eliminate travel expenses and save time spent in transit
                </li>
                <li>RLIT is compatible with PCs, Macs, and mobile devices</li>
              </ul>
              <strong>
                Which Courses Can Be Delivered Through Remote-Live Instructor
                Training?
              </strong>
              <p>
                Almost all global IT courses are RLIT. However, please get in
                touch with us at info@global-itech.com or call 248-557-2480 for
                more details.
              </p>
              <ol>
                <li>
                  What are the computer system requirements for RLIT?
                  <p>
                    Use a recent PC or Mac computer that is less than 4 years
                    old. Two-display monitors are highly recommended
                  </p>
                  <ul>
                    <li>
                      one for joining the virtual classroom and if applicable,
                      viewing digital books
                    </li>
                    <li>the other for doing your labs</li>
                  </ul>
                  <p>
                    It is certainly possible to train on a single monitor;
                    however, it is not quite as effective. Please get in touch
                    with us if you are not sure.
                  </p>
                  <p> Your internet connection</p>
                  <ul>
                    <li>Please use a wired, not wireless or wifi connection</li>
                    <li>
                      Please use a broadband internet connection and not dial-up
                      (modem)
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  What will I need to attend a remote class?
                  <p>
                    We will email you login information for both the remote
                    classroom and the remote lab environment. If you have a
                    camera, that would be helpful but not necessary.
                  </p>
                </li>
                <li>
                  How do we receive books/course materials?
                  <p>
                    If digital books are available, students will receive an
                    email in PDF format. If digital books are not available,
                    students will receive the hard copy book in the mail.
                  </p>
                </li>
                <li>
                  What if I can’t hear the audio?
                  <p>
                    Make sure you have followed the directions on the Remote
                    Test page. If you have already done so, turn off your
                    computer. Then plug in your USB headset (make sure there are
                    no other audio devices plugged in). Turn your computer back
                    on, and test your headset with a known audio source like a
                    YouTube video.
                  </p>
                </li>
                <li>
                  Where should I attend the training? Home? Office?
                  <p>
                    Any location that offers a secluded environment is best. You
                    will be a part of the class just as if you were in the live
                    classroom, and you will have the best experience if you can
                    avoid being interrupted.
                  </p>
                </li>
                <li>
                  What if I have a dial-up or slow internet connection?.
                  <p>
                    You will need to have access to a high-speed internet
                    connection to take part in remote classes. Minimum
                    cable-modem speed is recommended but not required.
                  </p>
                </li>
                <li>
                  What if I have a question during class?
                  <p>
                    Our classrooms are fully equipped with sound and a camera.
                    You can actually speak up and interact with the instructor
                    or the class, just as you would if you were attending the
                    class in person.
                  </p>
                </li>
                <li>
                  What if my labs aren't working or if I get stuck during a lab?
                  <p>
                    The instructor can connect remotely to your machine and
                    guide you through any issues, either during the class or
                    privately one-on-one.
                  </p>
                </li>
                <li>
                  What if I need to reschedule or cancel my class?
                  <p>
                    You can reschedule or cancel your class just as if you were
                    attending the class in person
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Deliverycontent;
