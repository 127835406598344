import { Rating } from "@mui/material";
import React from "react";

function Detailbanner({title}) {
  return (
    <>
      <section className="detail-banner">
        <div className="container h-100">
          <div className="detail-content">
            <h1>{title}</h1>
            <Rating
              name="read-only"
              defaultValue={5}
              readOnly
              className="ps-2"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Detailbanner;
