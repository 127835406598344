import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  w_title: Yup.string().required("Required"),
  number_attanding: Yup.string().required("Required"),
  student: Yup.string().required("Required"),
});

function Registrationform({ show, handleClose, eventName }) {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      w_title: "",
      email: "",
      number_attanding: "",
      student: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;

      axios
        .post(
          " https://git.digiwbs.com/Admin/public/api/apiworkshopSend",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          formik.resetForm();
        })
        .catch((err) => {
          console.error("Error fetching :", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });

  function onChange(value) {
    setRecaptchaToken(value);
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-register">
        <div className="registeration-modal">
          <form onSubmit={formik.handleSubmit}>
            <div className="formdiv">
              <div className="under-div">
                <CommonComp
                  type={"text"}
                  placeholder={"Name*"}
                  name={"name"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <p className="err">{formik.errors.name}</p>
                )}
                <CommonComp
                  type={"text"}
                  placeholder={eventName + "*"}
                  name={"w_title"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.w_title}
                />
                {formik.touched.w_title && formik.errors.w_title && (
                  <p className="err">{formik.errors.w_title}</p>
                )}
                <CommonComp
                  type={"email"}
                  placeholder={"Email*"}
                  name={"email"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="err">{formik.errors.email}</p>
                )}
                <select
                  name="number_attanding"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number_attanding}
                >
                  <option>Number Attending*</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
                {formik.touched.number_attanding &&
                  formik.errors.number_attanding && (
                    <p className="err">{formik.errors.number_attanding}</p>
                  )}
                <div className="mt-3 d-flex align-items-center radio">
                  <h4>
                    Student?<span style={{ color: "red" }}>*</span>
                  </h4>
                  <CommonComp
                    type={"radio"}
                    name={"student"}
                    id="yes"
                    value="yes"
                    checked={formik.values.student === "yes"}
                    onChange={formik.handleChange}
                  />

                  <label>Yes</label>
                  <CommonComp
                    type={"radio"}
                    name={"student"}
                    id="no"
                    value="no"
                    checked={formik.values.student === "no"}
                    onChange={formik.handleChange}
                  />
                  <label>No</label>
                </div>
                {formik.touched.student && formik.errors.student && (
                  <p className="err">{formik.errors.student}</p>
                )}
                <ReCAPTCHA
                  className="g-recaptcha mt-3"
                  sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                  onChange={onChange}
                />
                <button className="btn" type="submit">
                  Submit
                </button>
                <p>You must be a U.S resident to register for workshops.</p>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Registrationform;
