import React from "react";
import { Rating } from "@mui/material";

function Deliverybanner() {
  return (
    <>
      <section className="delivery">
        <div className="container h-100">
          <div className="delivery-content text-center">
            <h1>Delivery Methods</h1>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Deliverybanner;
