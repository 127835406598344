import React from "react";
import ContactForm from "../ContactForm";

const Refrralcontent = () => {
  return (
    <>
      <section className="refrralcontent">
        <div className="container">
          <div className="refrallhead">
            <h2>
              Get $100 for Referring a Friend to Global Information Technology!
            </h2>
            <p>
              Did you recently have a satisfactory learning experience at Global
              Information Technology? Why not spread the word? If you do, we’ll
              reward you with a $100 Visa gift card!
            </p>
            <p>
              Tell your friends, colleagues, or family members about all the
              benefits of training with Global Information Technology, like our
              flexible schedules, small class size, job placement assistance and
              of course, our world-class certified instructors. Fill out the
              form on this page, or contact your Career Consultant today!
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-6">
              <ContactForm />
            </div>
            <div className="col-12 col-lg-6">
              <p>
                <span style={{ fontWeight: 700 }}>Terms and Conditions:- </span>
                <br />
                This offer is not available to customers who purchase classes on
                our website. The referral fee is paid out once the initial
                payment has been received for the referring student and the
                referred student has been successfully enrolled and is attending
                their first scheduled course. The referral fee will be paid out
                only after a payment has been received for both the original
                student and the referred student. Both the referring student and
                the referred student must be satisfactorily progressing within
                their program in order for payment to be processed. Payment is
                in the form of a $100 VISA gift card. Global Information
                Technology maintains the right to terminate this program at any
                time. Restrictions apply. Please contact your career consultant
                directly and mention this promotion.
              </p>
              <h4>
                If you would like to schedule a meeting call:
                <a href="tel:8664644846" style={{ textDecoration: "none" }}>
                  <span style={{ color: "#f5aa30", textDecoration: "none" }}>
                    1-866-464-4846
                  </span>
                </a>{" "}
                or fill out the form.
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Refrralcontent;
