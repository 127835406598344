import React,{Suspense} from "react";
import Testimonialbanner from "./Testimonial/Testimonialbanner";
import TestimonialContent from "./Testimonial/TestimonialContent";

function TestimonialRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Testimonialbanner />
        <TestimonialContent />
      </Suspense>
    </>
  );
}

export default TestimonialRoute;
