import React, { useState } from "react";
import david from "../image/david.png";
import jhon from "../image/jhon.png";
import carl from "../image/young-bearded-man-with-striped-shirt 1 (1).png";
import emily from "../image/Mask group (17) 1 (1).png";

function Withness() {
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [leftWithExpanded, setLeftWithExpanded] = useState(true);

  const handleLeftWithExpand = () => {
    setLeftWithExpanded(!leftWithExpanded);
    setIsExpanded1(false);
    setIsExpanded2(false);
    setIsExpanded3(false);
    setIsExpanded4(false);
  };

  const handleExpand1 = () => {
    setIsExpanded1(!isExpanded1);
    setLeftWithExpanded(false);
  };

  const handleExpand2 = () => {
    setIsExpanded2(!isExpanded2);
    setLeftWithExpanded(false);
  };

  const handleExpand3 = () => {
    setIsExpanded3(!isExpanded3);
    setLeftWithExpanded(false);
  };

  const handleExpand4 = () => {
    setIsExpanded4(!isExpanded4);
    setLeftWithExpanded(false);
  };

  return (
    <>
      <section className="withness">
        <div className="container">
          <h2>Witness the Journey of Triumph and Inspiration!</h2>
          <div className="row d-flex ">
            <div
              className={`col-12 col-lg-4 leftwith`}
              style={{ width: leftWithExpanded ? "31%" : "17%" }}
            >
              <div className="leftinner">
                <h4>Success Stories</h4>
                <p
                  className="hidep"
                  style={{ display: leftWithExpanded ? "block" : "none" }}
                >
                  Real People, Real Success: Discover
                  <br />
                  the Transformative Journeys of Our <br />
                  Achievers.
                </p>
                <button
                  className="btn hidebtn "
                  style={{ display: leftWithExpanded ? "block" : "none" }}
                >
                  Explore More
                </button>
              </div>
            </div>
            <div
              className={`col-12 col-lg-2 col1 rightwith`}
              onMouseEnter={handleExpand1}
              onMouseLeave={handleLeftWithExpand}
              style={{ width: isExpanded1 ? "31%" : "17%" }}
            >
              <div className="rightinner">
                <h6>
                  E-learning made
                  <br />
                  me a coding <br />
                  prodigy! Thanks!
                </h6>
                <p
                  className="hov"
                  style={{ visibility: isExpanded1 ? "visible" : "hidden" }}
                >
                  My experience with Global It Solutions
                  <br />
                  was amazing. So helpful and
                  <br /> supporting staff and environment.
                </p>
                <a
                  className="read"
                  style={{ visibility: isExpanded1 ? "visible" : "hidden" }}
                >
                  Read More...
                </a>
                <img src={david} loading="lazy" alt="David Morris" />
                <p className="author">David Morris</p>
              </div>
            </div>
            <div
              className={`col-12 col-lg-2 col2 rightwith`}
              onMouseEnter={handleExpand2}
              onMouseLeave={handleLeftWithExpand}
              style={{ width: isExpanded2 ? "31%" : "17%" }}
            >
              <div className="rightinner">
                <h6>
                  I'm a tech <br />
                  entrepreneur, all
                  <br />
                  thanks to you!
                </h6>
                <p
                  className="hov"
                  style={{ visibility: isExpanded2 ? "visible" : "hidden" }}
                >
                  Exceptional courses, interactive platform
                  <br />
                  My skills soared, and I landed my dream
                  <br />
                  job.
                </p>
                <a
                  className="read"
                  style={{ visibility: isExpanded2 ? "visible" : "hidden" }}
                >
                  Read More...
                </a>
                <img src={jhon} loading="lazy" alt="John S" />
                <p className="author">John S</p>
              </div>
            </div>
            <div
              className={`col-12 col-lg-2 col3 rightwith`}
              onMouseEnter={handleExpand3}
              onMouseLeave={handleLeftWithExpand}
              style={{ width: isExpanded3 ? "31%" : "17%" }}
            >
              <div className="rightinner">
                <h6>
                  Certified and <br />
                  hired in no time. <br />
                  Grateful!
                </h6>
                <p
                  className="hov"
                  style={{ visibility: isExpanded3 ? "visible" : "hidden" }}
                >
                  E-learning made easy! Engaging content,
                  <br />
                  supportive community.
                  <br />– My knowledge expanded exponentially.
                </p>
                <a
                  className="read"
                  style={{ visibility: isExpanded3 ? "visible" : "hidden" }}
                >
                  Read More...
                </a>
                <img src={carl} loading="lazy" alt="Carl Johnson" />
                <p className="author">Carl Johnson</p>
              </div>
            </div>
            <div
              className={`col-12 col-lg-2 col4 rightwith`}
              onMouseEnter={handleExpand4}
              onMouseLeave={handleLeftWithExpand}
              style={{ width: isExpanded4 ? "31%" : "17%" }}
            >
              <div className="rightinner">
                <h6>
                  Transformed my
                  <br />
                  career - endless
                  <br />
                  gratitude to you!
                </h6>
                <p
                  className="hov"
                  style={{ visibility: isExpanded4 ? "visible" : "hidden" }}
                >
                  Highly recommended! I aced my
                  <br />
                  certification, all thanks to the expert
                  <br />
                  guidance and resources.
                </p>
                <a
                  className="read"
                  style={{ visibility: isExpanded4 ? "visible" : "hidden" }}
                >
                  Read More...
                </a>
                <img src={emily} loading="lazy" alt="Emily M" />
                <p className="author">Emily M</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Withness;
