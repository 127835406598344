import React, { Suspense } from "react";
import FreeCareerbanner from "./FreeCareerPlanning/FreeCareerbanner";
import FreeplanningContent from "./FreeCareerPlanning/FreeplanningContent";

function FreecareerRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <FreeCareerbanner />
        <FreeplanningContent />
      </Suspense>
    </>
  );
}

export default FreecareerRoute;
