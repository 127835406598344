import React from "react";
import Card from "react-bootstrap/Card";
import staff1 from "../image/Mask group-2.png";
import staff2 from "../image/Mask group-1.png";
import staff3 from "../image/Mask group.png";

function Staffcontent() {
  return (
    <>
      <section className="staffcontent ">
        <div className="container">
          <h3>
            We believe in the 3 R’s – Providing the Right Skills, for the Right
            Job, at the Right Time.
          </h3>
          <div className="row mt-5">
            <div className="col-12 col-lg-4">
              <Card>
                <Card.Img variant="top" src={staff1} loading="lazy" />
                <Card.Body>
                  <Card.Title>Temporary or Contract</Card.Title>
                  <Card.Text>
                    Our clients benefit from the flexibility of a contingent
                    workforce to supplement short-term and long-term staffing
                    needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card>
                <Card.Img variant="top" src={staff2} loading="lazy" />
                <Card.Body>
                  <Card.Title>Direct Hire Placement</Card.Title>
                  <Card.Text>
                    Our direct-hire placement services are performed on a
                    contingency fee basis only, which means you pay us a fee
                    only after hiring one of our candidates.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card>
                <Card.Img variant="top" src={staff3} loading="lazy" />
                <Card.Body>
                  <Card.Title>Contract to Hire</Card.Title>
                  <Card.Text>
                    While contract-to-hire is not ideal for every situation, in
                    our current market, candidates are often open to showing you
                    what they can do in hopes of securing a job with a great
                    company.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Staffcontent;
