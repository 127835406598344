import React from "react";
import ContactForm from "../ContactForm";

function Booktour() {
  return (
    <>
      <section className="bookcontent">
        <div className="container">
          <h2>Appointments Available</h2>
          <p className="book-para">
            Your health and safety are our number one priority, and so is your
            future! Our admissions team is here to support and guide you through
            the process every step of the way, and booking an admission and
            career planning session is now easier than ever.
          </p>
          <p className="book-para">
            Please call 1-866-464-4846 to set up a virtual or on-campus
            appointment, or use the form below to schedule an appointment. We
            look forward to assisting you with your career goals.
          </p>
          <ContactForm />
        </div>
      </section>
    </>
  );
}

export default Booktour;
