import React,{Suspense} from "react";
import Webdesignbanner from "./WebDesign/Webdesignbanner";
import Webservice from "./WebDesign/Webservice";
import Webwhyglobalit from "./WebDesign/Webwhyglobalit";
import Webdesignportfolio from "./WebDesign/Webdesignportfolio";
import Webcontact from "./WebDesign/Webcontact";

function WebdesignRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Webdesignbanner />
        <Webservice />
        <Webwhyglobalit />
        <Webdesignportfolio />
        <Webcontact />
      </Suspense>
    </>
  );
}

export default WebdesignRoute;
