import React,{Suspense} from "react";
import Vetransbanner from "./Vetrans/Vetransbanner";
import Vetransbenifit from "./Vetrans/Vetransbenifit";
import BenifitDetail from "./Vetrans/BenifitDetail";

function VetransRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Vetransbanner />
        <Vetransbenifit />
        <BenifitDetail />
      </Suspense>
    </>
  );
}

export default VetransRoute;
