import React, { useState } from "react";
import { Rating } from "@mui/material";
import Registermodal from "../home/Registermodal";

function Webdesignbanner() {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="webdesignbanner">
        <div className="container h-100">
          <div className="web-content">
            <h1>Web Design</h1>
            <p>
              Best web design company in Michigan. A website should not just
              draw <br />
              attention. The role of a website is to attract and engage the
              user, as well <br /> as communicate your brand and raise awareness
              about a product or service.
            </p>
            <Rating
              name="read_only"
              defaultValue={5}
              readOnly
              className="mb-4"
            />
            <br />
            <button className="start btn" onClick={openregisModal}>
              Start Consulting
            </button>
          </div>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Webdesignbanner;
