import React from "react";
import CommonComp from "../CommonComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

function Webcontact() {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!formik.isValidating) {
        const apiUrl =
          "https://git.digiwbs.com/Admin/public/api/apicallbackSend";
        axios
          .post(apiUrl, values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((result) => {
            Swal.fire({
              title: "success",
              text: "Form Submitted Successfully!",
              icon: "success",
            });

            formik.resetForm();
          })
          .catch((err) => {
            console.error("Error submitting form:", err);
            Swal.fire({
              title: "error",
              text: "Error submitting form",
              icon: "error",
            });
          });
      }
    },
  });

  return (
    <>
      <section className="web-contact">
        <div className="container">
          <form onSubmit={formik.handleSubmit}>
            <h3>Get a Call back</h3>
            <div className="d-flex name ">
              <div className="d-grid w-100">
                <CommonComp
                  type={"text"}
                  placeholder={"full Name*"}
                  name={"first_name"}
                  id="first_name"
                  className={"full_name"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <p className="err">{formik.errors.first_name}</p>
                )}
              </div>
              <div className="d-grid w-100">
                <CommonComp
                  type={"email"}
                  placeholder={"Email*"}
                  name={"email"}
                  id="email"
                  className={"email"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="err">{formik.errors.email}</p>
                )}
              </div>
              <div className="d-grid w-100">
                <CommonComp
                  type={"number"}
                  placeholder={"Phone No.*"}
                  name={"phone"}
                  id="phone"
                  className={"phone"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className="err">{formik.errors.phone}</p>
                )}
              </div>
            </div>
            <div className="d-flex name w-100 mt-2">
              <div className="d-grid web-message">
                <textarea
                  cols={6}
                  placeholder="Comments*"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message && (
                  <p className="err">{formik.errors.message}</p>
                )}
              </div>
              <button className="btn button1  web-button" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Webcontact;
