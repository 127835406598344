import React from "react";
import ContactForm from "../ContactForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Examregiscontent = () => {
  return (
    <>
      <section className="examregiscontent">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Visit www.2test.com to register your exam</p>
              </div>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Call Prometric directly @ 800-356-EXAM (3926)</p>
              </div>
              <h2>
                Microsoft Office Specialist, Adobe, Qucikbooks, AutoDesk Exam
                Registration
              </h2>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  For exam discounts and exam registration call Global I.T. to
                  register your exam @ 866-GO-GIT-GO (464-4846)
                </p>
              </div>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  Global Information Technology is an Authorized Certiport test
                  center.
                </p>
              </div>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  Visit{" "}
                  <a
                    href="https://certiport.pearsonvue.com/"
                    style={{ color: "#F5AA30", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.certiport.com
                  </a>{" "}
                  to register your exam
                </p>
              </div>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Call Certiport directly @ 888-222-7890</p>
              </div>
              <h2>VUE Exam Registration</h2>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>
                  Visit{" "}
                  <a
                    href="https://home.pearsonvue.com/"
                    style={{ color: "#F5AA30", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.pearsonvue.com
                  </a>{" "}
                  to register your exam
                </p>
              </div>
              <div className="d-flex ">
                <CheckCircleIcon
                  style={{ color: "#00A5DD" }}
                  className="me-2"
                />
                <p>Call VUE directly @ 1-877 551-7587</p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Examregiscontent;
