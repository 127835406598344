import React,{Suspense} from "react";
import Networkbanner from "./Network Service/Networkbanner";
import Networkcontent from "./Network Service/Networkcontent";
import Deliverycontact from "./Delivery_Method/Deliverycontact";
import Networkaccordian from "./Network Service/Networkaccordian";

function NetworkRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Networkbanner />
        <Networkcontent />
        <Networkaccordian />
        <Deliverycontact />
      </Suspense>
    </>
  );
}

export default NetworkRoute;
