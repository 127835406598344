import React from "react";
import image1 from "../image/focus (1).png";
import image2 from "../image/partner.png";
import image3 from "../image/customer.png";
import image4 from "../image/business-idea.png";

function Strategycontent() {
  return (
    <>
      <section className="startegycontent" id="corporate_strategy">
        <div className="container">
          <h2>Corporate Strategy</h2>
          <div className="row d-flex flex-wrap">
            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image1} alt="focus" loading="lazy" />
                <h3>Client-centric focus</h3>
              </div>
              <p>
                We never take customer loyalty for granted. Our goal is always
                to exceed their perceived expectations. We strive to
                continuously add value by listening to our customers and staying
                ahead of business and technological shifts, thus enabling us to
                respond with speed and accuracy to the needs of our customers.
              </p>
            </div>

            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image2} alt="Partner" loading="lazy" />
                <h3>“Partner-Up” Prospective</h3>
              </div>
              <p>
                In this newly revitalized economy, no company can satisfy all
                its needs alone. Global IT recognizes this and partners with
                “best-of-breed” companies to achieve greater productivity,
                better cost savings, and a higher degree of flexibility.
              </p>
            </div>

            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image3} alt="values" loading="lazy" />
                <h3>Creating New Value</h3>
              </div>
              <p>
                At Global IT, we combine new technology with proven
                methodologies and day-to-day experiences, thus maximizing the
                value-added potential of existing enterprise solutions.
              </p>
            </div>

            <div className="corporate-div">
              <div className="d-flex align-items-center">
                <img src={image4} alt="Solutions" loading="lazy" />
                <h3>Scalable Solutions</h3>
              </div>
              <p>
                Our values are driven by customer focus, intelligence,
                resourcefulness, and our entrepreneurial spirit. We are an
                inspired team that values and respects the contributions of
                everyone. We place a great deal of emphasis on integrity and
                refuse to take action that will compromise it. We value and
                practice a never-ending improvement philosophy and are open to
                constructive criticism.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Strategycontent;
