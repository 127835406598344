import { Rating } from '@mui/material';
import React from 'react'

function Careercoursebanner() {
  return (
    <>
      <section className="track-course">
        <div className="container h-100">
          <div className="trackcourse-content">
            <h1>Career Tracks</h1>
            <Rating
              name="read-only"
              defaultValue={5}
              readOnly
              className="ps-2"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Careercoursebanner