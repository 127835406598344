import React,{Suspense} from 'react'
import Freenightbanner from './Free_career_night/Freenightbanner'
import Freenightcontent from './Free_career_night/Freenightcontent'

const FreenightRoute = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Freenightbanner />
        <Freenightcontent />
      </Suspense>
    </>
  );
}

export default FreenightRoute