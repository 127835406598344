import React, { useState } from "react";
import country from "../image/american-soldier-mourning-praying-with-american-flag-front-him 2.png";
import staff from "../image/Rectangle 157.png";
import search from "../image/mother-daughter-business-workers-smiling-confident-working-office 1.png";
import success from "../image/pexels-sora-shimazaki-5673488 1.png";
import ContactForm from "../ContactForm";
import { Link } from "react-router-dom";
import Registermodal from "./Registermodal";

function Excelform() {
  const [showregisModal, setShowregisModal] = useState(false);

  const openregisModal = () => setShowregisModal(true);
  const closeregisModal = () => setShowregisModal(false);

  return (
    <>
      <section className="excel">
        <div className="container">
          <div className="row pb-5">
            <div className="col-12 col-lg-8 ps-3 rightxt">
              <h2>Excel Your Career with Leading Technology Certifications</h2>
              <p>
                Whether you are an IT professional looking to break into the
                I.T. Industry, or want to enhance your skills, Global I.T. has
                the training that is right for you. Knowing this is the hottest
                industry to be in for the long run, it is important to pick the
                right school. GIT offers career-oriented certification courses.
                designed to fit one’s specific needs in the areas of networking,
                Database Administration, e-commerce, Web Development, Clerical,
                and Programming. Our courses are highly structured, considering
                industry requirements, cost-effectiveness, and emerging
                technologies.
                <br />
                <br />
                Global I.T. has many benefits to offer, making it the best
                school. to train at Global I.T. will focus on your goals and
                your interests and work to bring those together to help you
                cost-effectively meet your career objectives. Global I.T.
                assures your certification and will put its name on the line to
                back it up. In addition to training, GIT offers services in
                staffing, web development, and IT consulting solutions.
              </p>
              <div className="row gy-4">
                <div className="col-12 col-lg-6 ">
                  <div className="exclcard">
                    <img
                      className=""
                      src={country}
                      loading="lazy"
                      alt="country"
                    />
                    <h3 className="firsttxt">
                      You Have Served Our Country Now Let Us Serve You!
                    </h3>
                    <div className="excelbody"></div>
                    <p>
                      We accept Veterans Benefits, Post-9-11 GI Bill®, Voc
                      Rehab, VET TEC, Montgomery GI Bill® Active Duty
                    </p>
                    <Link to="/vetrans">Learn More</Link>
                  </div>
                </div>
                <div className="col-12 col-lg-6 ">
                  <div className="exclcard">
                    <img className="" src={staff} loading="lazy" alt="staff" />
                    <h3 className="">
                      It Consulting and Staffing in Michigan.
                    </h3>
                    <div className="excelbody">
                      <p>
                        Providing the right skills for the right job at the
                        right time.
                      </p>
                      <Link to="/staff_service">Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row gy-4">
                <div className="col-12 col-lg-6 ">
                  <div className="exclcard">
                    <img src={search} loading="lazy" alt="sewarch" />
                    <h3 className="excelcard3">
                      Need Fuel for Your Job Search?
                    </h3>
                    <div className="excelbody">
                      <p>
                        Get Java Certified, Become a better Java developer and
                        open up new career opportunities.
                      </p>
                      <Link to="/category/Java Training/14">Learn More</Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 ">
                  <div className="exclcard">
                    <img src={success} loading="lazy" alt="success" />
                    <h3 className="">Let us make you a Success!</h3>
                    <div className="excelbody">
                      <p>
                        The Premier Provider of I.T., Training, and Consulting
                        Services till 1999.
                      </p>
                      <button onClick={openregisModal}>Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <Registermodal show={showregisModal} handleClose={closeregisModal} />
    </>
  );
}

export default Excelform;
