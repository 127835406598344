import React, { Suspense } from "react";
import Banner from "./home/Banner";
import Excelform from "./home/Excelform";
import Withness from "./home/Withness";
import Learn from "./home/Learn&get";
import Certificatecourses from "./home/Certificatecourses";
import About from "./home/About";
import Oursupport from "./home/Oursupport";
// import Mostpopular from "./home/Mostpopular";
import Contactform from "./home/GetEvryAns";
import Careertrack from "./home/Careertrack";
import Fixedbanner from "./home/Fixedbanner";

function Home() {
  return (
    <>e
      <Suspense fallback={<div>Loading...</div>}>
        <Banner />
        <Fixedbanner/>
        <Excelform />
        <Oursupport />
        <Careertrack/>
        <Certificatecourses />
        <About />
        {/* <Mostpopular /> */}
        <Learn />
        <Withness />
        <Contactform />
      </Suspense>
    </>
  );
}

export default Home;
