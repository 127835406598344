import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import CommonComp from "../CommonComp";
import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = Yup.object({
  who_are_you: Yup.string().required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  career_course: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  agreeToContact: Yup.boolean()
    .oneOf([true], "Must agree to contact")
    .required("Must agree to contact"),
  tuition_fee: Yup.array()
    .of(Yup.string()) // Assuming tuition_fee is an array of strings
    .min(1, "At least one checkbox must be selected") // At least one checkbox must be selected
    .required("At least one checkbox must be selected"), // Required
});

function TrackFormmodal({ show, handleClose }) {
  const [recaptchaToken, setRecaptchaToken] = useState("");
  //   const [courses, setCourses] = useState([]);
  const [Trackcourse, setTrackcousre] = useState();

  const formik = useFormik({
    initialValues: {
      who_are_you: "",
      tuition_fee: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      career_course: "",
      message: "",
      agreeToContact: false,
    },
    validationSchema,
    onSubmit: (values) => {
      // Convert tuition_fee array to comma-separated string
      const tuitionFeeString = values.tuition_fee.join(", ");

      // Create a new object with the formatted tuition_fee
      const formData = {
        ...values,
        tuition_fee: tuitionFeeString,
      };

      if (!recaptchaToken) {
        Swal.fire({
          title: "error",
          text: "Please complete the reCAPTCHA",
          icon: "error",
        });
        return;
      }

      values.recaptchaToken = recaptchaToken;

      axios
        .post(
          "https://git.digiwbs.com/Admin/public/api/apitrackerQuerysend",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          Swal.fire({
            title: "success",
            text: "Form Submitted Successfully!",
            icon: "success",
          });
          formik.resetForm();
        })
        .catch((err) => {
          console.error("Error fetching courses:", err);
          Swal.fire({
            title: "error",
            text: "Error submitting form",
            icon: "error",
          });
        });
    },
  });

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const tuitionFeeValue = formik.values.tuition_fee || [];

    let updatedTuitionFee = [];

    if (checked) {
      updatedTuitionFee = [...tuitionFeeValue, value];
    } else {
      updatedTuitionFee = tuitionFeeValue.filter((item) => item !== value);
    }

    formik.setFieldValue("tuition_fee", updatedTuitionFee);
    formik.setFieldTouched("tuition_fee", false); // Set the field as touched

    // Manually trigger validation for tuition_fee
    formik.validateField("tuition_fee");
  };

  //   useEffect(() => {
  //     axios
  //       .get("https://git.digiwbs.com/Admin/public/api/apiCoursesAll")
  //       .then((result) => {
  //         const coursesData = result.data.data;
  //         setCourses(coursesData);
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching courses:", err);
  //       });
  //   }, []);

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apicareerAll")
      .then((result) => {
        setTrackcousre(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setTrackcousre]);

  function onChange(value) {
    setRecaptchaToken(value);
  }
  return (
    <Modal show={show} onHide={handleClose} className="track-form">
      <span className="close" onClick={handleClose}>
        &times;
      </span>
      <div className="trackform-modal">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-clip">
            <h3 className="text">Get in Touch !</h3>
          </div>
          <div className="formdiv">
            <div className="under-div">
              <h6>
                Who are You ! <span style={{ color: "red" }}>*</span>
              </h6>
              <div className="formradio">
                <input
                  type="radio"
                  id="Individual"
                  name="who_are_you"
                  value="Individual"
                  checked={formik.values.who_are_you === "Individual"}
                  onChange={formik.handleChange}
                />
                <label htmlFor="Individual">Individual</label>
                <input
                  type="radio"
                  id="Veteran"
                  name="who_are_you"
                  value="Veteran"
                  checked={formik.values.who_are_you === "Veteran"}
                  onChange={formik.handleChange}
                />
                <label htmlFor="Veteran">Veteran</label>
                <input
                  type="radio"
                  id="Business"
                  name="who_are_you"
                  value="Business"
                  checked={formik.values.who_are_you === "Business"}
                  onChange={formik.handleChange}
                />
                <label htmlFor="Business">Business</label>
              </div>
              {formik.touched.who_are_you && formik.errors.who_are_you && (
                <p className="err">{formik.errors.who_are_you}</p>
              )}
              <div className="checkbox question">
                <input
                  type="checkbox"
                  id="How much is the tuition fee?"
                  name="tuition_fee"
                  value="How much is the tuition fee?"
                  checked={formik.values.tuition_fee?.includes(
                    "How much is the tuition fee?"
                  )}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor="How much is the tuition fee?">
                  How much is the tuition fee?
                </label>
                <br />
                <input
                  type="checkbox"
                  id="Can i get financial aid?"
                  name="tuition_fee"
                  value="Can i get financial aid?"
                  checked={formik.values.tuition_fee?.includes(
                    "Can i get financial aid?"
                  )}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor="Can i get financial aid?">
                  Can i get financial aid?
                </label>
                <br />
                <input
                  type="checkbox"
                  id="What are my career Prospects?"
                  name="tuition_fee"
                  value="What are my career Prospects?"
                  checked={formik.values.tuition_fee?.includes(
                    "What are my career Prospects?"
                  )}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor="What are my career Prospects?">
                  What are my career Prospects?
                </label>
                <br />
                <input
                  type="checkbox"
                  id="Start of the course?"
                  name="tuition_fee"
                  value="Start of the course?"
                  checked={formik.values.tuition_fee?.includes(
                    "Start of the course?"
                  )}
                  onChange={handleCheckboxChange}
                ></input>
                <label htmlFor="Start of the course?">
                  Start of the course?
                </label>
                <br />
                {formik.touched.tuition_fee && formik.errors.tuition_fee && (
                  <p className="err">{formik.errors.tuition_fee}</p>
                )}
              </div>
            </div>
            <div className="under-div mt-3 info-div">
              <div className="perinfo ">
                {/* <div className="name d-flex gx-5"> */}
                <div className="grid tracklabel">
                  <CommonComp
                    type={"text"}
                    placeholder={"First Name*"}
                    name={"first_name"}
                    id="first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <p className="err">{formik.errors.first_name}</p>
                  )}
                </div>
                <div className="d-grid tracklabel ">
                  <CommonComp
                    type={"text"}
                    placeholder={"Last Name*"}
                    name={"last_name"}
                    id="last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <p className="err">{formik.errors.last_name}</p>
                  )}
                </div>
                {/* </div> */}
                {/* <div className="name d-flex gx-5"> */}
                <div className="d-grid tracklabel ">
                  <CommonComp
                    type={"email"}
                    placeholder={"Email*"}
                    name={"email"}
                    id="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="err">{formik.errors.email}</p>
                  )}
                </div>
                <div className="d-grid tracklabel ">
                  <CommonComp
                    type={"number"}
                    placeholder={"Phone Number*"}
                    name={"phone"}
                    id="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <p className="err">{formik.errors.phone}</p>
                  )}
                </div>
                {/* </div> */}
                <div className="tracklabel">
                  <CommonComp
                    type={"text"}
                    placeholder={"Address*"}
                    name={"address"}
                    id="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <p className="err">{formik.errors.address}</p>
                  )}
                </div>
                {/* <div className="name d-flex gx-5"> */}
                <div className="d-grid tracklabel">
                  <CommonComp
                    type={"text"}
                    placeholder={"City*"}
                    name={"city"}
                    id="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <p className="err">{formik.errors.city}</p>
                  )}
                </div>
                <div className="d-grid tracklabel">
                  <CommonComp
                    type={"text"}
                    placeholder={"State*"}
                    name={"state"}
                    id="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <p className="err">{formik.errors.state}</p>
                  )}
                </div>
                {/* </div> */}
                <div className="tracklabel">
                  <CommonComp
                    type={"text"}
                    placeholder={"Zip*"}
                    name={"zip"}
                    id="zip"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zip}
                  />
                  {formik.touched.zip && formik.errors.zip && (
                    <p className="err">{formik.errors.zip}</p>
                  )}
                </div>
                <div className="select tracklabel">
                  <select
                    name="career_course"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.career_course}
                  >
                    <option>Career Track*</option>
                    <option>None of the above</option>
                    {Trackcourse &&
                      Trackcourse.map((val, index) => (
                        <option value={val.title} key={index}>
                          {val.title}
                        </option>
                      ))}
                  </select>
                  {formik.touched.career_course &&
                    formik.errors.career_course && (
                      <p className="err">{formik.errors.career_course}</p>
                    )}
                </div>
                <textarea
                  rows="4"
                  cols="6"
                  name="message"
                  form="usrform"
                  placeholder="Message*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
                {formik.touched.message && formik.errors.message && (
                  <p className="err">{formik.errors.message}</p>
                )}
                <p className="note">
                  By Submitting This Form, You Are Giving Your Express Written
                  Consent For Global Information Technology To Contact You
                  Regarding Our Programs And Services Using Email, Telephone Or
                  Text. This Consent Is Not Required To Purchase Goods/Services
                  and You May Always Call Us Directly At{" "}
                  <a href="tel:8664644846" style={{ textDecoration: "none" }}>
                    <span style={{ color: "#f5aa30", textDecoration: "none" }}>
                      1-866-464-4846
                    </span>
                    .
                  </a>
                </p>
              </div>

              <div className="confirmbox ">
                <div className="confirm d-flex align-items-center ">
                  <input
                    type="checkbox"
                    name="agreeToContact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.agreeToContact}
                  />
                  <label>
                    I agree to be contacted over email and phone.
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <br />
                </div>

                {formik.touched.agreeToContact &&
                  formik.errors.agreeToContact && (
                    <p className="err">{formik.errors.agreeToContact}</p>
                  )}

                <ReCAPTCHA
                  className="g-recaptcha"
                  sitekey="6Lc9I6wpAAAAAKUnLmeQkoFDZ5zGxI_oZgpDJg7d"
                  onChange={onChange}
                  onExpired={() => console.log("reCAPTCHA expired")}
                  onErrored={() => console.error("reCAPTCHA error")}
                />

                <button className="btn mt-3" type="submit">
                  Contact
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default TrackFormmodal;
