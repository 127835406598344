import React from "react";
import { Link } from "react-router-dom";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Coursesection({ data }) {
  return (
    <>
      <section className="catesec">
        <div className="container">
          <div className="row">
            {data.map((val) => (
              <div class="card">
                <img
                  className="course-img"
                  src={`${imagePath}${val?.img}`}
                  loading="lazy"
                  alt="course_img"
                />
                <div class="card-body">
                  <h5 class="card-title">{val?.title}</h5>
                  <Link to={`/Course/${val.title} / ${val.id}`} class="btn">
                    View Course
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Coursesection;
