import React, { useEffect, useState } from "react";
import ContactForm from "../ContactForm";
import { Table } from "react-bootstrap";
import axios from "axios";

function Classtabel() {
  const [courseShedule, setcourseShedules] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiCourseShedule")
      .then((result) => {
        const data = result.data.courseSheduleData;
        console.log("result class", data);
        setcourseShedules(data);
      })
      .catch((err) => {
        // console.error("Error fetching events:", err);
      });
  }, []);

  const handleCourseChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCourse(selectedValue);
  };

  const renderTables = () => {
    const uniqueCourses = Array.from(
      new Set(courseShedule.map((val) => val.course_name))
    );

    return (
      <>
        {uniqueCourses.map((courseName) => (
          <Table
            key={`${courseName}-${uniqueCourses.indexOf(courseName)}`}
            striped
            bordered
            hover
            style={{
              display:
                selectedCourse === courseName || selectedCourse === ""
                  ? "table"
                  : "none",
            }}
          >
            <thead>
              <tr className="tr-head">
                <th colSpan={4} className="tabel-name">
                  <h4>{courseName}</h4>
                </th>
              </tr>
              <tr className="tr-text">
                <th>Start Date</th>
                <th>Days</th>
                <th>Timings</th>
                <th>Duration (hours)</th>
              </tr>
            </thead>
            <tbody>
              {courseShedule
                .filter((val) => val.course_name === courseName)
                .map((val, innerIndex) => (
                  <tr key={`${courseName}-${innerIndex}`}>
                    <td>{val.start}</td>
                    <td>{val.days}</td>
                    <td>{val.timings}</td>
                    <td>{val.hours}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ))}
      </>
    );
  };

  return (
    <>
      <section className="class-table mt-3 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 left-table">
              <div className="course-select mt-5 mb-4">
                <h2>Schedule</h2>
              </div>
              <ContactForm />
            </div>
            <div className="col-12 col-lg-8 mt-5">
              <select
                name="course_name"
                value={selectedCourse}
                onChange={handleCourseChange}
              >
                <option value="">Filter by Course</option>
                {Array.from(
                  new Set(courseShedule.map((val) => val.course_name))
                ).map((courseName, index) => (
                  <option key={`${courseName}-${index}`} value={courseName}>
                    {courseName}
                  </option>
                ))}
              </select>
              {renderTables()}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Classtabel;
