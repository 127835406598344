import React from "react";
import comptia from "../image/techcomptia.png";
import cisco from "../image/techcisco.png";
import pmi from "../image/texhpmi.png";
import certiport from "../image/techcertiport .png";
import ec from "../image/techec-council.png";
import ibm from "../image/techibm .png";
import microsoft from "../image/techmicrosoft .png";
import iba from "../image/techiba.png";
import oracle from "../image/techoracle.png";
import saile from "../image/finansaile.png";
import alley from "../image/finanalley.png";
import bbb from "../image/finanbbb.png";
import bdc from "../image/finanmmbdc.png";
import esd from "../image/finanesd.png";
import works from "../image/educaworks.png";
import dleg from "../image/educadleg.png";
import symantec from "../image/reselsymantec.png";
import hp from "../image/reselhpinvent.png";
import dell from "../image/reseldell.png";
import troy from "../image/affilatedtroy.png";
import maccs from "../image/affilatedmaccs.png";
import detroit from "../image/affilateddetroit.png";

function Partnercontent() {
  return (
    <>
      <section className="partnercontent" id="partner">
        <div className="container ">
          <h2>Our Partner</h2>
          <div className="d-flex flex-wrap partner-gap">
            <div className="d-grid">
              <h3>Technical Partners</h3>
              <div className="techpartner mb-5">
                <img src={comptia} alt="technical" loading="lazy" />
                <img src={cisco} alt="technical" loading="lazy" />
                <img src={pmi} alt="technical" loading="lazy" />
                <img src={certiport} alt="technical" loading="lazy" />
                <img src={ec} alt="technical" loading="lazy" />
                <img src={ibm} alt="technical" loading="lazy" />
                <img src={microsoft} alt="technical" loading="lazy" />
                <img src={iba} alt="technical" loading="lazy" />
                <img src={oracle} alt="technical" loading="lazy" />
              </div>
            </div>
            <div className="d-grid">
              <h3>Financial Partners</h3>
              <div className="financial mb-5">
                <img src={saile} alt="financial" loading="lazy" />
              </div>
            </div>
            <div className="d-grid">
              <h3>Business Affiliates</h3>
              <div className="business mb-5">
                <img src={alley} alt="financial" loading="lazy" />
                <img src={bbb} alt="financial" loading="lazy" />
                <img src={bdc} alt="financial" loading="lazy" />
                <img src={esd} alt="financial" loading="lazy" />
              </div>
            </div>
            <div className="d-grid">
              <h3>Educational Affiliates</h3>
              <div className="educational mb-5">
                <img src={works} alt="financial" loading="lazy" />
                <img src={dleg} alt="financial" loading="lazy" />
              </div>
            </div>
            <div className="d-grid">
              <h3>Resellers</h3>
              <div className="resellers mb-5">
                <img src={symantec} alt="financial" loading="lazy" />
                <img src={dell} alt="financial" loading="lazy" />
                <img src={hp} alt="financial" loading="lazy" />
              </div>
            </div>
            <div className="d-grid">
              <h3>Affiliates</h3>
              <div className="affiliates mb-5">
                <img src={troy} alt="financial" loading="lazy" />
                <img src={maccs} alt="financial" loading="lazy" />
                <img src={detroit} alt="financial" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Partnercontent;
