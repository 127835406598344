import React, { Suspense } from "react";
import Searchpage from "./Searchpage/Searchpage";

function SearchpageRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Searchpage/>
      </Suspense>
    </>
  );
}

export default SearchpageRoute