import React from "react";
import { Link } from "react-router-dom";
const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function CourseList({ data, type, selectedType }) {
  const url = (selectedType) => type[selectedType] || "";

  return (
    <ul className="courselist">
      {data &&
        data.map((course, index) => (
          <li key={index}>
            <Link to={`${url(selectedType)}/${course.title} / ${course?.id}`}>
              <img
                src={`${imagePath}${course?.img}`}
                loading="lazy"
                alt="course_img"
              />
              <p>{course?.title}</p>
              <p>{course?.description}</p>
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default CourseList;
