import React, { useEffect, useState } from "react";
import CourseList from "./Courselist";
import axios from "axios";
import Usefullink from "./Usefullink";

const CourseDropdown = ({ courseData, handleCategoryClick, selectedCat }) => {
  const [selectedCategory, setSelectedCategory] = useState("technology");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiObj = {
    technology: `https://git.digiwbs.com/Admin/public/api/apiCourses?per_page=all`,
    certification: `https://git.digiwbs.com/Admin/public/api/apiPopularCourses`,
    vendor: `https://git.digiwbs.com/Admin/public/api/apiCourseCategories`,
  };

  const urlObj = {
    technology: `/Course`,
    certification: `/Course`,
    vendor: `/category`,
  };

  const getLinkForCategory = (selectedCate) => apiObj[selectedCate] || null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axios.get(getLinkForCategory(selectedCategory));
        let data = [];

        if (selectedCategory === "technology") {
          data = result.data.data.data;
        } else if (selectedCategory === "certification") {
          data = result.data.PopularCourses.data;
        } else if (selectedCategory === "vendor") {
          data = result.data.categoriesList;
        }

        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          console.error("Invalid data format for categories:", data);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getLinkForCategory(selectedCategory)]);

  const handleCategoryClickWithPrevent = (category, event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="dropdown">
        <li
          className=" dropdown-toggle nav-item pe-2 all-co"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
        >
          All Courses
        </li>
        <div
          className={`dropdown-menu all alldrop ${selectedCat ? "show" : ""}`}
          aria-labelledby="dropdownMenuButton"
        >
          <div className="row">
            <div className="col-lg-2 categories">
              <h6>Course Categories</h6>
              <button
                className="dropdown-item"
                onClick={(e) => handleCategoryClickWithPrevent("technology", e)}
              >
                Browse By Technology
              </button>
              <button
                className="dropdown-item"
                onClick={(e) =>
                  handleCategoryClickWithPrevent("certification", e)
                }
              >
                Browse By Certification
              </button>
              <button
                className="dropdown-item"
                onClick={(e) => handleCategoryClickWithPrevent("vendor", e)}
              >
                Browse By Vendor
              </button>
              <button
                className="dropdown-item"
                onClick={(e) => handleCategoryClickWithPrevent("usefulLink", e)}
              >
                Useful Link
              </button>
            </div>

            <div className="col-lg-10 courses">
              <h5>
                {selectedCategory === "usefulLink"
                  ? "Useful Links"
                  : "Popular Course"}
              </h5>
              {loading ? (
                <div className="loader"></div>
              ) : (
                <div className="row">
                  <div className="me-5">
                    {selectedCategory === "usefulLink" ? (
                      <Usefullink />
                    ) : (
                      <CourseList
                        data={categories}
                        type={urlObj}
                        selectedType={selectedCategory}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDropdown;
