import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import finance from "../image/Finance.svg";
import schooler from "../image/schooler.svg";
import workshop from "../image/freeworkshop.svg";
import { Link } from "react-router-dom/dist";

function Oursupport() {
  return (
    <>
      <section className="oursupport">
        <div className="container">
          <h5>Our Support</h5>
          <h2>Empowering Your Learning Journey</h2>
          <div className="row gy-4">
            <div className="col-12 col-lg-4">
              <div className="card">
                <div className="d-flex align-items-center">
                  <img src={finance} loading="lazy" alt="finance" />
                  <h3>Financial Aid</h3>
                </div>
                <p>
                  Invest in your future with our flexible financial assistance
                  options.
                </p>
                <Link to="/financialAid">
                  <span>
                    Learn More
                    <ArrowForwardIosIcon
                      className="ms-1"
                      sx={{ fontSize: 16 }}
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card">
                <div className="d-flex align-items-center">
                  <img src={schooler} loading="lazy" alt="scholarship" />
                  <h3>Scholarship</h3>
                </div>
                <p>
                  Unlock amazing opportunities with scholarships for your
                  journey.
                </p>
                <Link to="/Schoolership">
                  <span>
                    Learn More
                    <ArrowForwardIosIcon
                      className="ms-1"
                      sx={{ fontSize: 16 }}
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card">
                <div className="d-flex align-items-center">
                  <img src={workshop} loading="lazy" alt="workshop" />
                  <h3>Free Workshop</h3>
                </div>
                <p>
                  Expand your knowledge with interactive free workshop sessions.
                </p>
                <Link to="/freeWorkShop">
                  <span>
                    Learn More
                    <ArrowForwardIosIcon
                      className="ms-1"
                      sx={{ fontSize: 16 }}
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Oursupport;
