import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Searchpage() {
  const [courses, setCourses] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const searchQuery = searchParams.get("query");

  const formik = useFormik({
    initialValues: {
      searchText: searchQuery || "", // Initialize with the current search query
    },
    onSubmit: () => {
      // Trigger the search on form submission
      handleSearch(formik.values.searchText);
    },
  });

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      axios
        .get(
          `https://git.digiwbs.com/Admin/public/api/apiCoursesSearch?search=${searchQuery}`
        )
        .then((result) => {
          setCourses(result.data.Courses?.data || []);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchQuery]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      handleSearch(formik.values.searchText); // Trigger the search when Enter is pressed
    }
  };

  const handleSearch = (searchText) => {
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText) {
      setSearchParams({ query: trimmedSearchText }); // Update query parameters to trigger a new search
    }
  };

  return (
    <>
      <section className="searchpage">
        <div className="container">
          <h2 className="text-center">Search Results for {searchQuery}</h2>
          <div className="search-icon d-flex justify-content-center align-items-center">
            <input
              className="search-text"
              type="text"
              placeholder="Search..."
              name="searchText"
              onChange={(e) => {
                formik.handleChange(e);
                handleSearch(e.target.value); // Pass the current value to handleSearch
              }}
              onBlur={formik.handleBlur}
              value={formik.values.searchText}
              onKeyDown={handleKeyDown} // Trigger search on Enter key press
            />
            <SearchIcon
              style={{
                color: "#939393",
                marginTop: "0.5rem",
                marginLeft: "0.5rem",
                rotate: "90deg",
                cursor: "pointer",
                width: "2.5rem",
                height: "2.3rem",
              }}
              onClick={() => handleSearch(formik.values.searchText)} // Trigger search on icon click
            />
          </div>
          {loading ? (
            <div className="loader"></div>
          ) : (
            <div className="searchresult mt-5">
              {courses.map((course) => (
                <div className="card" key={course.id}>
                  <div className="card-image">
                    <img src={`${imagePath}${course.img}`} alt={course.title} />
                  </div>
                  <div className="card-body d-grid">
                    {course.title}
                    <Link to={`/Course/${course.title}/${course.id}`}>
                      <button>
                        Learn More{" "}
                        <ArrowForwardIosIcon
                          className="ms-1"
                          sx={{ fontSize: 16 }}
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Searchpage;
