import React, { useEffect, useState } from "react";
import logo from "../image/logo-git-1.png";
import fb from "../image/fb.svg";
import yt from "../image/yt.svg";
import blog from "../image/blog.svg";
import tw from "../image/tw.svg";
import lin from "../image/lin.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { HashLink } from "react-router-hash-link";

const technology = "https://git.digiwbs.com/Admin/public/api/apiCourses"; //data.data
const certification =
  "https://git.digiwbs.com/Admin/public/api/apiPopularCourses"; // PopularCourses
const vendor = "https://git.digiwbs.com/Admin/public/api/apiCourseCategories"; //categoriesList

function Footer() {
  const [Vendordata, setVendordata] = useState();
  const [Populardata, setPopulardata] = useState();
  const [Browserdata, setBrowserdata] = useState();

  useEffect(() => {
    axios
      .get(vendor)
      .then((result) => {
        setVendordata(result.data.categoriesList);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(certification)
      .then((result) => {
        setPopulardata(result.data.PopularCourses.data);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(technology)
      .then((result) => {
        setBrowserdata(result.data.data.data);
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);


  return (
    <>
      <footer>
        <div className="container">
          <img className="logo" src={logo} loading="lazy" alt="logo" />
          <div className="row flex-wrap">
            <div className="col-6 col-md-4 col-lg-2  foo">
              <h4 className="text-left">About Global IT</h4>
              <div className="linklist">
                <li>
                  <HashLink to="/about_us/#corporate_value">
                    Company Values
                  </HashLink>
                </li>
                <li>
                  <HashLink to="/about_us/#strategy_content">
                    Corporate Strategy
                  </HashLink>
                </li>
                <li>
                  <HashLink to="/about_us/#partner_content">
                    Our Partners
                  </HashLink>
                </li>
                <li>
                  <HashLink to="/about_us/#career-content">Careers</HashLink>
                </li>
                <li>
                  <HashLink to="/about_us/#rent_lab">Lab Rental</HashLink>
                </li>
                <li>
                  <Link to="/testimonial">Testimonials</Link>
                </li>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 foo2">
              <h4>Certification</h4>
              <div className="linklist">
                <li>
                  <Link
                    to="/category/CompTIA Certification/15"                   
                  >
                    CompTIA
                  </Link>
                </li>
                <li>
                  <Link to="/category/Cisco/16">
                    Cisco
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Microsoft/17"
                  >
                    Microsoft
                  </Link>
                </li>
                <li>
                  <Link to="/category/Oracle/19">
                    Oracle
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Project Management Institute- Authorized Training Partner (PMI®)/18"
                  >
                    PMI
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/IIBA – International Institute of Business Analysis/21"
                  >
                    IIBA
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Course/Lean Six Sigma Green Belt Certification/143"
                  >
                    Six Sigma
                  </Link>
                </li>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 foo3">
              <div className="linklist mt-5">
                <li>
                  <Link
                    to="/category/EC-Council Accredited Training/22"
                  >
                    EC-Council
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/ITIL Certification Training/23"
                  >
                    ITIL
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Software QA Testing Training/26"
                  >
                    Software Testing (QA)
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Cyber Security Certification Training/25"
                  >
                    Cyber Security
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Web Design Training/27"p
                  >
                    Web Designing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/category/Microsoft/17"
                  >
                    Microsoft Office
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Course/Certified Information Systems Security Professional (CISSP)/82"
                  >
                    CISSP
                  </Link>
                </li>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 foo4">
              <h4>Important Link</h4>
              <div className="linklist">
                <li>
                  <Link to="/financialAid">Financial Aid</Link>
                </li>
                <li>
                  <Link to="/freeWorkShop">Free Workshops</Link>
                </li>
                <li>
                  <Link
                    to="https://globalinformation.applicantpro.com/jobs/"
                    target="_blank"
                  >
                    Job Board
                  </Link>
                </li>
                <li>
                  <Link to="/student_service">Student Login</Link>
                </li>
                <li>
                  <Link to="/contactUs">Contact</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/plan_your_career">Plan Your Career</Link>
                </li>
                <li>
                  <Link to="https://hannover.global-itech.com/" target="_blank">
                    Webmail
                  </Link>
                </li>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-2 foo5">
              <h4 className="couroffer">Course & Offers</h4>
              <div className="course">
                <p className="text-white">View Course Schedule</p>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Choose Course
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Populardata &&
                      Populardata.map((val) => (
                        <Dropdown.Item key={val.id}>
                          <Link to={"/Course/" + val.title + (val && val?.id)}>
                            {val?.title}
                          </Link>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="py-3 search ">
                <p className="text-white">
                  Subscribe to our <span>Newsletter</span>
                </p>
                <div className="form d-flex pb-2">
                  <input type="text" placeholder="Email Address"></input>
                  <button className="btn submit btn-danger px-2">Go</button>
                </div>
                <div className="social-link">
                  <Link to="https://www.facebook.com/gogitgo">
                    <img src={fb} loading="lazy" alt="facebook" />
                  </Link>
                  <Link to="https://www.linkedin.com/company/global-itech/">
                    <img src={lin} loading="lazy" alt="linkedin" />
                  </Link>
                  <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fgogitgo">
                    <img src={tw} loading="lazy" alt="x" />
                  </Link>
                  <Link to="https://www.youtube.com/user/GoGITGo/videos">
                    <img src={yt} loading="lazy" alt="youtube" />
                  </Link>
                  <Link to="https://global-itech.blogspot.com/">
                    <img src={blog} loading="lazy" alt="blog" />
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            <div className="box pt-4">
              <h3>Browse By Vendor</h3>
              <div className="d-flex flex-wrap">
                {Vendordata &&
                  Vendordata.map((val) => (
                    <p className="pe-3" key={val.id}>
                      <Link
                        to={`/category/ ${val.title} / ${val.id}`}
                      >
                        {val?.title}
                      </Link>
                    </p>
                  ))}
              </div>
            </div>
            <div className="box pt-4">
              <h3>Browse By Certification</h3>
              <div className="d-flex flex-wrap">
                {Populardata &&
                  Populardata.map((val) => (
                    <p className="pe-3" key={val.id}>
                      <Link
                        to={`/Course/${val.title} / ${val.id}`}
                      >
                        {val?.title}
                      </Link>
                    </p>
                  ))}
              </div>
            </div>
            <div className="box pt-4">
              <h3>Browse By Technology</h3>
              <div className="d-flex flex-wrap">
                {Browserdata &&
                  Browserdata.map((val) => (
                    <p className="pe-3" key={val.id}>
                      <Link
                        to={`/Course/${val.title} / ${val.id}`}
                      >
                        {val?.title}
                      </Link>
                    </p>
                  ))}
              </div>
            </div>
            <hr />
            <p className="stable">© 2024 Global Information Technology</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
