import React from "react";
import portfolio1 from "../image/portfolio3.png";
import portfolio2 from "../image/portfolio1.png";
import portfolio3 from "../image/portfolio2.png";
import portfolio4 from "../image/portfolio4.png";

function Webdesignportfolio() {
  return (
    <>
      <section className="webportfolio ">
        <div className="container">
          <div className="row">
            <h2>Few from our Portfolio</h2>
            <div className="col-12 col-lg-3">
              <img src={portfolio1} alt="portfolio" loading="lazy" />
            </div>
            <div className="col-12 col-lg-3">
              <img src={portfolio2} alt="portfolio" loading="lazy" />
            </div>
            <div className="col-12 col-lg-3">
              <img src={portfolio3} alt="portfolio" loading="lazy" />
            </div>
            <div className="col-12 col-lg-3">
              <img src={portfolio4} alt="portfolio" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Webdesignportfolio;
