import React from "react";
import vt from "../image/vetrans-banner.png";
import { Rating } from "@mui/material";

function Vetransbanner() {
  return (
    <>
      <section className="vetrans-banner">
        <div className="container h-100">
          <div className="vetransbanner-content">
            <img src={vt} alt="vetrans-logo" loading="lazy" />
            <h1>Military Veterans</h1>
            <p>GET READY TO POWER YOUR TECH FUTURE</p>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Vetransbanner;
