import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import Allcoursesmodel from "./Allcoursesmodel";

const imagePath =
  process.env.IMAGE_PATH ||
  "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Learn() {
  const [courses, setCourses] = useState([]);
  const [coursesSearch, setCoursesSearch] = useState([]);
  const [allcourseModal, setallcoursesModal] = useState(false);

  const openallcourseModal = () => setallcoursesModal(true);
  const closeallcourseModal = () => setallcoursesModal(false);

  useEffect(() => {
    axios
      .get("https://git.digiwbs.com/Admin/public/api/apiCourses")
      .then((result) => {
        const coursesData = result.data.data.data;
        if (Array.isArray(coursesData)) {
          setCourses(coursesData);
        } else {
          console.error("Invalid data format for courses:", coursesData);
        }
      })
      .catch((err) => {
        console.error("Error fetching courses:", err);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    onSubmit: async (values) => {
      try {
        const result = await axios.get(
          `https://git.digiwbs.com/Admin/public/api/apiCoursesSearch?search=${values.searchText}`
        );
        setCoursesSearch(result.data.Courses.data);
      } catch (error) {
        console.error("Error searching courses:", error);
      }
    },
  });

  return (
    <>
      <section className="learnget">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 col-lg-12">
              <h3>Learn & Get Certified</h3>
              <p>
                <span>3000+</span> Students trained <span>$100M+</span> Salary
                <br />
                delivered
              </p>
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="text"
                  alt="search"
                  placeholder="Search From 100+ Course And Certification"
                  name="searchText"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.searchText}
                />
                <button className="btn btn-danger submit" type="submit">
                  <SearchIcon />
                </button>
              </form>

              <div className="search-result">
                <ul>
                  {courses.map((val) => (
                    <Link key={val.id} to={`/Course/${val.title} / ${val.id}`}>
                      <li>
                        <img
                          src={`${imagePath}${val.img}`}
                          loading="lazy"
                          alt={val.title}
                        />
                        {val.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="row my-5 learnlist">
                <ul className="d-flex flex-wrap">
                  {(coursesSearch.length ? coursesSearch : courses).map(
                    (val, index) => (
                      <li key={index}>
                        <Link to={`/Course/${val.title} / ${val.id}`}>
                          <img
                            src={`${imagePath}${val?.img}`}
                            alt={val?.title}
                            loading="lazy"
                          />
                          <span>{val?.title}</span>
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <button className="learn_btn" onClick={openallcourseModal}>
              View More
            </button>
          </div>
        </div>
      </section>
      <Allcoursesmodel
        show={allcourseModal}
        handleClose={closeallcourseModal}
      />
    </>
  );
}

export default Learn;
