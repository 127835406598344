import React, { Suspense } from "react";
import Contactbanner from "./ContactUS/Contactbanner";
import Contactforminfo from "./ContactUS/Contactforminfo";

function ContactRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Contactbanner />
        <Contactforminfo />
      </Suspense>
    </>
  );
}

export default ContactRoute;
