import { Rating } from "@mui/material";
import React from "react";

function Financialbanner() {
  return (
    <>
      <section className="financial-aid">
        <div className="container h-100">
          <div className="financial-content ">
            <h1>Financial Aid</h1>
            <Rating name="read-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Financialbanner;
