import React from "react";
import { Link } from "react-router-dom";
import home from "../image/home.svg";
import school from "../image/schoolership.svg";
import placement from "../image/placement.svg";
import workshop from "../image/workshop.svg";
import consult from "../image/consulting.png";

function Fixedbanner() {
  return (
    <>
      <div className="imgtext d-flex justify-content-end align-items-center banner-group ">
        <div className="banner-service rgtborder">
          <img src={consult} loading="lazy" alt="home" />
          <Link>Consulting</Link>
        </div>
        <div className="banner-service rgtborder">
          <img src={home} loading="lazy" alt="home" />
          <Link to="/financialAid">Financial Aid</Link>
        </div>
        <div className="banner-service rgtborder rightborder2">
          <img src={school} loading="lazy" alt="school" />
          <Link to="/Schoolership">Scholarship</Link>
        </div>
        <div className="banner-service rgtborder ">
          <img src={placement} loading="lazy" alt="placement" />
          <Link to="/JobPlacement">Job Placement</Link>
        </div>
        <div className="banner-service">
          <img src={workshop} loading="lazy" alt="workshop" />
          <Link to="/freeWorkShop">Free Workshops</Link>
        </div>
      </div>
    </>
  );
}

export default Fixedbanner;
