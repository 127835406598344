import React, { useState } from "react";
import resume from "../image/happy-young-colleagues-indoors-coworking 2.png";
import employer from "../image/employe_service.jpg";
import job from "../image/job_prepration.jpg";
import interview from "../image/interview_technique.jpg";
import network from "../image/networking_opportunity.jpg";
import jobsearch from "../image/job_search.jpg";

function Jobplacementcontent() {
  const [activeTab, setActiveTab] = useState("resume");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <section className="job-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 top-tab">
              <ul className="nav nav-tabs">
                <li
                  className={`nav-item ${
                    activeTab === "resume" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("resume")}
                  >
                    <i className="fa-solid fa-address-card"></i>Resume
                    Preparation
                  </button>
                </li>
                <li
                  className={`nav-item ${
                    activeTab === "employer" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("employer")}
                  >
                    <i className="fa-solid fa-user-gear"></i> Employer Services
                  </button>
                </li>
                <li
                  className={`nav-item ${
                    activeTab === "workshops" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("workshops")}
                  >
                    <i className="fa-solid fa-briefcase"></i>Job Preparation
                    Workshops
                  </button>
                </li>
                <li
                  className={`nav-item ${
                    activeTab === "interview" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("interview")}
                  >
                    <i className="fa-solid fa-address-card"></i>Interview
                    Techniques
                  </button>
                </li>
                <li
                  className={`nav-item ${
                    activeTab === "networking" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("networking")}
                  >
                    <i className="fa-solid fa-users-line"></i>Networking
                    Opportunities
                  </button>
                </li>
                <li
                  className={`nav-item ${
                    activeTab === "strategies" ? "activee" : ""
                  }`}
                >
                  <button
                    className="nav-link"
                    onClick={() => handleTabClick("strategies")}
                  >
                    <i className="fa-solid fa-person-arrow-up-from-line"></i>Job
                    Search Strategies
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-12">
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    activeTab === "resume" ? "show active" : ""
                  }`}
                  id="resume"
                >
                  <div className="d-flex pane-content">
                    <img src={resume} alt="" loading="lazy" />
                    <div className="">
                      <p>
                        “It’s All About You!” It’s about performance in the past
                        jobs, especially accomplishments in those jobs. The
                        resume is a document (brief overview) highlighting and
                        promoting a job seeker's skills and abilities, and it is
                        used to present a prospective employee with the best
                        possible light to get to the interview. More and more
                        employers require a resume for employment as well as the
                        internship experience. For For more information on
                        Resume Preparation, please contact Job Placement
                        Services at placement.services@global-itech.com
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  id="employer"
                  className={`tab-pane fade ${
                    activeTab === "employer" ? "show active" : ""
                  }`}
                >
                  <div className="d-flex pane-content">
                    <img src={employer} alt="" />
                    <div className="">
                      Many services are available to employers who wish to hire,
                      post job openings, and participate in recruiting
                      activities. A free job posting service is available to
                      employers for assistance in seeking qualified employees or
                      interns.
                    </div>
                  </div>
                </div>
                <div
                  id="workshops"
                  className={`tab-pane fade ${
                    activeTab === "workshops" ? "show active" : ""
                  }`}
                >
                  <div className="d-flex pane-content">
                    <img src={job} alt="" />
                    <div className="">
                      <p>
                        Our workshops are designed to prepare you to handle
                        various aspects and situations relative to your job
                        search. Some of these topics include The Power of
                        Networking, Mock Interviews, and Creating the Perfect
                        Resume. For more information on Job Preparation
                        Workshops, please contact Job Placement Services at
                        placement.services@global-itech.com
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  id="interview"
                  className={`tab-pane fade ${
                    activeTab === "interview" ? "show active" : ""
                  }`}
                >
                  <div className="d-flex pane-content">
                    <img src={interview} alt="" />
                    <div className="">
                      <p>
                        The need for advanced preparation is paramount when it
                        comes to a job interview. Before you can actually
                        proceed with the interview, you will need several
                        organized facts and details at your fingertips.
                      </p>
                      <p>
                        Prospective employees should be able to determine the
                        specific needs of the employer:
                      </p>
                      <ul>
                        <li>
                          Demonstrate interest and enthusiasm for the job.
                        </li>
                        <li>Provide pertinent information to the employer.</li>
                        <li>Demonstrating skills and abilities.</li>
                        <li>Compete with other interviewees.</li>
                        <li>Show initiative and knowledge about the job.</li>
                        <li>
                          Maintain the employer’s interest and attention through
                          conversation.
                        </li>
                        <li>Demonstrate seriousness about employment.</li>
                        <li>
                          Understand job interviews are stressful situations.
                          Employers want to know the job seekers' motivation for
                          employment, ability to do the job, and organizational
                          fit. For more information on Interview Techniques,
                          please contact Job Placement Services at
                          placement.services@global-itech.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  id="networking"
                  className={`tab-pane fade ${
                    activeTab === "networking" ? "show active" : ""
                  }`}
                >
                  <div className="d-flex pane-content">
                    <img src={network} alt="" />
                    <div className="">
                      <p>
                        Networking can be used for many purposes: selecting a
                        career, changing jobs, getting a promotion, relocating
                        to a new city, or starting a business. Those purposes
                        are:
                      </p>
                      <ul>
                        <li>
                          Networking with employers to seek information
                          regarding career choice and job availability.
                        </li>
                        <li>
                          Networking with employers is the best way to make good
                          things happen for future employment.
                        </li>
                        <li>
                          Networking with employers allows our office to obtain
                          job listings for the ‘Jobs Board’ and the Virtual
                          Career Fair.
                        </li>
                        <li>
                          Networking with our office helps students and alumni
                          determine job opportunities, internships, and
                          referrals. For more information on Network
                          Opportunities, please contact Job Placement Services
                          at placement.services@global-itech.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  id="strategies"
                  className={`tab-pane fade ${
                    activeTab === "strategies" ? "show active" : ""
                  }`}
                >
                  <div className="d-flex pane-content">
                    <img src={jobsearch} alt="" />
                    <div className="">
                      <p>
                        A successful job search requires organization and
                        effort. The traditional method of seeking employment is
                        by reading job ads, sending out résumés, and applying in
                        person. However, planning and organization are critical
                        to job search success. Increasing the chances of finding
                        employment requires a variety of methods, which can save
                        a great deal of time and effort. In the end, the
                        valuable resources of good self-management and
                        organizational skills yield many benefits such as:
                      </p>
                      <ul>
                        <li>A shorter period for new employment</li>
                        <li>
                          An increase in the number of interviews or job offers
                        </li>
                        <li>A reduction in the fear of unemployment</li>
                        <li>An increased potential for job satisfaction.</li>
                      </ul>
                      <p>
                        When searching for employment, keep in mind that
                        employers want to know previous employment and job
                        titles, but more importantly, the skills needed for
                        employment in their organization. So, skills are the
                        foundation of a successful and effective job search
                      </p>
                      <p>
                        For more information on job search strategies, please
                        contact Job Placement Services at
                        placement.services@global-itech.com.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Jobplacementcontent;
