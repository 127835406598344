import React from "react";
import { Card } from "react-bootstrap";
import virus from "../image/virus remov.png";
import spyware from "../image/Mask group-6.png";
import backup from "../image/backup.png";
import install from "../image/install.png";
import clean from "../image/Mask group-4.png";
import wirless from "../image/Mask group-5.png";
import upgrad from "../image/upgrad.png";
import netimages from "../image/networkdesign.png";
import officeimg from "../image/Group 1750 (2) 1.png";

function Networkcontent() {
  const card = [
    { Title: "Virus Removal", image: virus },
    { Title: "SPYWARE REMOVAL", image: spyware },
    { Title: "BACKUP AND RECOVERY", image: backup },
    { Title: "INSTALLATIONS", image: install },
    { Title: "COMPUTER CLEANING", image: clean },
    { Title: "NETWORKING & WIRELESS", image: wirless },
    { Title: "COMPUTER UPGRADES", image: upgrad },
  ];

  return (
    <>
      <section className="networkcontent ">
        <div className="staffpost"></div>
        <div className="container">
          <h3>
            Are you ready to start your own business, but need a network to work
            more efficiently?
          </h3>
          <div className="row mt-5">
            <div className=" d-flex flex-wrap card-loop w-100">
              {card.map((val, index) => (
                <Card key={index}>
                  <Card.Img variant="top" src={val.image} />
                  <Card.Body>
                    <Card.Title>{val.Title}</Card.Title>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
          <div className="row networkdesign">
            <div className="col-12 col-lg-6  netcol">
              <img src={netimages} alt="design" className="w-100" />
            </div>
            <div className="col-12 col-lg-6  netcol">
              <h2>Network Design, Implementation and Analysis</h2>
              <p>
                Are you ready to start your own business but need a network to
                work more efficiently? Are you already established but looking
                to upgrade your network? Let the experienced networking staff of
                Global I.T. set up, reconfigure, or expand your network to
                satisfy the ever-growing network technology and its need for
                faster, more secure networks.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 order">
              <h2>Office Setup and System Building</h2>
              <p>
                Did you just open a new location but not yet have any
                telephone/network wiring done? Do you need new computers at your
                location or the installation of one or multiple servers? Global
                I.T. is your partner in success when it comes to all types of
                computer setups. Whether you’re looking for a simple computer
                system installation or a more secure wireless network, we are
                here to help you. Let us evaluate your current setup for
                possible efficient and time-saving solutions that can give you
                more time to work on the more important parts of your business.
                Whatever your office may need, we are ready to help you.
                <br />
                Although you may have an information technology department
                working in your office, there are times when you might want to
                hire an IT consultant for some additional projects. Maybe you
                need to network the computers in your office. A Global IT
                consultant can help you with these tasks and more.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img src={officeimg} alt="officeomg" className="w-100" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Networkcontent;
