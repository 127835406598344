import { Rating } from "@mui/material";
import React from "react";

function Faqbanner() {
  return (
    <>
      <section className="faqbanner">
        <div className="container h-100">
          <div className="faq-content text-center">
            <h1>Frequently Asked Questions</h1>
            <Rating name="rad-only" defaultValue={5} readOnly />
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqbanner;
