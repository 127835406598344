import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import { Link } from "react-router-dom";

// const imagePath =
//   process.env.IMAGE_PATH ||
//   "https://git.digiwbs.com/Admin/public/build/images/courses/";

function Searchmodal({ show, handleClose }) {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  // const [showSearchBox, setShowSearchBox] = useState(false);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    onSubmit: () => {
      navigateToSearchPage(); // Trigger the redirect on form submission
    },
  });

  const fetchDefaultCourses = () => {
    axios
      .get(
        `https://git.digiwbs.com/Admin/public/api/apiCoursesSearch?search=default`
      ) // Adjust the query as needed to get default results
      .then((result) => {
        setCourses(result.data.Courses?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (show) {
      fetchDefaultCourses(); // Fetch default courses when the modal opens
    }
  }, [show]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      navigateToSearchPage(); // Trigger the redirect when Enter is pressed
    }
  };

  const navigateToSearchPage = () => {
    const searchQuery = formik.values.searchText.trim();
    if (searchQuery) {
      // Redirect to /Search-query route with the search query as a parameter
      navigate(`/Search-query?query=${encodeURIComponent(searchQuery)}`);
      handleClose(); // Close the modal after redirecting
      formik.values.searchText = "";
    }
  };

  return (
    <>
      <Modal className="modal search-modal" show={show} onHide={handleClose}>
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <div className="modal-head d-flex justify-content-center">
          <div className="search-box pe-2">
            <div className="search-icon">
              <input
                className="search-text"
                type="text"
                placeholder="Search..."
                name="searchText"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.searchText}
                onKeyDown={handleKeyDown} // Trigger search on Enter key press
              />
              <SearchIcon
                style={{
                  color: "#939393",
                  marginTop: "0.5rem",
                  marginLeft: "0.5rem",
                  rotate: "90deg",
                  cursor: "pointer",
                }}
                onClick={navigateToSearchPage} // Trigger search on click
              />
            </div>
            {/* <div
              className="search-result"
              style={{ display: showSearchBox ? "block" : "none" }}
            >
              {loading ? (
                <div className="loader"></div>
              ) : (
                <ul>
                  {courses.map((val) => (
                    <Link to={`/Course/${val.title}/${val.id}`} key={val.id}>
                      <li onClick={handleClose}>
                        <img src={`${imagePath}${val.img}`} alt={val.title} />
                        {val.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              )}
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Searchmodal;
