import React from "react";
import ContactForm from "../ContactForm";
import poster from "../image/firstpage-poster.png";
import { Link } from "react-router-dom";

function Firstcontent() {
  return (
    <>
      <section className="first-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <h2 className="first-head">
                Embark on the journey to becoming an IT professional in just few
                months* with Global IT!
              </h2>
              <p>
                Led by seasoned industry experts, our programs ensure your
                success with personalized guidance and real-world insights. We
                understand the value of hands-on experience, industry exposure,
                and a comprehensive curriculum, all tailored to equip you with
                the skills needed for a thriving IT career.
              </p>
              <p>
                What's more? With our emphasis on certifications and practical
                learning, you can start earning while you learn. Employers will
                recognize your expertise, giving you a head start in securing
                lucrative opportunities.
              </p>
              <div className="first-poster">
                <img src={poster} alt="start-today" className="h-100 w-100" />
                <Link to="/career-track-course">
                  <button className="btn start-today">Start Today</button>
                </Link>
              </div>
              <h2>Experience Real-World IT Training at Global IT!</h2>
              <p>
                Immerse yourself in practical learning and job readiness with
                Global IT's cutting-edge training programs! Every week, we host
                Career Oriented webinars onsite, offering free IT courses to the
                community. This invaluable opportunity allows you to tackle
                real-life scenarios, preparing you for the challenges of the IT
                industry.
              </p>
              <p>
                Gain confidence and proficiency as you navigate through these
                interactive sessions, ensuring you're well-equipped to achieve
                your IT career aspirations. Join us at Global IT and take the
                first step towards a fulfilling and successful journey in the
                world of technology!
              </p>
              <Link to="/career-track-course">
                <button className="btn start">New Start Today</button>
              </Link>
            </div>
            <div className="col-12 col-lg-4">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Firstcontent;
